import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UserModel } from 'src/app/models';
import { TaskModel } from 'src/app/models/TaskModel';
import { TribeService } from 'src/app/shared';

@Component({
  selector: 'task-thumbnail',
  templateUrl: './task-thumbnail.component.html',
  styleUrls: ['./task-thumbnail.component.css']
})
export class TaskThumbnailComponent {
  @Input() projectId: string;
  @Input() projectName: string;
  @Input() task: TaskModel;
  tribeMember: UserModel;
  @Output() messageEvent: EventEmitter<TaskModel> = new EventEmitter();
  
  constructor(private tribeService: TribeService) { }

  ngOnInit(): void {
    const uuid = this.task.assignee as string
    this.tribeMember = this.tribeService.getTribeMember(uuid)
  }

  getModel() {
    return this.task;
  }
  
  pass(updatedTask: TaskModel): void {
    console.log("Passing task to board component...");
    this.task = updatedTask;
    this.messageEvent.emit(updatedTask);
  }

}
