import { Input, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectModel } from '../../models/ProjectModel';
import { NotificationService } from '../services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { constants } from './config/modal-config-constants';

// Modal Content class
@Component({
  selector: 'create-project-modal-content',
  templateUrl: 'create-project-modal-content.component.html',
  styleUrls: ['./config/modal-validation.css']
})
export class CreateProjectModalContent implements OnInit {
  @Input() public project: ProjectModel;
  //#region 
  projectForm: FormGroup
  title: FormControl
  description: FormControl
  //#endregion
  constructor(public activeModal: NgbActiveModal, public notifications: NotificationService) { }

  ngOnInit() {
    this.project = new ProjectModel();
    this.title = new FormControl('', [
      Validators.required, 
      Validators.maxLength(constants.titleLength)
    ])
    this.description = new FormControl('', [
      Validators.maxLength(constants.descriptionLength)
    ]);

    this.projectForm = new FormGroup({
      title: this.title,
      description: this.description
    })
  }

  passBack(): void
  {
    console.log("Passing modal-content to modal component!");
    this.activeModal.close(this.project);
  }

  onSubmit(formValues) {
    let newProject = new ProjectModel(this.project);
    newProject.name = formValues.title;
    newProject.description = formValues.description;
    this.activeModal.close(newProject);
  }

  isValid(): boolean {
    return this.projectForm.valid;
  }

  closureCheck(formValues) {
    if (this.formIsDirty() && this.isValid()) {
      // run warning
      if (this.notifications.alertUser() === 'Save') {
        this.onSubmit(formValues)
        return
      }
    }
    // close modal
    this.activeModal.close('Close click')
  }

  formIsDirty(): boolean {
    return this.projectForm.dirty;
  }
}