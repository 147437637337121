import { Component } from '@angular/core';

@Component({
  selector: 'app-slider',
  template: `
  <mat-slider color="primary"
    thumbLabel
    tickInterval="1" 
    min="1"
    max="6" 
    step="1">
  </mat-slider>
  `
})
export class MatSliderComponent {
  
}