<h1 class="d-flex justify-content-center">Frequently Asked Questions</h1>

<mat-nav-list class="col-md-12 mb-5">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        What is SharkTracker?
      </mat-expansion-panel-header>
      <div class="col-sm-12">
        <ul>
          <li>Sharktracker is a dynamic, customizable tool that allows teams to manage projects in the way that makes the most sense for them. With its simplicity, affordability and adaptability, any group, team or organization can benefit from Sharktracker.
          Read more about us <a (click)="navigateToAboutUs()">here.</a>
          </li>
        </ul>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        How do I set up an account?
      </mat-expansion-panel-header>
      <div class="col-sm-12">
        <ul>
          <li>Click Register/Login then click on the create account button, follow the prompts, and done! 
            Adding your phone number is not required. 
          </li>
        </ul>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
          How do I create a new project?
        </mat-expansion-panel-header>
        <div class="col-sm-12">
          <ul>
            <li>
              On the projects screen, you will see “New Project” - click the “Create” button within this box.
            </li>
            <li>
              Name your project and provide a description. 
            </li>
            <li>
              Click "Create" and you're up and running! Add people to your tribe under project settings.
            </li>
          </ul>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          How do I add someone to a project?
        </mat-expansion-panel-header>
        <div class="col-sm-12">
          <ul>
            <li>Go to project settings</li>
            <li>Select invite user</li>
            <li>Enter email and send. Your invitee will receive your invitation via email.</li>
          </ul>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          How many people can I have per project?
        </mat-expansion-panel-header>
        <div class="col-sm-12">
          <ul>
            <li>20</li>
          </ul>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          How many projects can I have at one time?
        </mat-expansion-panel-header>
        <div class="col-sm-12">
          <ul>
            <li>You can only create up to 5 projects, however, you can be invited to hundreds of projects.</li>
          </ul>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          How do I archive tasks?
        </mat-expansion-panel-header>
        <div class="col-sm-12">
          <ul>
            <li>Before archiving a task, you must first move the task to “Done”.</li> 
            <li>Once the task is in the “Done” column, open the task and click “Archive”.</li>
          </ul>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          What is a voyage?
        </mat-expansion-panel-header>
        <div class="col-sm-12">
          <ul>
            <li>A voyage is very similar to a Sprint if you're familiar with Agile. It is a time-boxed period in which you align on a certain number of tasks to be completed. Your project will likely be made up of many voyages, which will help you and your team stay on task. </li>
          </ul>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          How do I set up my voyages?
        </mat-expansion-panel-header>
        <div class="col-sm-12">
          <ul>
            <li>Go to Project Settings (the cog icon on each project card)</li>
            <li>Go to "Configure project"</li>
            <li>Toggle the "Enable voyages" button</li>
            <li>Press "Save."</li>
          </ul>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          What do the shark rankings mean?
        </mat-expansion-panel-header>
        <div class="col-sm-12">
          <ul>
            <li>Shark rankings are simply the expected amount of effort you think a task will require. A Tadpole is usually quick and easy, while a Megalodon will require a ton of effort. </li>
          </ul>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          What do I do if I have additional questions?
        </mat-expansion-panel-header>
        <div class="col-sm-12">
          <ul>
            <li>If you have additional questions, please reach out to us using the “Contact Us” link in the navigation bar. </li>
          </ul>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          I want to cancel my subscription. How do I do this?
        </mat-expansion-panel-header>
        <div class="col-sm-12">
          <ul>
            <li>Don't do it! Just kidding, if you really must cancel:</li>
            <li>Click on your email in the navigation bar</li>
            <li>Go to "My Account"</li> 
            <li>Open the "Manage Subscription" panel </li>
            <li>Click the big red button.</li>
          </ul>
        </div>
      </mat-expansion-panel>
  </mat-nav-list>
