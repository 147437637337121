import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { ProjectModel, TaskModel, UserModel } from '../models';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { RouteService, 
  TaskService,
  ModalService,
  ProjectService,
  UpdateTaskModalContent, 
  TribeService,
} from '../shared';


@Component({
  selector: 'display-user-task-table',
  templateUrl: './display-user-task-table.component.html',
  styleUrls: ['./display-user-task-table.component.css']
})
export class DisplayUserTaskTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;

  project: ProjectModel;
  projectUuid: string;
  tribeMembers: UserModel[];
  headerElements: string[];
  headerNames: string[];
  tasks: TaskModel[] = [];
  previous: any = [];
  searchText: string = '';
  pageSize: number = 25;

  constructor(private taskService: TaskService, 
              private routeService: RouteService,
              private modalService: ModalService,
              private projectService: ProjectService,
              private tribeService: TribeService,
              private cdRef: ChangeDetectorRef) {

    this.routeService.queryParams.subscribe(params => {
      this.projectUuid = params['project'];
    });

    this.taskService.tasksReturned.subscribe((value) => {
      //this listens for subsequent get task requests for lazy loading
      this.tasks = value
      this.mdbTable.setDataSource(this.tasks);
      this.tasks = this.mdbTable.getDataSource();
      this.previous = this.mdbTable.getDataSource();
    });
  }

  @HostListener('input') oninput() {
    this.searchItems();
  }

  async ngOnInit(): Promise<void> {
    if(this.projectService.getProject(this.projectUuid) === undefined)
    {
      await this.projectService.getProjects();    
    }
    this.project = this.projectService.getProject(this.projectUuid);

    this.tribeMembers = await this.tribeService.getTribe(this.projectUuid)

    this.taskService.getTasksLazy(this.project, this.pageSize, null)
    .then((res) => {
      this.tasks = res;
      this.mdbTable.setDataSource(this.tasks);
      this.tasks = this.mdbTable.getDataSource();
      this.previous = this.mdbTable.getDataSource();
      if (this.tasks.length > 0) {
        var headers = Object.keys(this.tasks[0]);

        //Hide these items
        var index = headers.indexOf("uuid", 0);
        if (index > -1) {
          headers.splice(index, 1);
        }
        index = headers.indexOf("latestUpdate", 0);
        if (index > -1) {
          headers.splice(index, 1);
        }

        this.headerElements = this.getHeaders()
      }
      else
        this.headerElements = [""];
    })
  }

  getAssigneeFirstName(userUuid: string): string {
    const tribeMember = this.tribeService.getTribeMember(userUuid)
    return tribeMember.firstname
  }

  getHeaders(): string[] {
    let headers = [
      'ID',
      'Title',
      'Creation Date',
      'Status',
      'Shark Rank',
      'Shark Classification',
      'Description',
      'Assignee'
    ];
    return headers;
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
        this.mdbTable.setDataSource(this.previous);
        this.tasks = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
        this.tasks = this.mdbTable.searchLocalDataBy(this.searchText);
        this.mdbTable.setDataSource(prev);
    }
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.pageSize);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  openModal(task: TaskModel) {
    let data = {
      task: task,
      project: this.project
    }
    this.modalService.openTaskModal(UpdateTaskModalContent, data)
    .then((result) => {
      console.log("Promise returned!");
      let updatedTask: TaskModel = new TaskModel(result);
      let taskIdx = this.tasks.findIndex((searchTask) => {
        return searchTask.uuid === updatedTask.uuid;
      })
      this.tasks[taskIdx] = updatedTask;
      this.taskService.updateTask(updatedTask, taskIdx, this.project);
    })
    .catch((err) => {
      console.log("Promise rejected!" + err);
    })
  }

  openBoard() {
    console.log("Opening Kanban board!");
    this.routeService.openInsideAngularZone(['/board'], { queryParams: { project: this.projectUuid } })
  }
}
