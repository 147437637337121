import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { QuestionBase } from './question-base.model';

@Injectable()
export class QuestionControlService {
  constructor() { }

  toFormGroup(questions: QuestionBase<string>[]) {
    if (!questions || !questions.length) {
      return new FormGroup({})
    }
    const group: any = {};
  
    questions.forEach(question => {
      const key = question.key.toLocaleLowerCase()
      group[key] = question.required ? new FormControl(question.value || '', Validators.required)
                                              : new FormControl(question.value || '');
    });
    return new FormGroup(group);
  }

  // TODO we assume that task model contains an array of questions
  // once a task has been parsed! Consider implementing question parsing
  // in the task parser
  // toQuestions(form: FormGroup): QuestionBase<string>[] {
  //   let questions = []

  //   return questions
  // }
}