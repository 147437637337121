import { VoyageModel } from "src/app/models"
import { Parser } from './parser-utility'

export default class VoyageParser extends Parser {

  static parseVoyage(payload: any): VoyageModel {
    try {
      return new VoyageModel({
        uuid: this.getUuid(payload),
        name: this.getName(payload),
        description: this.getDescription(payload),
        startTime: this.getStartTime(payload),
        endTime: this.getEndTime(payload),
        viewId: this.getViewId(payload),
        lengthInWeeks: this.getLengthInWeeks(payload),
        lastUpdateTime: this.getLastUpdateTIme(payload),
        status: this.getStatus(payload)
      })
    }
    catch (e) {
      throw e
    }
  }
  //#endregion


  static serializeVoyageData(voyagesMap: any): VoyageModel[] {
    let data: VoyageModel[] = []
    let keys = Object.keys(voyagesMap)
    let values = Object.values(voyagesMap)
    keys.forEach((key, index) => {
      data.push(new VoyageModel({
        uuid: key,
        name: this.getName(values[index]),
        description: this.getDescription(values[index]),
        startTime: this.getStartTime(values[index]),
        endTime: this.getEndTime(values[index]),
        viewId: this.getViewId(values[index]),
        lengthInWeeks: this.getLengthInWeeks(values[index]),
        lastUpdateTime: this.getLastUpdateTIme(values[index]),
        status: this.getStatus(values[index])
      }))
    })
    return data
  }

  private static getUuid(payload: Object) {
    const key: string = 'uuid'
    return this.getString(payload, key)
  }

  private static getName(payload: Object) {
    const key: string = 'voyageName'
    return this.getString(payload, key) 
  }
  
  private static getDescription(payload: Object) {
    const key: string = 'voyageGoal'
    return this.getString(payload, key)
  }
  
  private static getStartTime(payload: Object) {
    const key: string = 'startTime'
    return this.getString(payload, key)
  }

  private static getEndTime(payload: Object) {
    const key: string = 'endTime'
    return this.getString(payload, key)
  }

  private static getViewId(payload: Object) {
    const key: string = 'viewId'
    return this.getString(payload, key)
  }

  private static getLengthInWeeks(payload: Object) {
    const key: string = 'lengthInWeeks'
    return this.getNumber(payload, key)
  }

  private static getStatus(payload: Object) {
    const key: string = 'status'
    return this.getString(payload, key)
  }

  private static getLastUpdateTIme(payload: Object) {
    const key: string = 'lastUpdateTime'
    return this.getString(payload, key)
  }
}