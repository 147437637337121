import { Injectable } from '@angular/core';
import { UsernameAttributes } from 'aws-amplify-angular/dist/src/components/authenticator/types';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { ModalService } from './modal.service';
import { ConfirmReplacementModalComponent } from '../modals/confirm-replacement-modal';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private dataWarning: string = "You are about to lose unsaved changes. Would you like to save first?"
  private subscriptionWarning: string = `
    Reactivate the paused subscription or replace it?
  `
  private config = {
    disableTimeOut: true
  }

  constructor(private toastr: ToastrService, private modal: ModalService) { }

  showSuccess(message, title): ActiveToast<any> {
    return this.toastr.success(message, title)
  }

  showError(message, title, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastr.error(message, title, override)
  }

  showInProgress(message, title): ActiveToast<any> {
    return this.toastr.info(message, title)
  }

  alertUser(): string {
    var userPreference;
    if (confirm(this.dataWarning) === true) {
      userPreference = "Save";
    } else {
      userPreference = "Cancel";
    }
    return userPreference;
  }

  alertSubChange(): string {
    var userPreference;
    if (confirm(this.subscriptionWarning) === true) {
      userPreference = "Reactivate";
    } else {
      userPreference = "Replace";
    }
    return userPreference;
    // TODO replace with openModalAlert()
  }

  openModalAlert(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.modal.openConfirmationWarning(ConfirmReplacementModalComponent)
      .then((res) => {
        resolve(res)
      })
      .catch(err => reject(err))
    })
  }
}