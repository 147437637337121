import { SubscriptionModel, UserModel } from "src/app/models";
import { Parser } from "./parser-utility";
import SubParser from './subscription-parser'

export default class UserParser extends Parser {
  static parseUser(payload: any): UserModel {
    try {
      return new UserModel({
        firstname: this.getFirstName(payload),
        lastname: this.getLastName(payload),
        email: this.getEmail(payload),
        projects: this.getProjects(payload),
        uuid: this.getUserUuid(payload),
        avatarName: this.getAvatarName(payload),
        avatarUrl: this.getAvatarUrl(payload),
        subscription: this.getSubscription(payload)
      })
    }
    catch(error) {
      throw error
    }
  }

  static parseTribeMember(payload: any): UserModel {
    try {
      return new UserModel({
        uuid: this.getUserUuid(payload),
        firstname: this.getFirstName(payload),
        lastname: this.getLastName(payload),
        email: this.getEmail(payload),
        avatarName: this.getAvatarName(payload),
        avatarUrl: this.getAvatarUrl(payload),
      })
    }
    catch(error) {
      throw error
    }
  }

  //#region parseUser() HELPERS
  static getFirstName(payload: any): string {
    const key: string = 'firstname'
    return this.getString(payload, key)
  }

  static getLastName(payload: any): string {
    const key: string = 'lastname'
    return this.getString(payload, key)
  }

  static getEmail(payload: any): string {
    const key: string = 'email'
    const keys: string[] = ['email', 'username']
    let res = ""
    while(keys.length > 0 && res === "") {
      res = this.getString(payload, keys.pop())
    }
    return res
  }

  static getUserUuid(payload: any) {
    const key: string = 'userUuid'
    return this.getString(payload, key)
  }

  static getAvatarName(payload: any) {
    const key: string = 'avatarName'
    return this.getString(payload, key)
  }

  static getAvatarUrl(payload: any) {
    const key: string = 'avatarUrl'
    return this.getString(payload, key)
  }

  static getProjects(payload: any): string[] {
    const key: string = 'projects'
    return this.getList(payload, key)
  }

  static getSubscription(payload: any): SubscriptionModel {
    const key: string = 'subscription'
    return this.filterSub(payload, key)
  }

  static filterSub(data: Object, k: string): SubscriptionModel {
    try {
      return k in data ? SubParser.parseSubscription(data[k]) 
        : new SubscriptionModel({})
    }
    catch(e) {
      console.error(e)
      throw e
    }
  }
  //#endregion

}