import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
import 'hammerjs';

Amplify.configure(aws_exports);

if (environment.production) {
  enableProdMode();
  window.console.log = function () { };   // disable any console.log debugging statements in production mode
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
