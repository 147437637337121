import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProjectModel, VoyageModel } from '../../../models';

@Component({
  selector: 'voyage-progress-bar',
  templateUrl: './voyage-progress-bar.component.html',
  styleUrls: ['./voyage-progress-bar.component.css']
})
export class VoyageProgressBarComponent implements OnInit {

  @Input() project: ProjectModel
  @Output() messageEvent = new EventEmitter<string>();
  public voyage: VoyageModel;
  public currentProgress: Promise<number>;

  constructor() { }

  ngOnInit(): void {
    if (!(this.project.voyages)) {
      this.project.latestVoyageUuid = ''
      return
    }
    if (this.project.latestVoyageUuid !== '') {
      let voyageId = this.project.latestVoyageUuid
      let activeVoyage = this.project.voyages.find((element) => {
        return element.uuid === voyageId
      })
      this.voyage = activeVoyage;
      this.currentProgress = this.getProgress()
    }
  }

  getProgress(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      let progress: number = 0;
      if (this.voyage.startTime && this.voyage.lengthInWeeks) {
        let start: Date = new Date(this.voyage.startTime);
        let now: Date = new Date();
    
        let microSecondsDiff: number = Math.abs(now.getTime() - start.getTime());
    
        const daysDiff = Math.round(microSecondsDiff / (1000 * 60 * 60  * 24));
        const voyageLengthInDays = Math.round(this.voyage.lengthInWeeks * 7);
    
        progress = Math.round((daysDiff / voyageLengthInDays) * 100);
        let raw_progress = daysDiff / voyageLengthInDays
        console.info('voyage progress: ' + raw_progress)
        console.info("Progress in days: " + daysDiff)
        console.info("Progress percentage: " + progress + '%')
        resolve(progress)
      }
      else {
        reject('voyage lacks startTime or lengthInWeeks attribute(s)!')
      }
    })
  }

  ngOnChanges() {
    if (this.project.latestVoyageUuid === '') {
      console.log('Refresh progress bar view')
      this.voyage = undefined
    }
  }
}
