<div class="container">
  <mat-form-field>
    <mat-label>Tags</mat-label>
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let tag of tags"
        matRipple
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(tag)">
        {{tag}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
      placeholder="New tag..."
      type="text"
      matInput
      #tagInput
      [formControl]="tagCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      (blur)="onTouched()"
      (click)="displayAutoComplete()">
      <mat-error *ngIf="tagCtrl.invalid">
        {{getErrorMessage()}}
      </mat-error>
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
        {{tag}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>  
</div>