import { Component, Input } from '@angular/core';
import { RouteService } from './../../routing';

@Component({
  selector: 'open-task-table-button',
  templateUrl: './open-task-table-button.component.html',
  styleUrls: ['./open-task-table-button.component.css']
})
export class OpenTaskTableButtonComponent {

  @Input() projectId: string

  constructor(private routeService: RouteService) { }

  open() {
    // if (!this.isEmpty(this.routeService.queryParams)) {
    //   let pid = this.routeService.routeSnapshot.queryParams.project;
    //   console.log("opening table...")
    //   this.routeService.open(['/table'], { queryParams: {project: pid }}); 
    // }
    // else {
    //   this.routeService.open(['/table'], { queryParams: {project: this.projectId }}); 
    // }
    this.routeService.openInsideAngularZone(['/table'],
     { queryParams: {project: this.projectId }}
    ); 
  }

  isEmpty(obj) {
    if (obj)
      return Object.keys(obj).length === 0;
    return true    
  }
}
