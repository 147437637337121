<div class="clickable">
  <div class="progress-container" *ngIf="project.latestVoyageUuid !== ''">
    <label for="progressBar" class="bmd-label-static clickable">Voyage Progress</label>
    <mat-progress-bar name="progressBar" mode="determinate" [value]="currentProgress | async"></mat-progress-bar>
  </div>
  <div class="progress-container" *ngIf="project.latestVoyageUuid === ''">
    <label for="progressBar" class="bmd-label-static clickable">No Active Voyage</label>
    <mat-progress-bar mode="buffer">
    </mat-progress-bar>
  </div>
</div>