<div *ngIf="!tasksLoaded()" class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>

<kanban-board *ngIf="tasksLoaded()" 
  [filterBy]="filterBy" 
  [project]="project"
  [tasks]="tasks"
  [voyage]="activeVoyage"
  (onCreate)="pushTask($event)"
  (onUpdate)="updateTask($event)"
  (onVoyageUpdate)="updateVoyage($event)">
</kanban-board>
