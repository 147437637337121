<input
  type="text"
  placeholder="New tag..."
  #tagInput
  [formControl]="tagInputFormControl"
  (blur)="onTouched()"
  (click)="displayAutoComplete()"
  matInput
  [matAutocomplete]="matAutocomplete"
  [matChipInputFor]="chipList"
  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
  (matChipInputTokenEnd)="add($event)">
