import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ProjectModel, TaskModel, UserModel } from '../../models';
import { NotificationService, TribeService } from './../services';
import { constants } from './config/modal-config-constants';
import { QuestionControlService } from 'src/app/models/question/question-control.service';
import { QuestionBase } from 'src/app/models/question/question-base.model';
import { DropdownQuestion } from 'src/app/models/question/question-dropdown.model';
import { TaskModalComponent } from './task-modal.component';

@Component({
  selector: 'update-task-modal-content',
  templateUrl: 'update-task-modal-content.component.html',
  styleUrls: ['./config/modal-validation.css']
})
export class UpdateTaskModalContent implements OnInit {
  @Input() public project: ProjectModel;
  @Input() public task: TaskModel;
  @Input() public tribeMembers: any[];
  @Output() public onUpdateQuestion= new EventEmitter();
  @Output() public onCreateQuestion = new EventEmitter();
  //#region FORMS
  taskForm: FormGroup
  title: FormControl
  assignee: FormControl
  description: FormControl
  sharkRank: FormControl
  sharkClassification: FormControl
  tagUpdater: FormControl
  questionGroup: FormGroup
  //#endregion
  constructor(public activeModal: NgbActiveModal, 
              private notifications: NotificationService,
              private qcs: QuestionControlService) { }
  
  ngOnInit() {
    console.log('this.task', this.task)
    this.title = new FormControl(this.task.title, [
      Validators.required, 
      Validators.maxLength(constants.titleLength)
    ])
    this.description = new FormControl(this.task.description, [
      Validators.maxLength(constants.descriptionLength)
    ]);
    this.assignee = new FormControl(this.task.assignee);
    this.sharkRank = new FormControl(this.task.sharkRank);
    this.sharkClassification = new FormControl(this.task.sharkClassification);
    this.tagUpdater = new FormControl(this.task.tags, [
      Validators.maxLength(20), // at most 20 tags
    ])
    console.log('this.task.questions', this.task.questions)
    this.questionGroup = this.qcs.toFormGroup(this.task.questions)
    console.log('this.questionGroup', this.questionGroup)

    this.taskForm = new FormGroup({
      title: this.title,
      assignee: this.assignee,
      description: this.description,
      sharkRank: this.sharkRank,
      sharkClassification: this.sharkClassification,
      tagUpdater: this.tagUpdater,
      questionGroup: this.questionGroup,
    })
    console.log('ngOnInit tribeMembers', this.tribeMembers)
  }

  onSubmit(formValues) {
    console.log('formValues', formValues)
    if (!this.tasksEqual()) {
      console.log("Passing modal-content to modal component!");
      this.task.mergeFormValues(formValues)
      this.activeModal.close(this.task);
    }
    else {
      this.activeModal.close('Close click');
    }
  }

  isValid(): boolean {
    return this.taskForm.valid
  }

  closureCheck(formValues) {
    if (this.taskFormIsDirty() && this.taskForm.valid) {
      // run warning
      if (this.notifications.alertUser() === 'Save') {
        this.onSubmit(formValues)
        return
      }
    }
    // close modal
    this.activeModal.close('Close click')
  }

  initTagCtrl(tagCtrl: FormControl): void {
    console.log('initTagCtrl')
    // console.log('this.taskForm.controls', this.taskForm.controls)
    // this.taskForm.setControl('tagUpdater', tagCtrl)
    // this.taskForm.updateValueAndValidity()
    // console.log('this.taskForm.controls', this.taskForm.controls)
  }

  handleQuestion(event: {key: string, value: string}): void {
    const key = event.key
    const value = event.value

    const formControl = this.getFormControl(key) as FormControl
    if (this.notNull(formControl)) {
      // add an option to the question representing the existing form control
      this.updateQuestion(event)
    }
    else {
      // add new question to the questionGroup
      this.addQuestion(event)

      // update the questionGroup
      const group = this.qcs.toFormGroup(this.task.questions)
      this.taskForm.setControl('questionGroup', group)
      this.taskForm.updateValueAndValidity()
    }
  }

  getFormControl(name: string): AbstractControl {
    console.log('this.questionGroup', this.questionGroup)
    console.log('this.taskForm.controls', this.taskForm.controls)
    console.log('this.taskForm.get(questionGroup)', this.taskForm.get('questionGroup'))       
    
    console.log('name', name)
    const questionForm: FormGroup = this.taskForm.get('questionGroup') as FormGroup
    console.log('questionForm', questionForm)
    const formCtrl = questionForm.controls[name]
    return formCtrl
  }

  addQuestion(event: {key: string, value: string}): void {
    console.log('addQuestion', event)
    const lowerCaseKey = event.key.toLocaleLowerCase()
    const lowerCaseVal = event.value.toLocaleLowerCase()
    const label = this.capitalize(lowerCaseKey)
    this.task.questions.push(new DropdownQuestion({
      key: lowerCaseKey,
      label: label,
      order: this.task.questions.length + 1,
      options: [
        {key: lowerCaseVal, value: event.value}
      ]
    }))
    this.onCreateQuestion.emit(this.task.questions.toString())
  }

  updateQuestion(event: {key: string, value: string}): void {
    console.log('updateQuestion', event)
    const lowerCaseKey = event.key.toLocaleLowerCase()
    const lowerCaseVal = event.value.toLocaleLowerCase()
    let idx = this.task.questions.findIndex((question) => {
      return question.key === lowerCaseKey
    })
    this.task.questions[idx].options.push({key: lowerCaseVal, value: event.value})
    this.onUpdateQuestion.emit(this.task.questions.toString())
  }

  capitalize(word: string): string {
    if (word.length === 0) return ""
    let char: string = word.charAt(0);
    let capital: string = char.toUpperCase()
    let slice: string = word.slice(1)
    console.log('capitalization', capital + slice)
    return capital + slice;
  }

  notNull(val: any): boolean {
    return val !== undefined && val !== null
  }
  
  tasksEqual() {
    if (this.task.title !== this.title.value)
      return false
    if (this.task.description !== this.description.value)
      return false
    if (this.task.assignee !== this.assignee.value)
      return false
    if (this.task.sharkRank !== this.sharkRank.value)
      return false
    if (this.task.sharkClassification !== this.sharkClassification.value)
      return false
    if (this.task.tags !== this.tagUpdater.value)
      return false
    // if (this.questionsChangedValue()) {
    //   return false
    // }
    return false // FIXME
  }

  questionsChangedValue(): boolean {
    this.task.questions.forEach((question) => {
      if (question.value !== this.questionGroup[question.key]) {
        return true
      }
    })
    return false
  }

  getAssigneeUserModel(userUuid: string): UserModel {
    return this.tribeMembers.find((member) => {
      return member.uuid === userUuid
    })
  }

  taskFormIsDirty(): boolean {
    return this.taskForm.dirty
  }
}
