<form [formGroup]="voyageForm" (ngSubmit)="onSubmit(voyageForm.value)">
  <div class="modal-body">
    <div class="form-group" [ngClass]="{'error': title.invalid && title.dirty}">
      <label for="title">Voyage Name: </label>
      <div *ngIf="title.invalid && (title.dirty || title.touched)">
        <em *ngIf="title.errors.required">
          Name is required.
        </em>
        <em *ngIf="title.errors.maxlength">
          Length exceeded by {{title.errors.maxlength.actualLength - title.errors.maxlength.requiredLength}} character(s).
        </em>
      </div>
      <input type="text" formControlName="title" class="form-control">
    </div>
    <div class="form-group" [ngClass]="{'error': description.invalid && description.dirty}">
      <label>Voyage Description: </label>
      <div *ngIf="description.invalid && (description.dirty || description.touched)">
        <em *ngIf="description.errors.maxlength">
          Length exceeded by {{description.errors.maxlength.actualLength - description.errors.maxlength.requiredLength}} character(s).
        </em>
      </div>
      <textarea formControlName="description" class="form-control rounded-0"
                rows="3">
      </textarea>
    </div>
    <div *ngIf="voyage.startTime && voyage.endTime" class="form-group static">
      <div class="flex-item">
        <label for="start">Start: </label>
        <div style="margin-left: 2px" name="start">{{voyage.startTime | date: 'medium'}}</div>
      </div>
      <div class="flex-item">
        <label for="end">End: </label>
        <div style="margin-left: 2px" name="end">{{voyage.endTime | date: 'medium'}}</div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="align-items: flex-start;">
    <button type="button" class="btn btn-primary" 
      (click)="onSubmit(voyageForm.value)"
      [ngClass]="isValid() ? 'enabled' : 'disabled'">
        Update Voyage
    </button>
    <button *ngIf="voyage.uuid === project.latestVoyageUuid" type="button" class="btn btn-secondary" 
    (click)="onArchive(voyageForm.value)"
    [ngClass]="isValid() ? 'enabled' : 'disabled'">
      Archive Voyage
    </button>
    <button type="button" class="btn btn-danger" 
      (click)="closureCheck(voyageForm.value)">
        Cancel
    </button>
  </div>
</form>