import { Injectable, NgZone } from '@angular/core'
import { UserModel } from '../../models/UserModel'
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { Hub } from 'aws-amplify';
import { Logger } from 'aws-amplify';
import { Router } from '@angular/router';
import { Auth, API } from 'aws-amplify';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // set the log level to toggle debug info
  private logger: Logger = new Logger('Auth', 'DEBUG');
  authState: AuthState = AuthState.SignedOut;
  userLoggedIn: Subject<CognitoUserInterface> = new Subject();
  userLoggedOut: Subject<CognitoUserInterface> = new Subject();
  private _userSession: CognitoUserSession | undefined;
  get userSession(): CognitoUserSession {
    return this._userSession;
  }
  set userSession(session: CognitoUserSession) {
    this._userSession = session;
  }
  user: CognitoUserInterface | undefined;
  refreshTimer: any;
  refreshWaitTime: number;

  constructor() {

    console.log("Singleton Auth Service")
    const listener = (data) => {
      switch (data.payload.event) {
        case 'signIn':
          this.onSignIn(data.payload);
          this.logger.debug('user signed in');
          break;
        case 'signUp':
          this.onSignUp(data.payload);
          this.logger.debug('user signed up');
          break;
        case 'signOut':
          this.onSignOut(data);
          this.logger.debug('user signed out');
          break;
        case 'signUp_failure':
          this.onSignUpFailure(data.payload);
          this.logger.error('user sign up failed');
          break;
        case 'signIn_failure':
          this.onSignInFailure(data.payload);
          this.logger.error('user sign in failed');
          break;
        case 'configured':
          this.onConfigure(data);
          this.logger.debug('the Auth module is configured');
      }
    }
    Hub.listen('auth', listener);
  }

  async signOut() {
    try {
      await Auth.signOut()
    } catch (error) {
      console.log('error signing out', error);
    }
  }
  
  // used during page refresh event 
  async initialize() {
    if (this.authState === AuthState.SignedIn) return;
    try {
      // options params needed if we want to bypass the cache for user credentials
      // i.e. options = { bypassCache: true } 
      const user = await Auth.currentAuthenticatedUser();
      this.user = user as CognitoUserInterface;
      const userSession = await Auth.currentSession();
      this.userSession = userSession
      this.authState = AuthState.SignedIn;
      this.logger.debug('Got current user');
      this.userLoggedIn.next(this.user)
    } catch(e) {
      this.logger.debug('Failed to get current user', e);
			throw e;
    }
  }
      
  onSignIn(payload: any): void {
    console.log(payload);
    this.user = payload.data as CognitoUserInterface;
    this.userSession = payload.data.signInUserSession as CognitoUserSession;
    console.log(this.userSession)
    this.authState = AuthState.SignedIn;
    console.log("emitting event...")
    this.userLoggedIn.next(this.user);
    console.log("event emitted!");
  }

  onSignUp(payload: any) {
    console.log(payload);
    this.user = payload.data as CognitoUserInterface;
    this.authState = AuthState.ConfirmSignUp
  }

  onSignOut(payload: any) {
    console.log(payload);
    this.user = undefined;
    this.userSession = undefined;
    this.authState = AuthState.SignedOut;
    this.userLoggedOut.next(this.user);
  }

  onSignUpFailure(data: any) {
    console.log(data);
    throw new Error('Method not implemented.');
  }
  
  onSignInFailure(payload: any) {
    console.error(payload.message)
    throw new Error('Method not implemented.');
  }

  onConfigure(data: any) {
    console.log(data);
    throw new Error('Method not implemented.');
  }

  async refreshUser() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = cognitoUser.signInUserSession;
      cognitoUser.refreshSession(currentSession.refreshToken,
      (err, session) => {
        this.userSession = session as CognitoUserSession;
      });
    } catch (e) {
      // whatever
    }
  }
    
  loggedIn(): boolean {
    // console.log(this.user)
    // console.log(this.authState)
    return this.authState === AuthState.SignedIn && !!(this.user);
  }

  isAuthenticated(): boolean {
    if (!this.loggedIn()) return false
    return this.isSessionValid()
  }

  isSessionValid(): boolean {
    // returns true if the id and access tokens have not expired
    // returns false if userSession is undefined
    // console.log('inside isSessionValid', this.userSession)
    return this.userSession ? this.userSession.isValid() : false
  }

  signedOut(): boolean {
    return this.authState === AuthState.SignedOut
  }

  getUserEmail(): string {
    return this.user.attributes.email;
  }

  getUserUuid(): string {
    return this.user.attributes.sub
  }

  ngOnDestroy() {
    this.userLoggedIn.unsubscribe();
    this.userLoggedOut.unsubscribe();
  }

  //#region DEPRECATED
  async signIn() {
    try {
      const user = await Auth.signIn('unknown', 'unknown');
    } 
    catch (e) {
      console.log('error signing in', e);
    }
  }
  async getCookie() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.user = user as CognitoUserInterface;
      const username = this.getUserEmail();
      const key = "CognitoIdentityServiceProvider.7qdo1mdeq5gnffqmpsl4b3g7si." + username + ".accessToken";
      let promise = new Promise((resolve, reject) => {
        const options = {
          headers: {
            // 'Content-Type': 'application/json',
            // 'x-amz-date': this.getNormalizedDateString(new Date()),
            // "Access-Control-Request-Headers": "access-control-allow-origin",
            // 'Access-Control-Allow-Origin': '*',
            'Authorization': this.user.storage[key],
            // 'x-api-key': '2JcfNsDAEq1VtQa1lkrVa9GSUiD7P3CL9ItHED0u',
            // 'Accept': '*/*'
          }, // OPTIONAL
          response: true
        };
        API.get("GetCookie", `/users`, options)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        })
      });
    }
    catch(e) {
      this.logger.debug('Failed to get current user', e);
			throw e;
    }
  }
  //#endregion
}
