import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserInfoService } from './../services';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuardService implements CanActivate {

  constructor(private userService: UserInfoService, private routeService: RouteService) { }

  canActivate() {
    if (this.userService.active()) {
      return true;
    }
    console.log('not subscribed!')
    // this.routeService.openInsideAngularZone(['payment']);
    return false;
  }
}
