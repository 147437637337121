import { SubscriptionModel } from "./SubscriptionModel";

export class UserModel
{
	uuid: string;
	firstname: string;
	lastname: string;
	email: string;
	projects?: string[];
  subscription?: SubscriptionModel;
	avatarName?: string;
	avatarUrl?: FormData;

	constructor(obj: any = null)
	{
		if(obj != null)
		{
			Object.assign(this, obj);
      if ('username' in obj) {
        this.email = obj.username;
      }
		}
	}

  includes?(searchTerm: string): boolean {
    if (this.uuid.includes(searchTerm))
      return true
    if (this.firstname.includes(searchTerm))
      return true
    if (this.lastname.includes(searchTerm))
      return true
    if (this.email.includes(searchTerm))
      return true
    return false
  }
}