import { Input, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectModel, VoyageModel } from '../../../models';
import { NotificationService } from './../../services';
import { constants } from '../config/modal-config-constants';

@Component({
  selector: 'create-voyage-modal-content',
  templateUrl: 'create-voyage-modal-content.component.html',
  styleUrls: ['./../config/modal-validation.css']
})
export class CreateVoyageModalContent implements OnInit{
  @Input() public project: ProjectModel;
  public voyage: VoyageModel;
  //#region 
  voyageForm: FormGroup
  title: FormControl
  description: FormControl
  length: FormControl

  constructor(public activeModal: NgbActiveModal, public notifications: NotificationService) { }
  
  ngOnInit() {
    this.voyage = new VoyageModel();
    this.title = new FormControl('', [
      Validators.maxLength(constants.titleLength)
    ])
    this.description = new FormControl('', [
      Validators.maxLength(constants.descriptionLength)
    ]);
    this.length = new FormControl(this.project.autoVoyageLength || 2);
    this.voyageForm = new FormGroup({
      title: this.title,
      description: this.description,
      length: this.length
    })
  }

  onSubmit(formValues) {
    let newVoyage = new VoyageModel(this.voyage);
    newVoyage.name = this.getVoyageTitle(formValues.title);
    newVoyage.description = formValues.description;
    newVoyage.lengthInWeeks = formValues.length;
    newVoyage.status = 'upcoming' 
    if (this.project.voyageCount === 0 || this.hasNoActiveVoyage())
    {
      newVoyage.status = 'active'
    }
    console.log(newVoyage)
    this.activeModal.close(newVoyage);
  }

  hasNoActiveVoyage(): boolean {
    return (this.project.latestVoyageUuid === '' 
    || this.project.latestVoyageUuid === undefined)
  }

  getVoyageTitle(title: string): string {
    if (title.length === 0) return this.getVoyagePrefix()
    return this.getVoyagePrefix() + ' - ' + title
  }

  getVoyagePrefix(): string {
    if (typeof(this.project.voyages) === 'undefined') {
      // if no voyages exist
      return 'V1'
    }
    else {
      let voyageNum = this.project.voyageCount + 1
      return 'V' + voyageNum.toString()
    }
  } 

  isValid(): boolean {
    return this.voyageForm.valid;
  }

  closureCheck(formValues) {
    if (this.formIsDirty() && this.isValid()) {
      // run warning
      if (this.notifications.alertUser() === 'Save') {
        this.onSubmit(formValues)
        return
      }
    }
    // close modal
    this.activeModal.close('Close click')
  }

  formIsDirty(): boolean {
    return this.voyageForm.dirty;
  }
}