import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VoyageModel } from 'src/app/models';

@Component({
  selector: 'confirm-modal',
  template:`
    <div class="modal-header">
      <h4 class="modal-title">{{voyage.name}}</h4>
      <button type="button" class="close" aria-label="Cancel" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label for="confirm">Are you sure you wish to deactivate the current voyage?</label> <br>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" 
        (click)="activeModal.close(voyage)">
        Confirm
      </button>
    </div> 
  `
})
export class ConfirmModalComponent {

  @Input() public voyage: VoyageModel;
  public confirmation: string = 'confirm'
  constructor(public activeModal: NgbActiveModal) { }

  passBack(): void
  {
    console.log("Confirming user action...");
  }
}