import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { QuestionBase } from './../question-base.model';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html'
})
export class DynamicFormQuestionComponent {
  @Input() question!: QuestionBase<string>;
  @Input() form!: FormGroup;
  public subscription: Subscription;

  get isValid() {
    // console.log('this.question.key', this.question.key)
    // console.log('this.form.controls', this.form.controls)
    return this.form.controls[this.question.key].valid;
  }

}