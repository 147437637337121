/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import cloud_config from './cloud-config'; 

const awsconfig = {
  Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: cloud_config.cognito.IDENTITY_POOL_ID,

      // REQUIRED - Amazon Cognito Region
      region: cloud_config.cognito.REGION,

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'XX-XXXX-X',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: cloud_config.cognito.USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: cloud_config.cognito.APP_CLIENT_ID,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: cloud_config.oauth.COOKIE,

      // OPTIONAL - customized storage object
      // storage: MyStorage,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_PASSWORD_AUTH',

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      clientMetadata: { myCustomKey: 'myCustomValue' },

      // OPTIONAL - Hosted UI configuration
      // oauth: {
      //   domain: cloud_config.oauth.DOMAIN,
      //   scope: cloud_config.oauth.SCOPES,
      //   redirectSignIn: cloud_config.oauth.REDIRECT_SIGN_IN,
      //   redirectSignOut: cloud_config.oauth.REDIRECT_SIGN_OUT,
      //   responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      // }
  },
  API: {
    endpoints: [
      {
        name: "GetCookie",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL
      },
      {
        name: "GetProjects",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL
      },
      {
        name: "CreateProject",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL
      },
      {
        name: "UpdateProject",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL
      },
      {
        name: "GetTasks",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL,
      },
      {
        name: "CreateTask",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL,
      },
      {
        name: "UpdateTask",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL,
      },
      {
        name: "GetTribe",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL,
      },
      {
        name: "CreateUser",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL,
      },
      {
        name: "CreateEmailInvite",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL,
      },
      {
        name: "PromoteUser",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL
      },
      {
        name: "CreateVoyage",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL,
      },
      {
        name: "UpdateVoyage",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL,
      },
      {
        name: "GetUser",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL,
      },
      {
        name: "UpdateUser",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL,
      },
      {
        name: "CreateSubscription",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL,
      },
      {
        name: "CancelSubscription",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL,
      },
      {
        name: "CreateAvatar",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL,
      },
      {
        name: "GetAvatar",
        region: cloud_config.apiGateway.REGION,
        endpoint: cloud_config.apiGateway.URL,
      }
    ]
  }
};


export default awsconfig;
