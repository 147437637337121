import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProjectModel } from '../../models/ProjectModel';
import { TaskModel } from '../../models/TaskModel';
import { Observable, of, from } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { UserModel } from '../../models/UserModel';
import { HttpService } from './http.service';
import { AuthService } from '../auth/auth.service';
import { AuthState } from '@aws-amplify/ui-components';
import { VoyageModel } from '../../models';
import { SubscriptionModel } from '../../models/SubscriptionModel';
import { RestAPIService } from './rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class HttpCloudService extends HttpService {

  urlRoot: string = '/api';

  constructor(private client: HttpClient, 
    private api: RestAPIService, 
    private auth: AuthService) { 
    super(client)
  }

  //#region PROJECTS
  pullProjects(): Observable<ProjectModel[]>
  {
    const uuid = this.auth.getUserUuid()
    let promise = new Promise<ProjectModel[]>((resolve, reject) => {
      if (this.auth.signedOut()) {
        reject('User signed out!');
      }
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        }, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      };
      this.api.get('GetProjects', `/projects/${uuid}`, options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    });
    return from(promise)
      .pipe(catchError(this.handleError<ProjectModel[]>('pullProjects', [])));
  }

  pushProject(project: ProjectModel): Observable<ProjectModel>
  {
    // Server-supplied values: uuid, taskCount, daysUntilTaskDeletion
    const uuid = this.auth.getUserUuid();
    let promise = new Promise<TaskModel[]>((resolve, reject) => {
      const body = {
        'projectName': project.name,
        'projectDescription': project.description,
        'userUuid': uuid
      };
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        }, // OPTIONAL
        body: body,
        response: true
      };
      this.api.put('CreateProject', `/projects`, options)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err);
      })
    })
    return from(promise)
      .pipe(catchError(this.handleError<any>('pushProject')));
  }

  updateProject(project: ProjectModel): Observable<any> {
    console.log('project', project)
    let promise = new Promise<TaskModel[]>((resolve, reject) => {
      const body = {
        'projectUuid': project.uuid,
        'projectName': project.name,
        'projectDescription': project.description,
        'daysUntilTaskDeletion': project.daysUntilTaskDeletion.toString(),
        'defaultVoyageLength': project.autoVoyageLength.toString(),
        'voyageEnabled': project.voyageEnabled,
        'tags': JSON.stringify(project.tags)
      };
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        }, // OPTIONAL
        body: body,
        response: true
      };
      this.api.patch('UpdateProject', `/projects`, options)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err);
      })
    })
    return from(promise)
      .pipe(catchError(this.handleError<any>('updateProject')));
  }
  //#endregion

  //#region TASKS
  pullTasks(projectParam: string): Observable<TaskModel[]>
  {
    let promise = new Promise<TaskModel[]>((resolve, reject) => {
      const options = {
        params: {
          project: projectParam
        },
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        }, // OPTIONAL
        response: true
      };
      
      this.api.get('GetTasks', `/tasks/${projectParam}`, options)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err);
      })
    })
    return from(promise)
      .pipe(catchError(this.handleError<TaskModel[]>('pullTasks', [])));
  }

  pullTasksLazy(project: ProjectModel, numberOfTasks: number, lastKey:string): Observable<TaskModel[]>
  {
    let queryParams = {};
    if(lastKey)
    {
      queryParams = {$top:numberOfTasks, $lastKey:lastKey};
    }
    else
    {
      queryParams = {$top:numberOfTasks};
    }

    let promise = new Promise<TaskModel[]>((resolve, reject) => {
      const options = {
        params: {},
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken()
        },
        queryStringParameters:queryParams,
        response: true
      };
      this.api.get('GetTasks', `/tasks/${project.uuid}`, options)
      .then((res) => {
        console.log(res);
        resolve(res)
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      })
    })
    return from(promise)
      .pipe(catchError(this.handleError<TaskModel[]>('pullTasksLazy', [])));
  }

  pushTask(task: TaskModel, project: ProjectModel): Observable<any>
  {
    const username = this.auth.getUserEmail();
    let promise = new Promise<TaskModel>((resolve, reject) => {
      const body = {
        'taskTitle': task.title, 
        'taskStatus': task.status, 
        'description': task.description, 
        'sharkRank': task.sharkRank.toString(),
        'sharkClassification': task.sharkClassification,
        'projectUuid': project.uuid,
        'projectName': project.name,
        'assignee': task.assignee,
        'changedBy' : username,
        'tags': JSON.stringify(task.tags),
        'questions': JSON.stringify(task.questions)
      };
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        }, // OPTIONAL
        body: body,
        response: true
      }
      this.api.put('CreateTask', `/tasks`, options)
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
    return from(promise)
      .pipe(catchError(this.handleError<TaskModel>('pushTask')));
  }
  
  updateTask(task: TaskModel, project: ProjectModel): Observable<TaskModel>
  {
    const email = this.auth.getUserEmail();
    console.log('task', task)
    let promise = new Promise<TaskModel>((resolve, reject) => {
      const body = {
        'taskTitle': task.title, 
        'taskStatus': task.status, 
        'description': task.description, 
        'sharkRank': task.sharkRank,
        'sharkClassification': task.sharkClassification,
        'projectUuid': project.uuid,
        'taskUuid': task.uuid,
        'projectName': project.name,
        'assignee': task.assignee,
        'latestUpdate': task.latestUpdate,
        'latestChangeBy': email,
        'changedBy': email,
        'tags': JSON.stringify(task.tags),
        'questions': JSON.stringify(task.questions)
      };
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        }, // OPTIONAL
        body: body,
        response: true
      }
      this.api.patch('UpdateTask', `/tasks`, options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
    return from(promise)
      .pipe(catchError(this.handleError<TaskModel>('updateTask')));
  }
  //#endregion

  //#region TRIBE
  pullTribe(projectUuid: string): Observable<UserModel[]>
  {
    let promise = new Promise<UserModel[]>((resolve, reject) => {
      const options = {
        params: {
          project: projectUuid
        },
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        }, // OPTIONAL
        response: true
      };
      this.api.get('GetTribe', `/tribe/${projectUuid}`, options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
    return from(promise)
      .pipe(catchError(this.handleError<UserModel[]>('pullTribe', [])));
  }
  //#endregion

  //#region USERS
  pullUser() {
    const username = this.auth.getUserEmail();
    let promise = new Promise<UserModel>((resolve, reject) => {
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        },
        response: true
      };
      console.log(options);
      this.api.get('GetUser', `/users/${username}`, options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
    return from(promise)
      .pipe(catchError(this.handleError<UserModel>('getUser')));
  }

  updateUserSubscription(newSub: SubscriptionModel): Observable<UserModel> {
    const username = this.auth.getUserEmail();
    const uuid = this.auth.getUserUuid();
    let promise = new Promise<UserModel>((resolve, reject) => {
      const options = {
        body: {
          'email': username,
          'subscriptionId': newSub.id,
          'subscriptionStatus': newSub.status
        },
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        },
        response: true
      };
      this.api.patch('UpdateUser', `/users/${uuid}`, options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
    return from(promise)
      .pipe(catchError(this.handleError<UserModel>('updateUser')));
  }

  pullSubscription(user: UserModel): Observable<SubscriptionModel> {
    let subscriptionId = user.subscription.id;
    let promise = new Promise<SubscriptionModel>((resolve, reject) => {
      const xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {  
        if (this.readyState === 4 && this.status === 200) {  
          let jsonBody = JSON.parse(this.responseText)
          console.log(jsonBody);  
          let subscription = new SubscriptionModel({
            id: subscriptionId,
            status: jsonBody.status,
            status_update_time: jsonBody['status_update_time'],
            plan_id: jsonBody['plan_id'],
            start_time: jsonBody['start_time']
          })
          resolve(subscription);
        }
        else {
          reject(this.responseText)
        }  
      };  
      xhttp.open('GET', 'https://api.sandbox.paypal.com/v1/billing/subscriptions/' + subscriptionId, true);  
      xhttp.setRequestHeader('Authorization', 'Basic Auth');  
      
      xhttp.send();
    })
    return from(promise)
    .pipe(catchError(this.handleError<SubscriptionModel>('pullSubscription')));
  }

  createSubscription(user: UserModel): Observable<SubscriptionModel>
  {
    console.log(user);
    let subscriptionId = user.subscription.id;
    let promise = new Promise<SubscriptionModel>((resolve, reject) => {
      const body = {
        'userUuid': user.uuid,
        'email': user.email,
        'subscriptionId': subscriptionId 
      };
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        }, // OPTIONAL
        body: body,
        response: true
      };
      this.api.put('CreateSubscription', `/users/subscription`, options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
    return from(promise)
      .pipe(catchError(this.handleError<SubscriptionModel>('pullSubscription')));
  }

  softCancelSubscription(user: UserModel): Observable<SubscriptionModel> {
    let subscriptionId = user.subscription.id;
    let promise = new Promise<SubscriptionModel>((resolve, reject) => {
      const body = {
        'userUuid': user.uuid,
        'subscriptionId': subscriptionId,
        'cancelation': 'soft'
      };
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        }, // OPTIONAL
        body: body,
        response: true
      };
      this.api.post('CancelSubscription', `/users/subscription`, options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
    return from(promise)
      .pipe(catchError(this.handleError<SubscriptionModel>('softCancelSubscription')));
  }

  hardCancelSubscription(user: UserModel): Observable<SubscriptionModel> {
    let subscriptionId = user.subscription.id;
    let promise = new Promise<SubscriptionModel>((resolve, reject) => {
      const body = {
        'userUuid': user.uuid,
        'subscriptionId': subscriptionId,
        'cancelation': 'hard'
      };
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        }, // OPTIONAL
        body: body,
        response: true
      };
      this.api.post('CancelSubscription', `/users/subscription`, options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
    return from(promise)
      .pipe(catchError(this.handleError<SubscriptionModel>('hardCancelSubscription')));
  }
  //#endregion USERS
  
  //#region SETTINGS
  pushEmailInvite(email: string, projectUuid: string): Observable<Object>
  {
    const host = this.auth.getUserEmail()
    let promise = new Promise<Object>((resolve, reject) => {
      const body = {
        'host': host,
        'email': email, // invitee
        'project': projectUuid
      };
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        }, // OPTIONAL
        body: body,
        response: true
      };
      this.api.post('CreateEmailInvite', `/settings/invite`, options)
      .then((res) => {
        console.log(res)
        resolve(res);
      })
      .catch((err) => {
        console.error(err)
        reject(err);
      })
    })
    return from(promise)
      .pipe(catchError(this.handleError<Object>('pushEmailInvite')));
  }

  pushPromotion(email: string, projectUuid: string): Observable<Object> 
  {
    const host = this.auth.getUserEmail();
    let promise = new Promise<Object>((resolve, reject) => {
      const body = {
        'promoter': host,
        'promotee': email,
        'project': projectUuid
      };
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        },
        body: body,
        response: true
      };
      this.api.post('PromoteUser', `/settings/promote`, options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
    return from(promise)
      .pipe(catchError(this.handleError<Object>('pushPromotion')));
  }
  
  createAvatar(fileUuid: string) {
    const userUuid = this.auth.getUserUuid()
    let promise = new Promise<string>((resolve, reject) => {
      if (this.auth.signedOut()) {
        reject('User signed out!');
      }
      const body = {
        'fileName': fileUuid
      }
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken()
        }, // OPTIONAL
        body: body,
        response: true,
        responseType: 'text'
      };
      this.api.put('CreateAvatar', `/avatars/${userUuid}`, options)
      .then((res) => {
        console.log(res.data)
        resolve(res);
      })
      .catch((err) => {
        console.log(err)
        reject(err);
      })
    });
    return from(promise)
      .pipe(catchError(this.handleError<string>('CreateAvatar')));
  }

  uploadAvatar(preSignedUrl: string, file: File): Observable<any>
  {
    if (this.auth.signedOut()) {
      return from(Promise.reject('User signed out!'));
    }
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'image/jpeg',
        'Accept':'*/*'
      })
    }
    return from(this.client.put(preSignedUrl, file, options))
      .pipe(catchError(this.handleError<string>('UploadAvatar')));
  }

  getAvatar(userUuid: string) {
    let promise = new Promise<string>((resolve, reject) => {
      if (this.auth.signedOut()) {
        reject('User signed out!');
      }
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken()
        }, // OPTIONAL
        response: true,
        responseType: 'text'
      };
      this.api.get('GetAvatar', `/avatars/${userUuid}`, options)
      .then((res) => {
        console.log(res)
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      })
    });
    return from(promise)
      .pipe(catchError(this.handleError<string>('GetAvatar')));
  }
  //#endregion

  //#region VOYAGES
  pushVoyage(projectUuid: string, voyage: VoyageModel) {
    let promise = new Promise<VoyageModel>((resolve, reject) => {
      if (this.auth.authState === AuthState.SignedOut) {
        reject('User signed out!');
      }
      const body = {
        'voyageName': voyage.name,
        'voyageGoal': voyage.description,
        'lengthInWeeks': voyage.lengthInWeeks.toString(),
        'status': voyage.status
      }
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        }, // OPTIONAL
        body: body,
        response: true
      };
      this.api.put('CreateVoyage', `/voyage/${projectUuid}`, options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    });
    return from(promise)
      .pipe(catchError(this.handleError<ProjectModel[]>('pushVoyage')));
  }

  updateVoyage(projectUuid: string, voyage: VoyageModel) {
    let promise = new Promise<VoyageModel>((resolve, reject) => {
      if (this.auth.authState === AuthState.SignedOut) {
        reject('User signed out!');
      }
      let body = {
        'voyageUuid': voyage.uuid,
        'voyageName': voyage.name,
        'voyageGoal': voyage.description,
        'status': voyage.status,
        'lengthInWeeks': voyage.lengthInWeeks.toString()
      }
      
      const options = {
        headers: {
          'Authorization': this.auth.userSession.getAccessToken().getJwtToken(),
        }, // OPTIONAL
        body: body,
        response: true,
      };
      this.api.patch('UpdateVoyage', `/voyage/${projectUuid}`, options)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    });
    return from(promise)
      .pipe(catchError(this.handleError<ProjectModel[]>('UpdateVoyage')));
  }
  //#endregion

  handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error.message === "Network Error") {
        return from(Promise.reject(error))
      }
      if (result === undefined) {
        // pass the error through to another handler
        return from(Promise.reject(error))
      }
      return of(result as T);
    }
  }
}
