<div class="card-body">
  <div class="card-title" style="font-weight: bold;" >{{projectName}} - {{task?.viewId}}</div>
  <p class="card-meta">{{task.title}}</p>
  <p class="card-meta">Difficulty: {{task.sharkRank}}</p>
  <p class="card-meta">Classification: {{task.sharkClassification}}</p>
  <hr>
  <label class="card-link">On Duty:

    <h5><mdb-badge pill="true" primary="true">{{tribeMember.firstname}}</mdb-badge></h5>
  </label>
  
  <task-details-button [projectId]="projectId" class="card-link float-right" [task]="task" 
    (messageEvent)="pass($event)">
  </task-details-button>
</div>