import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { onAuthUIStateChange, CognitoUserInterface, AuthState, FormFieldTypes, AuthStateHandler } from '@aws-amplify/ui-components';
import { Hub } from 'aws-amplify';
import { NotificationService, UserInfoService, RouteService } from '../shared';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  
  title = 'amplify-angular-auth';
  user: CognitoUserInterface | undefined;
  authState: AuthState;
  signUpFormFields: FormFieldTypes;
  signInFormFields: FormFieldTypes;
  signInHandler: AuthStateHandler;
  
  constructor(private ref: ChangeDetectorRef, 
    private notifications: NotificationService, 
    private ngZone: NgZone,
    private routeService: RouteService,
    private userService: UserInfoService) { 
    // the type attribute of each form field is a one-to-one correspondance
    // with the cognito user pool allowed attributes
    const listener = (data) => {
      console.log('Hub data.payload.event', data.payload.event)
      switch (data.payload.event) {
        case 'signIn':
          this.onSignIn(data.payload)
          break;
        case 'signUp':
          this.onSignUp(data.payload)
          break;
        case 'signUp_failure':
          this.onSignUpFailure(data.payload);
          break;
        case 'signIn_failure':
          this.onSignInFailure(data.payload);
          break;
      }
    }
    Hub.listen('auth', listener);
    this.signInHandler = (nextAuthState: AuthState, data: any) => {
      console.log('rigged!')
      console.log(nextAuthState)
      console.log(data)
    }
    this.signUpFormFields = [
      {
        type: "name",
        label: "First Name *",
        placeholder: "Enter your first name",
        required: true,
      },
      {
        type: "family_name",
        label: "Last Name *",
        placeholder: "Enter your last name",
        required: true,
      },
      {
        type: "email",
        label: "Email *",
        placeholder: "Enter your email",
        required: true,
      },
      {
        type: "password",
        label: "Password *",
        placeholder: "Create a new password",
        required: true,
      },
      {
        type: "phone_number",
        label: "Phone Number",
        placeholder: "Enter your phone number",
        required: false,
      },
    ];
    this.signInFormFields = [
      {
        type: "username",
        label: "Username or Email *",
        placeholder: "Enter your username or email",
        required: true,
      },
      {
        type: "password",
        label: "Password *",
        placeholder: "Enter your password",
        required: true,
      }
    ];
  }

  ngOnInit() {
    onAuthUIStateChange((authState, authData) => {
      console.log("inside")
      console.log(authState)
      console.log(authData)
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.ref.detectChanges();
    })
  }

  async onSignUp(event: any): Promise<void> {
    console.log(event);
    await this.userService.getUser()
    this.routeService.openInsideAngularZone(['./projects'])
  }

  async onSignIn(event: any): Promise<void> {
    console.log(event)
    await this.userService.getUser()
    this.routeService.openInsideAngularZone(['./projects'])
  }

  onSignUpFailure(payload: any) {
    console.error(payload.message)
    if (payload.data.message) {
      let msg = payload.data.message
      this.ngZone.run(() => {
        this.notifications.showError(msg, 'Log in failed!')
      })
    }
    throw new Error('Method not implemented.');
  }
  
  onSignInFailure(payload: any) {
    console.log(payload)
    console.error(payload.message)
    console.log(payload.data)
    console.log(payload.data.message)
    let msg = payload.data.message as string
    this.ngZone.run(() => {
      let toast = this.notifications.showError(msg, 'Log in failed!')
      toast.toastRef.afterActivate().subscribe({
        next: (value) => {console.log(value)}
      })
    })
    throw new Error('Method not implemented.');
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }
}
