<div *ngIf="!projectsLoaded()" class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>
<div class="container" *ngIf="projectsLoaded()">
  <div class="row container-header">
    <div class="col-sm-12">      
    </div>
  </div>
  <div class="row">
    <div class="card-container col-sm-12">
      <div class="col-sm-3" [id]="project.uuid" 
        *ngFor="let project of projects"
        style="margin-bottom: 30px">
        <mdb-card class="project-card">  
          <div class="card-header">
            {{project.name}}
          </div>    
          <div class="card-body">
            <p class="card-text">
              {{project.description}}
            </p>
            <div class="btn-container">
              <button title="Open Task Board" (click)="openBoard(project.uuid)" 
                mdbBtn color="primary" mdbWavesEffect>
                <span class="glyphicon glyphicon-tasks"></span> 
              </button>
              <create-task-button [projectId]="project.uuid" [defaultStatus]="defaultStatus" 
                (messageEvent)="receiveTaskInfo($event, project)">
                <span class="glyphicon glyphicon-plus"></span> 
              </create-task-button>
            	<open-task-table-button [projectId]="project.uuid">
              	<span class="glyphicon glyphicon-list-alt"></span>
            	</open-task-table-button>
              <button title="Open Project Settings" (click)="openSettings(project.uuid)" class="btn"
                 mdbBtn color="primary" mdbWavesEffect>
              	<span class="glyphicon glyphicon-cog"></span>
            	</button>
            </div>
          </div>
          <div class="card-footer">
            <app-voyage-well *ngIf="project.voyageEnabled"
              [project]="project" 
              (onVoyageViewOpen)="openVoyageView($event)">
            </app-voyage-well>
          </div>
        </mdb-card>
      </div>
      <div class="col-sm-3">
        <mdb-card>
          <div class="card-header">
            New Project
          </div> 
          <mdb-card-body>    
            <mdb-card-text> 
              <h5>
              To begin your journey, start here.
              </h5>
            </mdb-card-text>
            <create-project-button (messageEvent)="receiveProjectInfo($event)">
              <div>Create</div>
            </create-project-button>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
  </div>
</div>
