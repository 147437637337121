import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from './../../services';
import { CreateVoyageModalContent } from './../../modals'
import { ProjectModel, VoyageModel } from '../../../models';

@Component({
  selector: 'create-voyage-button',
  templateUrl: './create-voyage-button.component.html',
  styleUrls: ['./create-voyage-button.component.css']
})
export class CreateVoyageButtonComponent {

  @Input() project: ProjectModel
  @Output() messageEvent = new EventEmitter<VoyageModel>();

  constructor(private modalService: ModalService) { }

  openModal():void {
    console.log('User creating voyage...')
    this.modalService.open(CreateVoyageModalContent, this.project)
    .then((result) => {
      console.log(result);
      // pass data to display-user-projects component
      this.messageEvent.emit(result);
    })
    .catch((result) => {
      // pass data to display-user-projects component
      console.log(result);
      this.messageEvent.emit(result);
    })
  }

  openCreateModal(project: ProjectModel) {
    let data = {
      voyage: null,
      project: project
    }
    this.modalService.openVoyageModal(CreateVoyageModalContent, data)
    .then((result) => {
      let incomingVoyage = new VoyageModel(result)
      console.log(incomingVoyage)
      this.messageEvent.emit(incomingVoyage);
    })
  }
}
