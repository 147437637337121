<h1 class="d-flex justify-content-center">About Us</h1>

<div class="mission-container">
  <div class="mission-row">
    <p class="item-center">
      "When project management becomes another project..."
    </p>
  </div>
  
  <div class="mission-row">
    <h1 class="item">What is SharkTracker?</h1>
    <p class="item">
      SharkTracker is a project management tool designed to free your mind and hone your attention,
      because we believe your brain deserves these things. 
      But wait, as a project management tool, 
      how can it free your mind and hone your attention?
      It frees your mind by cutting out screen distractions 
      <em>and</em> sharpens your attention with simple features allowing you to stay productive.
      SharkTracker focuses on getting tasks done, not on gimmicks, to improve results.
    </p>
  </div>
  
  <div class="mission-row">
    <h1 class="item">Who is SharkTracker for?</h1> 
    <p class="item">
      Curious people. 
      People who want to free their minds from distraction while they work.
      Software developers, artists, musicians, students, and many more people on small teams or working alone
      will benefit from SharkTracker. If you believe, as we do, that you should spend
      your cognitive budget honing your attention on things that matter, then you will benefit from SharkTracker.
    </p>
  </div>
  
  
  <div class="mission-row">
    <h1 class="item">
      When is SharkTracker?
    </h1>
    <p class="item">
      The full release is some time away. Currently, we’re gathering feedback of our first test release 
      starting April 24, 2021. Email us at info@thesharktracker.com if you're interested in trying the
      pre-release version with all its charms and bugs.  
    </p>
  </div>

  <div class="mission-row">
    <h1 class="item">Who are we?</h1>
    <p class="item">
      We're a small team with a big imagination. And we use SharkTracker for our own development needs.
      To plug the technical jargon that some may care about, we're a team of UI/UX software developers,
      back-end cloud developers, dev ops engineers, and security IT personnel rolled into one 
      with generous support from business folk we tricked into helping us. 
    </p>
  </div>
  
  <!-- <div class="mission-row">
    <h1>
      Why is SharkTracker?
    </h1>
    <p>
      SharkTracker exists because no one wants their attention squandered without a say.
    </p>
  </div> -->
  

  <!-- <div class="mission-row">
    <h1>
      Where is SharkTracker?
    </h1>
    <p>
      Somewhere out there teaching complexity a lesson. 
    </p>
  </div> -->
<!--   
  <div class="team-container">
    <div class="row row-cols-1 row-cols-md-2 g-4">
      <div class="col">
        <div class="card">
          <img
            src="https://mdbootstrap.com/img/new/standard/city/041.jpg"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">Aaron</h5>
            <p class="card-text">
              This is a longer card with supporting text below as a natural lead-in to
              additional content. This content is a little bit longer.
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <img
            src="https://mdbootstrap.com/img/new/standard/city/042.jpg"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">Victor</h5>
            <p class="card-text">
              This is a longer card with supporting text below as a natural lead-in to
              additional content. This content is a little bit longer.
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <img
            src="https://mdbootstrap.com/img/new/standard/city/043.jpg"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">Hugo</h5>
            <p class="card-text">
              This is a longer card with supporting text below as a natural lead-in to
              additional content.
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <img
            src="https://mdbootstrap.com/img/new/standard/city/044.jpg"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">Chris</h5>
            <p class="card-text">
              This is a longer card with supporting text below as a natural lead-in to
              additional content. This content is a little bit longer.
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <img
            src="https://mdbootstrap.com/img/new/standard/city/044.jpg"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">Brad</h5>
            <p class="card-text">
              This is a longer card with supporting text below as a natural lead-in to
              additional content. This content is a little bit longer.
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <img
            src="https://mdbootstrap.com/img/new/standard/city/044.jpg"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">David</h5>
            <p class="card-text">
              This is a longer card with supporting text below as a natural lead-in to
              additional content. This content is a little bit longer.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
 -->

</div>

