<!-- <div id="paypal-button-container">
  <paypal-buttons [props]="{
    createOrder: createOrder,
    onApprove: onApprove}">
  </paypal-buttons>

</div> -->

<div class="options-container">
  <h1>Choose your plan</h1>
  <div class="card-container">
    <mdb-card class="payment-card" (click)="toggleMonthly()" 
      [ngClass]="{'payment-card-active': selectedPlanId === monthlyPlanId}">  
      <div class="card-header">
        <div class="card-header">The Sailor</div>
      </div>    
      <div class="card-body">
        <h5 class="card-title">Monthly Subscription</h5>
        <h6 class="card-subtitle mb-2 text-muted">$3.00 per month</h6>
        <p class="card-text">
          Includes all features
        </p>
      </div>
      <div class="card-footer">

      </div>
    </mdb-card>
  
    <mdb-card class="payment-card" (click)="toggleYearly()"  
      [ngClass]="{'payment-card-active': selectedPlanId === yearlyPlanId}">  
      <div class="card-header">
        <div class="card-header">The Captain</div>
      </div>    
      <div class="card-body">
        <h5 class="card-title">Yearly Subscription</h5>
        <h6 class="card-subtitle mb-2 text-muted">$30.00 per year</h6>
        <p class="card-text">
          Includes all features
        </p>
      </div>
      <div class="card-footer">

      </div>
    </mdb-card>
  </div>

  <div class="paypal-container" [hidden]="!planSelected()">
    <div class="btn-group" #paypal></div>  
  </div>
    
    <!-- <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Monthly Subscription</mat-card-title>
        <mat-card-subtitle>$3.00 a month</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image src="portal\sharktracker\src\assets\images\boat.png">
      <mat-card-content>
        <p>
          Includes all features
        </p>
      </mat-card-content>
      <mat-card-actions>
        <ngx-paypal [config]="payPalConfig"></ngx-paypal>
      </mat-card-actions>
    </mat-card>
  <mat-card class="example-card">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title>Yearly Subscription</mat-card-title>
      <mat-card-subtitle>$30.00 a year</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image src="portal\sharktracker\src\assets\images\boat.png">
    <mat-card-content>
      <p>
        Includes all features
      </p>
    </mat-card-content>
    <mat-card-actions>
      <ngx-paypal [config]="payPalConfig"></ngx-paypal>
    </mat-card-actions>
  </mat-card> -->

</div>
