import { Component, NgZone } from '@angular/core';
import { RouteService } from '../shared';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent {
  constructor(private routeService: RouteService,
    private ngZone: NgZone) { }

  openFaqs() {
    this.routeService.openInsideAngularZone(['/FAQ']);
  }

  openAboutUs() {
    this.routeService.openInsideAngularZone(['/about-us']);
  }

  openContactUs() {
    this.routeService.openInsideAngularZone(['/contact-us']);
  }

}
