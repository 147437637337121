import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './../auth';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuardService implements CanActivate {

  constructor(private authService: AuthService,
    private routeService: RouteService) { }

  canActivate(): boolean {
    if (this.authService.isAuthenticated()) {
      return true
    }
    console.log('not authenticated!')
    this.routeService.openInsideAngularZone(['login']);
    return false;
  }
}
