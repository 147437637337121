import { Injectable, TemplateRef, Type } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModel, ProjectModel, TaskModel, VoyageModel } from './../../models';
import { ProjectService } from './project.service';
import { TribeService } from './tribe.service';

export type Content<T> = string | TemplateRef<T> | Type<T>

@Injectable()
export class ModalService {
  
  private tribe: Array<UserModel> = [];
  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
  };

  get ngbActiveModal(): NgbActiveModal {
    return this._ngbActiveModal
  }

  constructor(private _ngbActiveModal: NgbActiveModal, 
    private ngbModalService: NgbModal,
    private tribeService: TribeService) {}

  open<T>(content: Content<T>, data: any): Promise<any> {
    this.modalRef = this.ngbModalService.open(content, this.modalOptions);
    if (data instanceof ProjectModel) {
      this.modalRef.componentInstance.project = data;
    }
    else if (data instanceof VoyageModel) {
      // this.modalRef.componentInstance.project = this.projectService.getProject(projectId);
      this.modalRef.componentInstance.voyage = data
    }
    return this.awaitUserAction()
  }

  openProjectModal<T>(content: Content<T>, data: ProjectModel): Promise<any> {
    this.modalRef = this.ngbModalService.open(content, this.modalOptions);
    this.modalRef.componentInstance.project = data;
    return this.awaitUserAction()
  }

  openTaskModal<T>(content: Content<T>, data: any): Promise<any> {
    this.modalRef = this.ngbModalService.open(content, this.modalOptions);
    this.modalRef.componentInstance.task = data.task;
    this.modalRef.componentInstance.project = data.project;
    this.tribe = [];
    this.tribeService.getTribe(data.project.uuid)
    .then((res) => {
      res.forEach((user) => {
        this.tribe.push(user);
      })
      console.log('tribe', this.tribe)
    })
    .catch((err) => {
      console.log('Error: ' + JSON.stringify(err));
    });
    this.modalRef.componentInstance.tribeMembers = this.tribe;
    return this.awaitUserAction()
  }

  openVoyageModal<T>(content: Content<T>, data: any): Promise<any> {
    this.modalRef = this.ngbModalService.open(content, this.modalOptions);
    this.modalRef.componentInstance.voyage = data.voyage
    this.modalRef.componentInstance.project = data.project
    return this.awaitUserAction()
  }

  openVoyageReplacementWarning<T>(content: Content<T>, demoted: VoyageModel, promoted: VoyageModel) {
    this.modalRef = this.ngbModalService.open(content, this.modalOptions);
    this.modalRef.componentInstance.activeVoyage = demoted;
    this.modalRef.componentInstance.newVoyage = promoted;
    return this.awaitUserAction()
  }

  openVoyageConfirmationWarning<T>(content: Content<T>, data: any) {
    this.modalRef = this.ngbModalService.open(content, this.modalOptions);
    this.modalRef.componentInstance.voyage = data.voyage;
    return this.awaitUserAction()
  }

  openConfirmationWarning<T>(content: Content<T>): Promise<any> {
    this.modalRef = this.ngbModalService.open(content, this.modalOptions)
    return this.awaitUserAction()
  }


  awaitUserAction(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.modalRef.result
      .then((result) => {
        if (result === 'Close click' || result === 'Cross click')
          console.log('Action interupted!');
        else {
          console.log("Passing result to parent component...");
          console.log(result)
          resolve(result);
        }
      })
      .catch((err) => {
        if (err === 0) console.log("Modal dismissed!");
        reject(err);
      })
    })
  }
}
