import { VoyageModel } from './VoyageModel'

export class ProjectModel
{
  name: string;
  uuid?: string; // uuid generated server-side
  startDate?: string; // startDate generated server-side
  description?: string;
  daysUntilTaskDeletion?: number = 30 // daysUntilTaskDeletion generated server-side
  taskCount?: number; // taskCount generated server-side
  voyageEnabled: boolean;
  autoVoyageLength?: number;
  latestVoyageUuid?: string;
  voyageCount?: number // voyage count generated server-side
  voyages?: VoyageModel[] 
  deckHands?: string[]
  firstMates?: string[]
  captains?: string[]
  tags?: string[]
  questions?: any  = []

  constructor(obj: any = null)
  {
    if(obj != null)
    {
      Object.assign(this, obj);
    }
  }
}