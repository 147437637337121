import { Component, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ProjectService } from '../../shared';
import { AuthService } from '../../shared/auth/auth.service';

interface View {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.css']
})
export class NavSidebarComponent {
  public _opened: boolean = false;
  public _closeOnClickOutside: boolean = false;
  public _modeNum: number = 1;
  public _positionNum: number = 0;
  public _dock: boolean = false;
  public _closeOnClickBackdrop: boolean = false;
  public _showBackdrop: boolean = false;
  public _animate: boolean = true;
  public _trapFocus: boolean = true;
  public _autoFocus: boolean = true;
  public _keyClose: boolean = false;
  public _autoCollapseHeight: number = null;
  public _autoCollapseWidth: number = null;
  selectedView: string = 'board';
  public currentProject: string;
  public currentProjectId: string;

  public _MODES: Array<string> = ['over', 'push', 'slide'];
  public _POSITIONS: Array<string> = ['left', 'right', 'top', 'bottom'];

  views: View[] = [
    {value: 'table', viewValue: 'Table'},
    {value: 'board', viewValue: 'Board'},
    {value: 'settings', viewValue: 'Settings'}
  ];

  constructor(private ngZone: NgZone, 
    private router: Router, 
    private auth: AuthService, 
    private ref: ChangeDetectorRef, 
    private projectService: ProjectService,
    private route: ActivatedRoute) { 
    this.auth.userLoggedIn.subscribe((data) => {
      if (data !== undefined) {
        console.log('update template on log in?')
        this.ref.detectChanges();
      }
    })

    this.auth.userLoggedOut.subscribe(() => {
      console.log('update template on logout?');
      this.ref.detectChanges();
    })

    this.route.queryParams.subscribe(params => {
      this.currentProjectId = params['project'];
    });

    this.router.events.pipe(filter(e => e instanceof RouterEvent))
    .subscribe((e: RouterEvent) => {
      // user navigates back to projects, clear the selection option
      if (e.url.includes('/board')) {
        this.selectedView = 'board';
      }
      else if (e.url.includes('/table')) {
        this.selectedView = 'table';
      }
      else if (e.url.includes('/settings')) {
        this.selectedView = 'settings';
      }
      else {
        this.selectedView = '';
      }
    })

  }

  public _toggleSidebar() {
    this._opened = !this._opened;
  }

  openSettings() {
    console.log("Opening App Settings!");
    this.ngZone.run(() => this.router.navigate(['/app/settings']));
  }

  openProjects() {
    this.ngZone.run(() => {
      this.currentProject = undefined;
      this.router.navigate(['/projects']);
    });
  }

  openHome() {
    this.ngZone.run(() => this.router.navigate(['../']));
  }

  login() {
    this.ngZone.run(() => this.router.navigate(['/login']));
  }

  loggedIn(): boolean {
    return this.auth.loggedIn();
  }

  projectSelected(): boolean {
    this.currentProject = this.projectService.getProject(this.currentProjectId)?.name
    return !!(this.currentProject);
  }

  onSelection(view, event): void
  {
    if (event.isUserInput) {
      console.log(event);
      this.ngZone.run(() => this.router.navigate(['/' + view], { queryParams: {project: this.currentProjectId }}));
    }
  }

  getUsername(): string {
    return this.auth.getUserEmail();
  }

  onAuthEvent(payload) {
    console.log(payload);
  }

  public _toggleOpened(): void {
    this._opened = !this._opened;
  }

  public _toggleMode(): void {
    this._modeNum++;

    if (this._modeNum === this._MODES.length) {
      this._modeNum = 0;
    }
  }

  public _toggleAutoCollapseHeight(): void {
    this._autoCollapseHeight = this._autoCollapseHeight ? null : 500;
  }

  public _toggleAutoCollapseWidth(): void {
    this._autoCollapseWidth = this._autoCollapseWidth ? null : 500;
  }

  public _togglePosition(): void {
    this._positionNum++;

    if (this._positionNum === this._POSITIONS.length) {
      this._positionNum = 0;
    }
  }

  public _toggleDock(): void {
    this._dock = !this._dock;
  }

  public _toggleCloseOnClickOutside(): void {
    this._closeOnClickOutside = !this._closeOnClickOutside;
  }

  public _toggleCloseOnClickBackdrop(): void {
    this._closeOnClickBackdrop = !this._closeOnClickBackdrop;
  }

  public _toggleShowBackdrop(): void {
    this._showBackdrop = !this._showBackdrop;
  }

  public _toggleAnimate(): void {
    this._animate = !this._animate;
  }

  public _toggleTrapFocus(): void {
    this._trapFocus = !this._trapFocus;
  }

  public _toggleAutoFocus(): void {
    this._autoFocus = !this._autoFocus;
  }

  public _toggleKeyClose(): void {
    this._keyClose = !this._keyClose;
  }

  public _onOpenStart(): void {
    console.info('Sidebar opening');
  }

  public _onOpened(): void {
    console.info('Sidebar opened');
  }

  public _onCloseStart(): void {
    console.info('Sidebar closing');
  }

  public _onClosed(): void {
    console.info('Sidebar closed');
  }

  public _onTransitionEnd(): void {
    console.info('Transition ended');
  }

  public _onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }

}
