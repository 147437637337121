import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DisplayUserTasksComponent } from './display-user-tasks/display-user-tasks.component';
import { Error404Component } from './errors/404.component';
import { DisplayUserProjectsComponent } from './display-user-projects/display-user-projects.component';
import { DisplayUserTaskTableComponent } from './display-user-task-table/display-user-task-table.component';
import { DisplayProjectSettingsComponent } from './display-project-settings/display-project-settings.component';
import { DisplayUserVoyagesComponent } from './display-user-voyages/display-user-voyages.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { DisplayAppSettingsComponent } from './display-app-settings/display-app-settings.component';
import { PaymentPageComponent } from './payment-page/payment-page.component';
import { FrequentlyAskedQuestionsComponent  } from './frequently-asked-questions/frequently-asked-questions.component';
import { AboutUsComponent  } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component'
import { 
  AuthenticationGuardService as AuthenticationGuard,
  AuthorizationGuardService as AuthorizationGuard
} from './shared';

export const routes: Routes = [
  {path: '', component: LandingPageComponent},
  {path: 'login', component: LoginPageComponent},
  {
    path: 'projects', component: DisplayUserProjectsComponent, 
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'voyages', component: DisplayUserVoyagesComponent, 
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'board', component: DisplayUserTasksComponent, 
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'settings', component: DisplayProjectSettingsComponent, 
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {path: 'app/settings', component: DisplayAppSettingsComponent },
  {
    path: 'table', component: DisplayUserTaskTableComponent, 
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {path: 'payment', component: PaymentPageComponent},
  {path: 'FAQ', component: FrequentlyAskedQuestionsComponent},
  {path: 'about-us', component: AboutUsComponent},
  {path: 'contact-us', component: ContactUsComponent},
  {path: '404', component: Error404Component},
  {path: '**', redirectTo: '404'} // path: '**' always last entry
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
