import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VoyageModel } from 'src/app/models';

@Component({
  selector: 'confirm-replacement-modal',
  template:`
    <div class="modal-header">
      <h4 class="modal-title">Replacing "{{activeVoyage.name | truncate : 20}}" with "{{newVoyage.name | truncate : 20}}"</h4>
      <button type="button" class="close" aria-label="Cancel" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label for="confirm">Are you sure you wish to replace the current active voyage?</label> <br>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" 
        (click)="activeModal.close('Confirm')">
        Confirm
      </button>
      <button type="button" class="btn btn-danger" 
        (click)="activeModal.dismiss('Cancel')">
        Cancel
      </button>
    </div> 
  `
})
export class ConfirmReplacementModalComponent {

  // public voyage: VoyageModel;
  public activeVoyage: VoyageModel;
  public newVoyage: VoyageModel;
  constructor(public activeModal: NgbActiveModal) { }

  passBack(): void
  {
    console.log("Confirming user action...");
  }
}