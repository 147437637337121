import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ProjectModel, VoyageModel } from '../models';
import { 
  RouteService,
  ModalService,
  ProjectService,
  UpdateVoyageModalContent,
  ConfirmReplacementModalComponent,
  ConfirmModalComponent,
} from '../shared';


@Component({
  selector: 'app-display-user-voyages',
  templateUrl: './display-user-voyages.component.html',
  styleUrls: ['./display-user-voyages.component.css']
})
export class DisplayUserVoyagesComponent implements OnInit {

  public projectUuid: string;
  public project: ProjectModel;
  public voyages: VoyageModel[];
  public filterBy: string = 'backlog'
  public upcomingMax: number = 2; // overflow amount for upcoming voyages

  public activeVoyages: VoyageModel[] = []; // 1x
  public upcomingVoyages: VoyageModel[] = []; // 2x-3x
  public futureVoyages: VoyageModel[] = []; // inf
  public completedVoyages: VoyageModel[] = []; // inf
  public headerElements: string[] = ['ID', 'Name', 'Description'];

  constructor(private projectService: ProjectService, private routeService: RouteService,
    private modalService: ModalService) {
    this.routeService.queryParams.subscribe((params) => {
      this.projectUuid = params['project']
    })
  }

  ngOnInit(): void {
    this.project = this.projectService.getProject(this.projectUuid)
    this.voyages = this.projectService.getVoyages(this.projectUuid)
    this.voyages.sort((lhs, rhs) => {
      let leftNum = parseInt(lhs.name.substring(1))
      let rightNum = parseInt(rhs.name.substring(1))
      return leftNum - rightNum
    })
    this.initVoyageContainers()
  }
  private initVoyageContainers(): void {
    // get all upcoming and future voyages and store in upcoming voyages
    this.upcomingVoyages = this.getInactiveVoyages()
    
    // get active voyage
    let activeVoyageIdx = this.getActiveVoyageIdx()
    if (activeVoyageIdx > -1) {
      let splicedArray = Array.from(this.voyages).splice(activeVoyageIdx, 1);
      this.activeVoyages.push(splicedArray.pop());
    }
    
    // get a copy of future voyages from overflowed upcoming voyages
    if (this.upcomingVoyages.length > this.upcomingMax) {
      let trimmedUpcoming = Array.from(this.upcomingVoyages.slice(0, this.upcomingMax))
      this.futureVoyages = Array.from(this.upcomingVoyages.slice(this.upcomingMax))
      this.upcomingVoyages = Array.from(trimmedUpcoming)
    }
    
    // get a copy of inactive voyages
    this.completedVoyages = this.getArchivedVoyages()

    this.printContainers()
  }
  
  private getInactiveVoyages(): VoyageModel[] {
    let inactiveVoyages: VoyageModel[] = [];
    this.voyages.forEach((element) => {
      if (element.status === 'upcoming' || element.status === 'future') {
        inactiveVoyages.push(new VoyageModel(element));
      }
    })
    return inactiveVoyages
  }

  private getArchivedVoyages(): VoyageModel[] {
    let inactiveVoyages: VoyageModel[] = [];
    this.voyages.forEach((element) => {
      if (element.status === 'archived' || element.status === 'completed') {
        inactiveVoyages.push(new VoyageModel(element));
      }
    })
    return inactiveVoyages
  }

  private getActiveVoyageIdx(): number {
    let activeVoyageIdx = -1
    if (this.project.latestVoyageUuid) {
      activeVoyageIdx = this.voyages.findIndex((element) => {
        return element.uuid === this.project.latestVoyageUuid
      })
    }
    return activeVoyageIdx
  }

  deactivateVoyageDrop(event: CdkDragDrop<string[]>) {
    let voyage = new VoyageModel(event.item.data)
    let prevStatus = this.getStatus(event.previousContainer)
    let newStatus = this.getStatus(event.container)
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else if (prevStatus === 'active') {
      // demote active voyage
      // let demoted = new VoyageModel(event.container.data)
      // this.modalService.open(ConfirmModalComponent, voyage, this.project.projectUuid)
      let data: any = {
        voyage: voyage,
        project: this.project
      }
      this.modalService.openVoyageConfirmationWarning(ConfirmModalComponent, data)
      .then(() => {
        return this.projectService.updateVoyage(this.project, voyage, 'deactivate')
      })
      .then((demotedVoyage) => {
        // peform container transfer
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
          
        // update transfered item
        if (newStatus === 'upcoming') {
          this.upcomingVoyages[event.currentIndex] = demotedVoyage
        }
        else {
          this.futureVoyages[event.currentIndex] = demotedVoyage
        }

        })
    }
    else {
      // trivial transfer between upcoming and future
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  activateVoyageDrop(event: CdkDragDrop<string[]>) {
    let previousContainer = event.previousContainer
    let activeContainer = event.container
    if (previousContainer === activeContainer) return

    let voyage = new VoyageModel(event.item.data)
    let newStatus = this.getStatus(previousContainer)
    
    if (this.hasActiveVoyage()) {
      console.log('replacement')
      console.log(newStatus)
      let demoted = new VoyageModel(activeContainer.data[0])
      this.modalService.openVoyageReplacementWarning(ConfirmReplacementModalComponent, demoted, voyage)
      // (1) deactive current voyage
      .then(() => {
        // - get a copy of the demoted voyage within active voyages 
        // - move the demoted voyage to first postion of the container from which the
        //   the newly activated voyage originated
        let demotedVoyage = new VoyageModel(activeContainer.data[0])
        transferArrayItem(activeContainer.data, previousContainer.data, 0, 0)
        return this.projectService.updateVoyage(this.project, demotedVoyage, 'deactivate')
      })
      // (2) set demoted voyage to http response voyage
      .then((demotedVoyage) => {
        console.log('demotedVoyage', demotedVoyage)
        if (newStatus === 'upcoming') {
          this.upcomingVoyages[0] = demotedVoyage
        }
        else {
          this.futureVoyages[0] = demotedVoyage
        }
        // (3) activate previously inactive voyage
        return this.projectService.updateVoyage(this.project, voyage, 'activate')
      })
      .then((activeVoyage) => {
        console.log('activatedVoyage', activeVoyage)
        let prevIdx = -1
        console.log(this.activeVoyages.length)
        if (newStatus === 'upcoming') {
          prevIdx = this.upcomingVoyages.findIndex((value) => {
            return value.uuid === activeVoyage.uuid
          })
          this.upcomingVoyages[prevIdx] = activeVoyage
        }
        else {
          prevIdx = this.futureVoyages.findIndex((value) => {
            return value.uuid === activeVoyage.uuid
          })
          this.futureVoyages[prevIdx] = activeVoyage
        }
        transferArrayItem(previousContainer.data,
          activeContainer.data,
          prevIdx,
          0);
      })
      .catch((err) => {

      })
    }
    else {
      console.log('vacancy')
      // there's a vacancy in active container
      this.projectService.updateVoyage(this.project, voyage, 'activate')
      .then((incomingVoyage) => {
        // receive voyage containing a new start and end time

        // peform container transfer
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);

        // repair transfered item
        console.log('incomingVoyage', incomingVoyage)
        if (this.activeVoyages.length) this.activeVoyages[0] = incomingVoyage
        else this.activeVoyages.push(incomingVoyage)
        
        this.printContainers()
      })
    }
  }

  archiveVoyageDrop(event: CdkDragDrop<string[]>) {
    // TODO: ask for confirmation, then archive
  }

  getStatus(container) {
    let index = parseInt(container.element.nativeElement.attributes["index"].value);
    if (index === 0) return 'active'
    if (index === 1) return 'upcoming'
    if (index === 2) return 'future'
    if (index === 3) return 'completed'  
  }

  onCreate($event: VoyageModel): void {
    let newVoyage = new VoyageModel($event)
    if (!this.hasActiveVoyage()) {
      // set newly created voyage to active if no 
      // active voyage currently exists
      newVoyage.status = 'active'
    }
    else if (this.upcomingVoyages.length < this.upcomingMax) {
      newVoyage.status = 'upcoming'
    }
    else {
      newVoyage.status = 'future'
    }
    this.projectService.createVoyage(this.project, newVoyage)
    .then((incomingVoyage) => {
      // add incoming to voyages
      this.voyages.push(incomingVoyage)      
      // assign voyage to correct container
      if (incomingVoyage.status === 'active') {
        this.activeVoyages.push(incomingVoyage)
        this.project.latestVoyageUuid = incomingVoyage.uuid
      }
      else if (incomingVoyage.status === 'upcoming') {
        this.upcomingVoyages.push(incomingVoyage);
      }
      else {
        this.futureVoyages.push(incomingVoyage)
      }
      // update project voyageCount to reflect DB changes
      this.project.voyageCount += 1
    })
  }

  onUpdate($event): void {
    let incomingVoyage = new VoyageModel($event);
    // update tables
    this.projectService.updateVoyage(this.project, incomingVoyage, 'none')
    .then((res) => {
      console.log(res)
      if (incomingVoyage.status === 'active') {
        this.activeVoyages[0] = incomingVoyage;
      }
      else if (incomingVoyage.status === 'upcoming')
      {
        let index = this.upcomingVoyages.findIndex((element) => {
          return element.uuid === incomingVoyage.uuid
        })
        console.log('index: ', index)
        this.upcomingVoyages[index] = incomingVoyage
      }
      else if (incomingVoyage.status === 'future') {
        let index = this.futureVoyages.findIndex((element) => {
          return element.uuid === incomingVoyage.uuid
        })
        this.futureVoyages[index] = incomingVoyage
      }
      this.printContainers()
    })
  }

  onArchive($event, prevVoyage: VoyageModel): void {
    console.log('archive event confirmed')
    let incomingVoyage = new VoyageModel($event);
    // update tables
    this.projectService.updateVoyage(this.project, incomingVoyage, 'none')
    .then((res) => {
      console.log(res)
      if (prevVoyage.status === 'active') {
        this.project.latestVoyageUuid = ''
      }
      this.completedVoyages.push(res)
      this.activeVoyages.pop()
      this.printContainers()
    })
  }

  hasActiveVoyage(): boolean {
    return this.activeVoyages.length > 0
  }
  
  openUpdateModal(voyage: VoyageModel) {
    let data: any = {
      voyage: voyage,
      project: this.project
    }
    this.modalService.openVoyageModal(UpdateVoyageModalContent, data)
    .then((res) => {
      return this.projectService.updateVoyage(this.project, res, 'none')
    })
    .then((voyage) => {
      let idx = this.completedVoyages.findIndex((element) => {
        return element.uuid === voyage.uuid
      })
      this.completedVoyages[idx] = voyage
    })
  }

  printContainers(): void {
    console.log('total', this.voyages)
    console.log('active', this.activeVoyages)
    console.log('upcoming', this.upcomingVoyages)
    console.log('future', this.futureVoyages)
    console.log('completed', this.completedVoyages)
  }
}
