<div class="dashboard-container">
  <div class="dashboard-btn-container">
    <create-voyage-button [project]="project" (messageEvent)="onCreate($event)">

    </create-voyage-button>
  </div>
  <div class="dashboard-column-container">
    <div class="dashboard-mid" cdkDropListGroup>
      <div [ngClass]="{'activated-container': activeVoyages.length}"
      class="active-container" cdkDropList [cdkDropListData]="activeVoyages"
      #active="cdkDropList" (cdkDropListDropped)="activateVoyageDrop($event)"
      [attr.index]="0">
        <div class="container-title">
          Active
        </div>
        <div *ngFor="let item of activeVoyages">
          <voyage-thumbnail class="thumbnail" [voyage]="item" [project]="project" 
            [cdkDragData]="item" cdkDrag
            (messageEvent)="onUpdate($event)"
            (archiveEvent)="onArchive($event, item)">
          </voyage-thumbnail>
        </div>
      </div>
      <div class="upcoming-container" cdkDropList [cdkDropListData]="upcomingVoyages"
          #upcoming="cdkDropList" (cdkDropListDropped)="deactivateVoyageDrop($event)"
          [attr.index]="1">
          <div class="container-title">
            Upcoming
          </div>
          <div *ngFor="let item of upcomingVoyages">
            <voyage-thumbnail class="thumbnail" [voyage]="item" [project]="project" 
              [cdkDragData]="item" cdkDrag
              (messageEvent)="onUpdate($event)"
              (archiveEvent)="onArchive($event, item)">
            </voyage-thumbnail>
          </div>
      </div>
      <div class="future-container" cdkDropList [cdkDropListData]="futureVoyages"
        #future="cdkDropList" (cdkDropListDropped)="deactivateVoyageDrop($event)"
        [attr.index]="2">
        <div class="container-title">
          Future
        </div>
        <div *ngFor="let item of futureVoyages">
          <voyage-thumbnail class="thumbnail" [voyage]="item" [project]="project"
            [cdkDragData]="item" cdkDrag
            (messageEvent)="onUpdate($event)"
            (archiveEvent)="onArchive($event, item)">
          </voyage-thumbnail>
        </div>
      </div>
    </div>
    <div class="dashboard-end">
      <div class="completed-container" [attr.index]="3">
        <div class="container-title">
          Archived
        </div>
        <table mdbTable #tableEl="mdbTable" hover="true" class="table table-striped table-bordered table-md">
          <thead>
            <tr>
              <th *ngFor="let head of headerElements; let i = index" 
                aria-controls="tableSortExample" scope="col" 
                [mdbTableSort]="completedVoyages" [sortBy]="headerElements[i]">
                {{head}}
                <mdb-icon fas icon="sort"></mdb-icon>
              </th>
              
            </tr>
          </thead>
          <tbody>
            <tr mdbTableCol *ngFor="let voyage of completedVoyages; let i = index" (click)="openUpdateModal(voyage)">
              <th scope="row">{{voyage.viewId}}</th>
              <td>{{voyage.name}}</td>
              <td>{{voyage.description | truncate : 20}}</td>
            </tr>
          </tbody>
          <tfoot class="grey lighten-5 w-100">
            <tr>
              <td colspan="8">
                <mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="completedVoyages"></mdb-table-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
