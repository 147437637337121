<div class="card-body">
  <div class="card-text-container">
    <div class="card-title" style="font-weight: bold;" >{{voyage.name}}</div>
    <p class="card-meta">{{voyage.description | truncate : 50}}</p>
  </div>
  <hr>
  <div class="card-button-container">
    <voyage-details-button [voyage]="voyage" [project]="project"
      (messageEvent)="pass($event)"
      (archiveEvent)="passArchive($event)">
    </voyage-details-button>
  </div>
</div>
