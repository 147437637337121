import { ProjectModel, VoyageModel } from "src/app/models"
import { Parser } from './parser-utility'
import VoyageParser from './voyage-parser'

export default class ProjectParser extends Parser {

  static parseProject(payload: any): ProjectModel {
    if (Array.isArray(payload)) {
      throw new Error('Faled to parse: payload must not be an array')
    }
    try {
      let project: ProjectModel = new ProjectModel({
        name: payload["projectName"],
        uuid: payload["projectUuid"],
        description: payload["projectDescription"],
        startDate: payload["projectStartDate"],
        taskCount: this.getTaskCount(payload),
        daysUntilTaskDeletion: this.getDaysUntilTaskDeletion(payload),
        voyageEnabled: this.areVoyagesEnabled(payload),
        voyages: this.getVoyages(payload),
        voyageCount: this.getVoyageCount(payload),
        latestVoyageUuid: this.getLatestVoyage(payload),
        autoVoyageLength: this.getAutoVoyageLength(payload),
        deckHands: this.getDeckHands(payload),
        firstMates: this.getFirstMates(payload),
        captains: this.getCaptains(payload),
        tags: this.getTags(payload)
      })
      return project
    }
    catch(e) {
      throw e
    }
  }

  //#region parseProject() HELPERS
  static getTaskCount(payload: any): number {
    try {
      const key: string = 'taskCount'
      return this.getNumber(payload, key)
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getVoyageCount(payload: any): number {
    try{
      const key: string = 'voyageCount'
      return this.getNumber(payload, key)
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getDaysUntilTaskDeletion(payload: any): number {
    try{
      const key: string = 'daysUntilTaskDeletion'
      const res: number = this.getNumber(payload, key)
      // setting daysUntilTaskDeletion to zero could be dangerous
      return res !== 0 ? res : -1
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getTags(payload: any): string[] {
    try {
      const key: string = 'tags'
      return key in payload ? this.getTagList(payload[key]) : []
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getTagList(data: any): string[] {
    try {
      switch (typeof(data)) {
        case 'string':
          console.log('string')
          return this.getEvalReplacement(data)
        case 'object':
          console.log('object')
          return data
        default:
          let errorMsg = 'Payload data type ' + typeof(data) + ' unrecognized!'
          throw new Error(errorMsg)
      }
    }
    catch(e) {
      console.error(e)
      throw e
    }
  }

  static removeNull(array: string[]): string[] {
    return array.filter(x => x !== null)
  };

  static areVoyagesEnabled(payload: any): boolean {
    try {
      const key: string = 'voyageEnabled'
      return this.getBooleanFromString(payload[key])
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getBooleanFromString(element): boolean {
    if (element === "True" || element === "true")
      return true
    return false
  }

  static getVoyages(payload: any): VoyageModel[] {
    try {
      const key: string = 'voyages'
      return key in payload ? this.getVoyageList(payload, key) : []
    }
    catch(e) {
      console.error(e)
      throw e
    }
  }

  static getLatestVoyage(payload: any): string {
    try {
      const keys: string[] = ['latestVoyageUuid', 'latestVoyage']
      let res = ''
      while(keys.length > 0 && res === '') {
        res = this.getString(payload, keys.pop())
      }
      return res !== '' ? res : ''
      
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getAutoVoyageLength(payload: any): number {
    try {
      const key: string = 'voyageLength'
      const res: number = this.getNumber(payload, key)
      return res !== 0 ? res : 2
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getDeckHands(payload: any): string[] {
    try {
      const key: string = 'deckHands'
      return key in payload ? this.getList(payload, key) : []
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getFirstMates(payload): string[] {
    try{
      const key: string = 'firstMates'
      return key in payload ? this.getList(payload, key) : []
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getCaptains(payload):  string[] {
    try {
      const key: string = 'captains'
      return key in payload ? this.getList(payload, key) : []
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getVoyageList(data: any, k: string): VoyageModel[] {
    if (Array.isArray(data)) {
      throw new Error('Unrecognized data type: array')
    }
    let voyagesObj: Object = data[k]
    let voyages: VoyageModel[] = this.parseVoyages(voyagesObj)
    return voyages
  }

  static parseVoyages(voyagesObj: any): VoyageModel[] {
    // voyagesObj = {uuid1: {values1}, uuid2: {values2}, ...}
    return VoyageParser.serializeVoyageData(voyagesObj)
  }
  //#endregion
}