import { 
  Component, 
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  EventEmitter,
  Output 
} from '@angular/core';
import { Observable } from 'rxjs';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatRipple } from '@angular/material/core';
import { map } from 'rxjs/operators';
import { ProjectModel } from 'src/app/models';

@Component({
  selector: 'tag-builder',
  templateUrl: './tag-builder.component.html',
  styleUrls: ['./tag-builder.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: TagBuilderComponent
    }
  ]
})
export class TagBuilderComponent implements OnInit, ControlValueAccessor {

  @Input() project: ProjectModel;
  @Output() onTagControlInit = new EventEmitter()
  public value: any;
  public tags: string[];
  public allTags: string[];
  public tagStream: Observable<string>;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  tagCtrl = new FormControl('', [Validators.maxLength(24)]);
  filteredTags: Observable<string[]>;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('chipList') chipList: ElementRef<HTMLElement>;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  @ViewChildren(MatRipple) ripples: QueryList<MatRipple>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  
  constructor() {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      map((tag: string) => {
        console.log('tagCtrl value changed!')
        console.log(tag)
        return tag ? this._filter(tag) : this.allTags.slice()
      })
    );
  }

  ngOnInit(): void {
    this.tags = []
    this.allTags = Array.from<string>(this.project.tags)
    // if (this.project.tags && this.project.tags.length > 0) {
    //   this.tags = Array.from<string>(this.project.tags)
    //   this.allTags = Array.from<string>(this.project.tags)
    // }
    if (this.project.tags.length === 0 && this.project.voyageEnabled) {
      console.log('loading voyage options into autocomplete menu')
      this.loadVoyageOptions()
    }
    this.onTagControlInit.emit(this.tagCtrl)
  }

  ngAfterViewInit() {
  }

  add(event: MatChipInputEvent): void {
    if (this.disabled || this.hasError()) {
      return
    }

    this.markAsTouched();
    const input = event.input;
    const value = event.value;
    if (value === "") return

    const trimmedValue = value.trim()

    // does not already exist check
    let index = this.tags.indexOf(trimmedValue)
    console.log('index', index)
    if (index === -1) {
      // Add the tag
      this.tags.push(trimmedValue);
      
      // inform the parent form
      this.onChange(this.tags)
    }
    else {
      console.log('tag already exists!')
      console.log(this.chipList)
      console.log(this.tagInput)
      this.launchRippleAtIndex(index)
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue('');

  }

  remove(tag: string): void {
    if (this.disabled) {
      return
    }
    this.markAsTouched();
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);

      // inform the parent form
      this.onChange(this.tags)
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.markAsTouched();

    const value = event.option.viewValue
    if (value === "") return

    // does not already exist check
    let index = this.tags.indexOf(value)
    console.log('index', index)
    if (index === -1) {
      // Add the tag
      this.tags.push(value);
      
      // inform the parent form
      this.onChange(this.tags)
    }
    else {
      console.log('tag already exists!')
      console.log(this.chipList)
      console.log(this.tagInput)
      this.launchRippleAtIndex(index)
    }
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue('');
  }

  loadVoyageOptions() {
    this.project.voyages.forEach((voyage) => {
      if (voyage.status !== 'archived') {
        let prefix = 'V' + voyage.viewId.toString()
        this.allTags.push(prefix)
      }
    })
  }

  launchRippleAtIndex(idx: number) {
    let element: MatRipple = this.ripples.find((item, index) => {
      return index === idx
    })
    const rippleRef = element.launch({
      persistent: true,
      centered: true,
      color: 'blue'
    });

    // Fade out the ripple later.
    rippleRef.fadeOut();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

  displayAutoComplete() {
    this.trigger.openPanel()
  }

  removeNull(array) {
    return array.filter(x => x !== null)
  };

  hasError() {
    return this.tagCtrl.invalid && this.tagCtrl.dirty
  }

  getErrorMessage() {
    console.log(this.tagCtrl.errors)
    if (this.tagCtrl.errors.maxlength) {
      let diff = this.tagCtrl.errors.maxlength.actualLength - this.tagCtrl.errors.maxlength.requiredLength
      return 'Max length exceeded by ' + diff.toString() + ' characters!';
    }
  }

  //#region ControlValueAccessor Interface
  onChange = (quantity) => { console.log('changed!') };

  onTouched = () => { console.log('touched!') };

  touched = false;

  disabled = false;

  writeValue(obj: any): void {
    console.log('writeValue', `${obj}`)
    console.log('this.tags', this.tags)
    if (Array.isArray(obj)) {
      console.log('isArray true!')
      let initArray = Array.from(obj)
      initArray.forEach((element) => {
        this.tags.push(element)
      })
    }
    else if (this.tags && obj !== null && obj !== "") {
      console.log('pushing')
      this.tags.push(`${obj}`)
      this.tags = this.tags.filter((value) => {
        return value !== ""
      })
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    console.log('before touched: ', this.touched)
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      console.log('after touched: ', this.touched)
    }
  }

  markAsUntouched() {
    console.log('before untouched: ', this.touched)
    if (this.touched) {
      this.touched = false;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
  //#endregion

}
