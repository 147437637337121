import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProjectModel, VoyageModel } from 'src/app/models';
import { 
  ModalService,
  ProjectService,
  ConfirmReplacementModalComponent,
  UpdateVoyageModalContent 
} from 'src/app/shared';


@Component({
  selector: 'app-voyage-well',
  templateUrl: './voyage-well.component.html',
  styleUrls: ['./voyage-well.component.css']
})
export class VoyageWellComponent implements OnInit {

  @Input() project: ProjectModel
  @Output() onVoyageViewOpen = new EventEmitter()

  public voyages: VoyageModel[]
  public activeVoyageUuid: string;

  public activeVoyages: VoyageModel[] = []; // target array of one 
  public upcomingVoyages: VoyageModel[] = [];
  public wellLimit: number = 3;

  constructor(private projectService: ProjectService, private modalService: ModalService) { }
  
  ngOnInit() {

    this.voyages = this.getVoyages(this.project.uuid);
    
    // initialize active voyage, if it exists
    this.activeVoyageUuid = this.project.latestVoyageUuid;
    let activeVoyageIdx = this.voyages.findIndex((element) => {
      return element.uuid === this.activeVoyageUuid
    })
    if (activeVoyageIdx >= 0) { 
      console.log('active voyage found: ' + activeVoyageIdx)
      console.log(this.voyages)
      this.activeVoyages.push(this.voyages[activeVoyageIdx])
    }
    // initialize upcoming voyages, if any
    this.upcomingVoyages = this.getUpcomingVoyages()
  }

  getVoyages(projectUuid: string): VoyageModel[] {
    return this.projectService.getVoyages(projectUuid)
  }

  getUpcomingVoyages() {
    let upcoming: VoyageModel[] = [];
    this.voyages.forEach((x) => {
      if (x.status === 'upcoming' || x.status === 'future') {
        console.log(x)
        upcoming.push(new VoyageModel(x))
      }
    })
    return upcoming.slice(0, this.wellLimit)
  }

  dropIntoInactive(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      let activeVoyage = new VoyageModel(event.item.data);
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.projectService.updateVoyage(this.project, activeVoyage, 'deactivate')
      .then((demotedVoyage) => {
        console.log('voyage: ' + demotedVoyage)
        let voyageIdx = this.getIndex(demotedVoyage.uuid)
        this.voyages[voyageIdx] = demotedVoyage
        let upcomingVoyageIdx = this.upcomingVoyages.findIndex((voyage) => {
          return voyage.uuid === demotedVoyage.uuid
        })
        this.upcomingVoyages[upcomingVoyageIdx] = demotedVoyage; 
        this.project.latestVoyageUuid = ""
      })
    }
  }

  dropIntoActive(event: CdkDragDrop<string[]>) {
    let voyage = new VoyageModel(event.item.data)
    let activeContainer = event.container
    let previousContainer = event.previousContainer
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      return
    }
    if (!this.hasActiveVoyage()) {
      let inactiveVoyage = new VoyageModel(event.item.data);
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.project.latestVoyageUuid = inactiveVoyage.uuid
      this.projectService.updateVoyage(this.project, inactiveVoyage, 'activate')
      .then((activatedVoyage) => {
        let voyageIdx = this.getIndex(activatedVoyage.uuid)
        this.voyages[voyageIdx] = activatedVoyage
        this.activeVoyages[0] = activatedVoyage;
      });
    }
    else {
      let demoted = new VoyageModel(activeContainer.data[0])
      this.modalService.openVoyageReplacementWarning(ConfirmReplacementModalComponent, demoted, voyage)
      // (1) deactive current voyage
      .then(() => {
        // - get a copy of the demoted voyage within active voyages 
        // - move the demoted voyage to first postion of the container from which the
        //   the newly activated voyage originated
        transferArrayItem(activeContainer.data, previousContainer.data, 0, 0)
        return this.projectService.updateVoyage(this.project, demoted, 'deactivate')
      })
      // (2) set demoted voyage to http response voyage
      .then((demotedVoyage) => {
        console.log('demotedVoyage', demotedVoyage)
        this.upcomingVoyages[0] = demotedVoyage
        return this.projectService.updateVoyage(this.project, voyage, 'activate')
      })
      // (3) set promoted voyage to http replacement voyage
      .then((activeVoyage) => {
        console.log('activatedVoyage', activeVoyage)
        let prevIdx = -1
        console.log(this.activeVoyages.length)
        prevIdx = this.upcomingVoyages.findIndex((value) => {
          return value.uuid === activeVoyage.uuid
        })
        this.upcomingVoyages[prevIdx] = activeVoyage
        transferArrayItem(previousContainer.data,
          activeContainer.data,
          prevIdx,
          0);
      })
      .catch((err) => {
        
      })
    }
  }
  
  onUpdate($event): void {
    let incomingVoyage = new VoyageModel($event);
    // update tables
    this.projectService.updateVoyage(this.project, incomingVoyage, 'none')
    .then((res) => {
      console.log(res)
      if (incomingVoyage.status === 'active') {
        this.activeVoyages[0] = incomingVoyage;
      }
      else if (incomingVoyage.status === 'upcoming')
      {
        let index = this.upcomingVoyages.findIndex((element) => {
          return element.uuid === incomingVoyage.uuid
        })
        console.log('index: ', index)
        this.upcomingVoyages[index] = incomingVoyage
      }
      this.printContainers()
    })
  }

  onArchive($event, prevVoyage: VoyageModel): void {
    console.log('archive event confirmed')
    let incomingVoyage = new VoyageModel($event);
    // update tables
    this.projectService.updateVoyage(this.project, incomingVoyage, 'none')
    .then((res) => {
      console.log(res)
      if (prevVoyage.status === 'active') {
        this.project.latestVoyageUuid = ''
      }
      this.activeVoyages.pop()
      this.printContainers()
    })
  }

  openVoyageView(project: ProjectModel): void {
    this.onVoyageViewOpen.emit(project.uuid)
  }

  getIndex(uuid: string) {
    return this.voyages.findIndex((element) => {
      return element.uuid === uuid
    })
  }

  printContainers() {
    console.log('total', this.voyages)
    console.log('active', this.activeVoyages)
    console.log('upcoming', this.upcomingVoyages)
  }

  hasActiveVoyage(): boolean {
    return this.activeVoyages.length > 0
  }
}
