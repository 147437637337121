import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { 
  AuthService,
  RouteService,
  ProjectService,
  UserInfoService 
} from '../../shared';
import { ProjectModel } from 'src/app/models';
import {  } from 'src/app/shared';

interface View {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public currentProject: ProjectModel;
  public currentProjectId: string;
  public selectedView: string = 'board';

  views: View[] = [];

  constructor(private auth: AuthService, 
    private ref: ChangeDetectorRef,
    private projectService: ProjectService,
    private userService: UserInfoService,
    private routeService: RouteService) {
      
  }
  
  ngOnInit() {
    // register observers
    this.auth.userLoggedIn.subscribe((data) => {
      this.currentProject = undefined
      this.currentProjectId = undefined
      if (data !== undefined) {
        this.ref.detectChanges();
      }
    })

    this.auth.userLoggedOut.subscribe(() => {
      console.log('update template on logout?');
      this.ref.detectChanges();
    })
    
    this.routeService.queryParams.subscribe(params => {
      console.log(params)
      let size = Object.keys(params).length;
      if (size) {
        console.log('initializing project state...')
        this.currentProjectId = params['project'];
        this.initProjectState(this.currentProjectId)
      }
    });
    
    // listen to routerEvents in routeService and register an observer
    this.routeService.events.pipe(filter(e => e instanceof RouterEvent))
    .subscribe((e: RouterEvent) => {
      // user navigates back to projects, clear the selection option
      if (e.url.includes('/board')) {
        this.selectedView = 'board';
      }
      else if (e.url.includes('/table')) {
        this.selectedView = 'table';
      }
      else if (e.url.includes('/settings')) {
        this.selectedView = 'settings';
      }
      else if (e.url.includes('/voyages')) {
        this.selectedView = 'voyages'
      }
      else {
        this.selectedView = '';
      }
    })

    this.userService.onRetrieval.subscribe((value) => {
      console.log('value', value)
      // this.ref.detectChanges(); // to rerun authenticatedAndSubscribed()
      if (this.userService.active()) {
        this.routeService.openInsideAngularZone(['/projects'])
      }
      else if (this.userService.suspended()) {
        this.routeService.openInsideAngularZone(['/app/settings'])
      }
      else {
        this.routeService.openInsideAngularZone(['/payment'])
      }
    })
  }

  initProjectState(projectUuid: string) {
    this.currentProject = this.projectService.getProject(projectUuid)
    this.views = [
      {value: 'table', viewValue: 'Table'},
      {value: 'board', viewValue: 'Board'}
    ]
    if (this.currentProject.voyageEnabled) {
      this.views.push({value: 'voyages', viewValue: 'Voyages'})
    }
    this.views.push({value: 'settings', viewValue: 'Settings'})
  }

  openHome() {
    this.routeService.openInsideAngularZone(['../'])
  }

  openProjects() {
    this.currentProject = undefined
    this.routeService.openInsideAngularZone(['/projects'])
  }

  openLogin() {
    this.routeService.openInsideAngularZone(['/login'])
  }

  openAppSettings() {
    this.routeService.openInsideAngularZone(['/app/settings'])
  }
  
  openVoyages() {
    this.routeService.openInsideAngularZone(['/voyages'])
  }

  openFaqs() {
    this.routeService.openInsideAngularZone(['/FAQ'])
  }

  openAboutUs() {
    this.routeService.openInsideAngularZone(['/about-us'])
  }

  openContactUs() {
    this.routeService.openInsideAngularZone(['/contact-us'])
  }

  authenticated(): boolean {
    return this.auth.isAuthenticated();
  }

  subscribed(): boolean {
    return this.userService.active();
  }

  suspended(): boolean {
    return  this.userService.suspended();
  }

  authenticatedAndSubscribed(): boolean {
    // let subscribed = this.subscribed();
    // let loggedIn = this.loggedIn();
    // console.log('logged in ', loggedIn.toString())
    // console.log('subscribed ', subscribed.toString())
    return (this.authenticated() && this.subscribed())
  }

  projectSelected(): boolean {
    // this.currentProject = this.projectService.getProject(this.currentProjectId)
    return !!(this.currentProject);
  }

  onSelection(view, event): void
  {
    if (event.isUserInput) {
      this.routeService.openInsideAngularZone(['/' + view], { queryParams: {project: this.currentProjectId }})
    }
  }

  voyageEnabled(): boolean {
    this.currentProject = this.projectService.getProject(this.currentProjectId)
    return this.currentProject.voyageEnabled
  }

  getUsername(): string {
    return this.auth.getUserEmail();
  }

  logOut() {
    this.openLogin()
    return this.auth.signOut();
  }
}
