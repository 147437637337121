
<form [formGroup]="userForm" (ngSubmit)="onSubmit()">

	<div class="form-group" [ngClass]="{ 'has-error': invalidFirstName() }"> <!-- First Name -->
		<label for="firstname" class="control-label">First Name</label>
		<input type="text" formControlName="firstname" class="form-control" id="firstname" name="firstname" placeholder="Martin">
		<p *ngIf="invalidFirstName()" id="firstname_error" class="help-block">Invalid first name.</p>
	</div>

	<div class="form-group" [ngClass]="{ 'has-error': invalidLastName() }"> <!-- Last Name -->
		<label for="lastname" class="control-label">Last Name</label>
		<input type="text" formControlName="lastname" class="form-control" id="lastname" name="lastname" placeholder="Toha">
		<p *ngIf="invalidLastName()" id="lastname_error" class="help-block">Invalid last name.</p>
	</div>

	<div class="form-group" [ngClass]="{ 'has-error': invalidEmail() }"> <!-- Email -->
		<label for="email" class="control-label">E-mail</label>
		<input type="text" formControlName="email" class="form-control" id="email" name="email" placeholder="your@email.com">
		<p *ngIf="invalidEmail()" id="email_error" class="help-block">Invalid email address.</p>
	</div>

	<div class="form-group" [ngClass]="{ 'has-error': invalidZipcode() }"> <!-- Zip Code-->
		<label for="zipcode" class="control-label">Zip Code</label>
		<input type="text" formControlName="zipcode" class="form-control" id="zipcode" name="zipcode" placeholder="#####">
		<p *ngIf="invalidZipcode()" id="zipcode_error" class="help-block">Invalid zip code.</p>
	</div>

	<div class="form-group" [ngClass]="{ 'has-error': invalidPassword() }"> <!-- Password -->
		<label for="password" class="control-label">Password</label>
		<input type="password" formControlName="password" class="form-control" id="password" name="password" placeholder="*****">
		<p *ngIf="invalidPassword()" id="password_error" class="help-block">Invalid password.</p>
	</div>

	<mat-form-field appearance="fill">
		<mat-label>Project(s)</mat-label>
		<mat-select multiple required>
		  <mat-option *ngFor="let project of projects" (onSelectionChange)="onSelection(project.uuid)" [value]="project.uuid">
			{{project.name}}
		  </mat-option>
		</mat-select>
	  </mat-form-field>
	
	<div class="form-group has-error"> <!-- Register Button -->
		<button type="submit" class="btn btn-primary" >Register</button>
	</div>

</form>	