<form [formGroup]="projectForm">
  <div class="modal-header">
    <div class="form-group" [ngClass]="{'error': title.invalid && title.dirty}">
      <label for="formControlTitle">Project Name</label>
      <div *ngIf="title.invalid && (title.dirty || title.touched)">
        <em *ngIf="title.errors.required">
          Title is required.
        </em>
        <em *ngIf="title.errors.maxlength">
          Length exceeded by {{title.errors.maxlength.actualLength - title.errors.maxlength.requiredLength}} character(s).
        </em>
      </div>
      <input formControlName="title" name="name" type="text" class="form-control"
        placeholder="Monsters n Sharks">
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group" [ngClass]="{'error': description.invalid && description.dirty}">
      <label for="formControlDesc">Project Description</label>
      <div *ngIf="description.invalid && (description.dirty || description.touched)">
        <em *ngIf="description.errors.maxlength">
          Length exceeded by {{description.errors.maxlength.actualLength - description.errors.maxlength.requiredLength}} character(s).
        </em>
      </div>
      <textarea placeholder="(1) Set the stage.\n(2) Start a tribe.\n(3) Fight some sharks."
        formControlName="description" class="form-control rounded-0"
        name="description" rows="3">
      </textarea>
    </div>
  </div>
  <div class="modal-footer" style="align-items: flex-start;">
    <button type="button" class="btn btn-primary"  
      (click)="onSubmit(projectForm.value)"
      [ngClass]="isValid() ? 'enabled' : 'disabled'">
      Create Project
    </button>
    <button type="button" class="btn btn-danger" data-dismiss="modal"
      (click)="closureCheck(projectForm.value)">
      Cancel
    </button>
  </div>
</form>