<ng-sidebar-container>

  <ng-sidebar #sidebar
    [(opened)]="_opened" 
    [mode]="_MODES[_modeNum]"
    [keyClose]="_keyClose"
    [position]="_POSITIONS[_positionNum]"
    [dock]="_dock"
    [dockedSize]="'50px'"
    [autoCollapseHeight]="_autoCollapseHeight"
    [autoCollapseWidth]="_autoCollapseWidth"
    [closeOnClickOutside]="_closeOnClickOutside"
    [closeOnClickBackdrop]="_closeOnClickBackdrop"
    [showBackdrop]="_showBackdrop"
    [animate]="_animate"
    [trapFocus]="_trapFocus"
    [autoFocus]="_autoFocus"
    [ariaLabel]="'Sharktracker Sidebar'"
    (onOpenStart)="_onOpenStart()"
    (onOpened)="_onOpened()"
    (onCloseStart)="_onCloseStart()"
    (onClosed)="_onClosed()"
    (onTransitionEnd)="_onTransitionEnd()">
    <div *ngIf="!loggedIn()" class="sidebar-item">
      <p>
        <a id="login" class="navbar-brand" style="color: white;" (click)="login()">
          Login
        </a>
      </p>
    </div>
    <div *ngIf="loggedIn()" class="sidebar-item">
      <p>
        <a id="signout" class="navbar-brand" style="color: white;" (click)="login()">
          Sign out
        </a>
      </p>
    </div>
    <div *ngIf="loggedIn()" class="sidebar-item">
      <p>
        <a (click)="openSettings()" id="register" class="navbar-brand" style="color: white;">
          <span aria-label="Open Settings" class="glyphicon glyphicon-cog"></span>
        </a>
      </p>
    </div>
  </ng-sidebar>

  <div ng-sidebar-content>
    <header class="header">
      <div>
        <button (click)="_toggleSidebar()" class="sidebar-header__toggle">
          Toggle sidebar
        </button>
      </div>
      <div class="col-sm-2">
        <button class="btn btn-color button-logo" (click)="openHome()"> 
          <img src="assets/images/SharkTracker_Logo.jpg" height="110%" width="110%">
        </button>
      </div>
      <div class="col-sm-1">
        <button *ngIf="loggedIn()" class="btn btn-color btn-lg" 
          (click)="openProjects()">
          Projects
        </button>
      </div>
      
      <div class="col-sm-2">
        <mat-form-field *ngIf="projectSelected()" appearance="fill">
          <mat-label>{{currentProject}}</mat-label>
          <mat-select [(ngModel)]="selectedView" name="view">
            <mat-option *ngFor="let view of views" [value]="view.value" (onSelectionChange)="onSelection(view.value, $event)">
              {{view.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="loggedIn()" class="welcome-item">
        <p class="header-item-text">
          Welcome, {{getUsername()}}
        </p>
      </div>
    </header>

  </div>

</ng-sidebar-container>

