<mat-nav-list>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      General Stats
    </mat-expansion-panel-header>
    <div class="col-sm-12">
      <ul>
        <li>Max number of members: 20</li>
        <li>Tribe size: 6</li>
        <li>Task count: </li>
        <li>Tasks completed: </li>
        <li>Additional project metrics</li>
      </ul>

      <div class="row mb-5">
        <label>Tribe Members</label>
        <div class="col-md-12 mb-5 d-flex justify-content-between">
            <div *ngFor="let user of tribe" class="material-tooltip-main" data-toggle="tooltip"
            title="{{user.firstname}} {{user.lastname}}">
              <img src="{{user.avatarUrl | async}}" alt="ava" class="md-avatar rounded-circle size-1" onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      Invite User
    </mat-expansion-panel-header>
    <div class="col-sm-12">
        <br>
        <form [formGroup]="userForm" (ngSubmit)="onSubmitInvite()" class="col-sm-12">
          <div class="form-group col-sm-8" [ngClass]="{ 'has-error': invalidEmail() }"> <!-- Email -->
            <label for="email" class="control-label">E-mail</label>
            <input type="text" formControlName="email" class="form-control" id="email" name="email" placeholder="your@email.com">
            <p *ngIf="invalidEmail()" id="email_error" class="help-block">Invalid email address.</p>
          </div>
          <div class="form-group col-sm-6">
            <button type="submit" class="btn btn-primary" >Send</button>
          </div>
        </form>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      Promote User to First Mate
    </mat-expansion-panel-header>
    <div class="col-sm-12">
        <br>
        <form (ngSubmit)="onPromoteUser(selectedUser.value)" class="col-sm-12">
          <div class="form-group col-sm-8">
            <label for="email" class="control-label">Tribe</label>
            <select #selectedUser (change)='onSelectPromotionChange(selectedUser.value)' class="form-control">
              <option *ngFor="let user of deckHands" [value]="user.email">
                {{user.firstname}} {{getLastNameInitial(user.lastname)}}
              </option>
            </select>
          </div>
          <div class="form-group col-sm-6">
            <button type="submit" class="btn btn-primary">Promote</button>
          </div>
        </form>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="isCaptain">
    <mat-expansion-panel-header>
        Configure Project
    </mat-expansion-panel-header>
    <div class="col-sm-12">
        <!-- <br> -->
      <form *ngIf="project" [formGroup]="configurationForm" (ngSubmit)="onSubmitConfigure()" class="col-sm-12">
        
        <div class="form-group col-sm-8" [ngClass]="{'error': titleIsInvalid()}">
          <label for="title" class="control-label">Project Name</label>
          <div *ngIf="title.invalid && (title.dirty || title.touched)">
            <em *ngIf="title.errors.required">
              Title is required.
            </em>
            <em *ngIf="title.errors.maxlength">
              Length exceeded by {{title.errors.maxlength.actualLength - title.errors.maxlength.requiredLength}} character(s).
            </em>
          </div>
          <input
           formControlName="title" type="text" class="form-control">
        </div>
        
        <div class="form-group col-sm-8" [ngClass]="{'error': description.invalid && description.dirty}">
          <label for="description" class="control-label">Project Description</label>
          <div *ngIf="description.invalid && (description.dirty || description.touched)">
            <em *ngIf="description.errors.maxlength">
              Length exceeded by {{description.errors.maxlength.actualLength - description.errors.maxlength.requiredLength}} character(s).
            </em>
          </div>
          <textarea formControlName="description" 
            type="text" 
            class="form-control rounded-0"
            rows="3">
          </textarea>
        </div>
        <div class="form-group col-sm-8">
          <label for="daysUntilTaskDeletion" class="control-label">Days Until Task Deletion</label>
          <select formControlName="daysUntilTaskDeletion" class="form-control">
            <option value="30">30</option>
            <option value="60">60</option>
            <option value="90">90</option>
            <option value="120">120</option>
            <option value="150">150</option>
          </select> 
        </div>
        <div class="form-group col-sm-8">
          <mat-slide-toggle formControlName="voyageBtn" color="primary" #voyageSlideToggle>
            Enable Voyages
          </mat-slide-toggle>
          <div *ngIf="voyageSlideToggle.checked">
            <label class="form-label" for="voyageRange">Length in Weeks</label>
            <mat-slider color="primary" formControlName="voyageSlider" 
              thumbLabel tickInterval="1" min="1" max="6" step="1">
            </mat-slider>
          </div>
        </div>
        <div class="form-group col-sm-8">
          <tag-builder formControlName="tagBuilder" [project]="project"
            (onTagControlInit)="initTagCtrl($event)">

          </tag-builder>
        </div>
        <div class="form-group col-sm-6">
          <button type="submit" class="btn btn-primary" [ngClass]="{'disabled': !configurationForm.valid}">Save</button>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-nav-list>

