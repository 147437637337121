export class SubscriptionModel
{
  id: string;
  status: string; // "active, paused, cancelled"
  start_time?: string;
  status_update_time?: string;
  plan_id?: string;
  billing_info?: any;
  facilitatorAccessToken?: string;
	name?: string;
  description?: string;
  rate?: string;

  startDate?: string;
  endDate?: string;

	constructor(obj: any = null)
	{
		if(obj != null)
		{
			Object.assign(this, obj);
      console.log(obj['billing_info'])
      if ('billing_info' in obj && Object.keys(obj['billing_info']).length !== 0) {
        let time = new Date(this?.billing_info?.last_payment.time)
        let nextPayment = new Date(this?.billing_info?.next_billing_time)
        this.rate = this.getRate(time, nextPayment)
      }
		}
	}

  private getRate(time: Date, nextPayment: Date): string {
    if (this.yearly(time, nextPayment)) {
      return 'yearly'
    }
    return 'monthly'
  }

  private yearly(time: Date, nextPayment: Date): boolean {
    const microSecondsDiff = Math.abs(nextPayment.getTime() - time.getTime());
    // Math.round is used instead of Math.floor to account for certain DST cases
    // Number of milliseconds per day =
    //   24 hrs/day * 60 minutes/hour * 60 seconds/minute * 1000 ms/second
    const daysDiff = Math.round(microSecondsDiff / (1000 * 60 * 60  * 24));
    if (daysDiff > 31) {
      return true
    }
    return false
  }
}