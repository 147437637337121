
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserModel } from '../models/UserModel';
import { ProjectModel } from '../models/ProjectModel';
import { ProjectService, RouteService } from '../shared';

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'input-user-data-form',
  templateUrl: './input-user-data-form.component.html',
  styleUrls: ['./input-user-data-form.component.css']
})

export class InputUserDataFormComponent implements OnInit {
	registered = false;
	submitted = false;
  userForm: FormGroup;


  constructor(private formBuilder: FormBuilder,
    private projectService: ProjectService,
    private routeService: RouteService)
  {

  }

  current_selected = [];

  onSelection(e){
    console.log('Current Selection: ' + this.current_selected);
    this.current_selected.push(e);
    
  }

  invalidFirstName()
  {
  	return (this.submitted && this.userForm.controls.firstname.errors != null);
  }

  invalidLastName()
  {
  	return (this.submitted && this.userForm.controls.lastname.errors != null);
  }

  invalidEmail()
  {
  	return (this.submitted && this.userForm.controls.email.errors != null);
  }

  invalidZipcode()
  {
  	return (this.submitted && this.userForm.controls.zipcode.errors != null);
  }

  invalidPassword()
  {
  	return (this.submitted && this.userForm.controls.password.errors != null);
  }

  projects: ProjectModel[] = [];

  ngOnInit()
  {
    this.projectService.getProjects()
    .then((res) => this.projects = res)
    .catch((err) => {
      console.log('Error: ' + JSON.stringify(err));
    })

  	this.userForm = this.formBuilder.group({
  		firstname: ['', Validators.required],
  		lastname: ['', Validators.required],
  		email: ['', [Validators.required, Validators.email]],
  		zipcode: ['', [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]],
      password: ['', [
        Validators.required, 
        Validators.minLength(5),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$')]
      ],
    });
    
  }

  pullHandler(res): any
  {
    var resBody = res["body"];
    var resJson = JSON.parse(resBody);
    var itemArr = resJson["Items"];
    itemArr.forEach(element => {
      this.projects.push(new ProjectModel({
        name: element["projectName"],
        uuid: element["projectUuid"],
        description: element["projectDescription"],
        startDate: (new Date()).toString(),
      }))
    });
  }

  onSubmit()
  {
  	this.submitted = true;

  	if(this.userForm.invalid)
  	{
      console.log("invalid form")
  	}
  	else
  	{
      var user = new UserModel({
        guid: "",
        customerUid: "",
        firstname: "",
        lastname: "",
        email: "",
        zipcode: "",
        password: "",
        projects: [],
      });
      user.email = this.userForm.controls.email.value;
      user.projects = this.current_selected;
      user.firstname = this.userForm.controls.firstname.value;
      this.registered = true;
  	}
  }

}
