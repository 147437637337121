import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { TaskModel, ProjectModel } from 'src/app/models';
import { NotificationService } from '../services';
import { constants } from './config/modal-config-constants';

import { Observable, Subscription } from 'rxjs';
import { QuestionBase } from 'src/app/models/question/question-base.model';
import { DropdownQuestion } from 'src/app/models/question/question-dropdown.model';
import { QuestionControlService } from 'src/app/models/question/question-control.service';

@Component({
  selector: 'create-task-modal-content',
  templateUrl: 'create-task-modal-content.component.html',
  styleUrls: ['./config/modal-validation.css']
})
export class CreateTaskModalContent implements OnInit {
  @Input() public project: ProjectModel;
  @Input() public task: TaskModel;
  @Input() public tribeMembers: any[];
  @Output() onCreateQuestion: EventEmitter<string> = new EventEmitter()
  @Output() onUpdateQuestion: EventEmitter<string> = new EventEmitter()
  public questionGroupObservable: Observable<any>;
  public questionGroupSubscription: Subscription;
  //#region Angular Forms
  taskForm: FormGroup
  title: FormControl
  assignee: FormControl
  description: FormControl
  sharkRank: FormControl
  sharkClassification: FormControl
  status: FormControl
  tagUpdater: FormControl
  questionGroup: FormGroup

  //#endregion
  constructor(public activeModal: NgbActiveModal,
              private qcs: QuestionControlService,
              private notifications: NotificationService) 
  {}

  ngOnInit() {
    // create task modal has no questions to retrieve!
    this.title = new FormControl('', [
      Validators.required, 
      Validators.maxLength(constants.titleLength)
    ])
    this.description = new FormControl('', [
      Validators.maxLength(constants.descriptionLength)
    ]);
    this.assignee = new FormControl('unassigned');
    this.sharkRank = new FormControl('');
    this.sharkClassification = new FormControl('');
    this.status = new FormControl('todo');
    this.tagUpdater = new FormControl([]);
    // task model is empty so use a default value for questions
    this.questionGroup = this.qcs.toFormGroup([])

    this.taskForm = new FormGroup({
      title: this.title,
      assignee: this.assignee,
      description: this.description,
      sharkRank: this.sharkRank,
      sharkClassification: this.sharkClassification,
      status: this.status,
      tagUpdater: this.tagUpdater,
      questionGroup: this.questionGroup
    })

    // this.questionGroupObservable = this.questionGroup.valueChanges
    // this.questionGroupSubscription = this.questionGroup.valueChanges.subscribe(formValues => {
    //   this.onQuestionChange(formValues)
    // })
  }

  passBack(): void
  {
    console.log("Passing modal-content to modal service!");
    this.activeModal.close(this.task);
  }

  onSubmit(formValues) {
    console.log('formValues', formValues)
    console.log("Passing modal-content to modal service!");
    
    const newTask = new TaskModel({
      uuid: '',
      title: formValues.title,
      assignee: formValues.assignee,
      description: formValues.description,
      sharkRank: formValues.sharkRank,
      sharkClassification: formValues.sharkClassification,
      status: formValues.status,
      tags: formValues.tagUpdater,
      questions: this.createQuestions(formValues)
    });
    // this.createQuestions(formValues)
    this.activeModal.close(newTask);
  }

  createQuestions(formValues): QuestionBase<string>[] {
    if (!formValues || !formValues.questionGroup) return []
    let questions: QuestionBase<string>[] = []
    let keyQuestionMap: {
      [key: string]: QuestionBase<string>
    }
    let questionGroup: {
      [key: string]: string
    }
    keyQuestionMap = this.task.getQuestionMap()
    console.log('keyQuestionMap', keyQuestionMap)
    questionGroup = formValues.questionGroup
    console.log('questionGroup', questionGroup)
    Object.keys(questionGroup).forEach((key: string) => {
      const lowerCaseKey = key.toLocaleLowerCase()
      keyQuestionMap[lowerCaseKey].value = questionGroup[key]
    });
    // flatten the map
    for (let key in keyQuestionMap) {
      questions.push(keyQuestionMap[key])
    }

    return questions
  }

  isValid(): boolean {
    return this.taskForm.valid;
  }

  closureCheck(formValues) {
    if (this.taskFormIsDirty() && this.isValid()) {
      // run warning
      if (this.notifications.alertUser() === 'Save') {
        this.onSubmit(formValues)
        return
      }
    }
    // close modal
    this.activeModal.close('Close click')
  }

  initTagCtrl(tagCtrl: FormControl): void {
    // this.taskForm.addControl('tagInputControl', tagCtrl)
    console.log('this.taskForm.controls', this.taskForm.controls)
    this.taskForm.setControl('tagUpdater', tagCtrl)
    this.taskForm.updateValueAndValidity()
    console.log('this.taskForm.controls', this.taskForm.controls)
    // console.log('added tagCtrl to taskForm', this.taskForm)
  }

  handleQuestion(event: {key: string, value: string}): void {
    const key = event.key
    const value = event.value

    const formControl = this.getFormControl(key) as FormControl
    if (this.notNull(formControl)) {
      // add an option to the question representing the existing form control
      this.updateQuestion(event)
    }
    else {
      // add new question to the questionGroup
      this.addQuestion(event)

      // update the questionGroup
      const group = this.qcs.toFormGroup(this.task.questions)
      this.taskForm.setControl('questionGroup', group)
      this.taskForm.updateValueAndValidity()
    }
  }

  getFormControl(name: string): AbstractControl {
    console.log('this.questionGroup', this.questionGroup)
    console.log('this.taskForm.controls', this.taskForm.controls)
    console.log('this.taskForm.get(questionGroup)', this.taskForm.get('questionGroup'))       
    
    console.log('name', name)
    const questionForm: FormGroup = this.taskForm.get('questionGroup') as FormGroup
    console.log('questionForm', questionForm)
    const formCtrl = questionForm.controls[name]
    return formCtrl
  }

  addQuestion(event: {key: string, value: string}): void {
    console.log('addQuestion', event)
    const lowerCaseKey = event.key.toLocaleLowerCase()
    const lowerCaseVal = event.value.toLocaleLowerCase()
    const label = this.capitalize(lowerCaseKey)
    this.task.questions.push(new DropdownQuestion({
      key: lowerCaseKey,
      label: label,
      order: this.task.questions.length + 1,
      options: [
        {key: lowerCaseVal, value: event.value}
      ]
    }))
    this.onCreateQuestion.emit(this.task.questions.toString())
  }

  updateQuestion(event: {key: string, value: string}): void {
    console.log('updateQuestion', event)
    const lowerCaseKey = event.key.toLocaleLowerCase()
    const lowerCaseVal = event.value.toLocaleLowerCase()
    let idx = this.task.questions.findIndex((question) => {
      return question.key === lowerCaseKey
    })
    this.task.questions[idx].options.push({key: lowerCaseVal, value: event.value})
    this.onUpdateQuestion.emit(this.task.questions.toString())
  }

  capitalize(word: string): string {
    if (word.length === 0) return ""
    let char: string = word.charAt(0);
    let capital: string = char.toUpperCase()
    let slice: string = word.slice(1)
    console.log('capitalization', capital + slice)
    return capital + slice;
  }

  taskFormIsDirty(): boolean {
    return this.taskForm.dirty
  }

  notNull(val: any): boolean {
    return val !== undefined && val !== null
  }

  // onQuestionChange(formValues: object): void {
  //   console.log('this.questionGroup.values', formValues)
  //   const keys = Object.keys(formValues)
  //   const values = Object.values(formValues)
  //   const numQuestions = this.task.questions.length
  //   if (keys.length !== numQuestions || values.length !== numQuestions) {
  //     throw Error('formValue keys to question length mismatch')
  //   }
  //   keys.forEach((key, index) => {
  //     if (this.task.questions[index].key === key) {
  //       // console.log('keys['+ index +']', keys[index], 'values['+index+']', values[index])
  //       this.task.questions[index].value = values[index]
  //     }
  //   })
  //   // console.log('this.task.questions', this.task.questions)
  // }

  // ngOnDestroy() {
  //   this.questionGroupSubscription.unsubscribe()
  // }
}