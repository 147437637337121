<amplify-authenticator class="auth" username-alias="email" *ngIf="authState !== 'signedin'">
  <amplify-sign-in 
    header-text="Sign-in to your Account"
    slot="sign-in"
    usernameAlias="email"
    header-text="Login to your Account"
    submit-button-text="Log In"
    (handleAuthStateChange)="signInHandler">
  </amplify-sign-in>
  <amplify-sign-up
    slot="sign-up"
    usernameAlias="email"
    [formFields]="signUpFormFields">
  </amplify-sign-up>
  <amplify-confirm-sign-up *ngIf="authState === 'confirmSignUp'"
    usernameAlias="email"
    header-text="Confirm Registration">
  </amplify-confirm-sign-up>
</amplify-authenticator>