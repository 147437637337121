<div class="col-sm-12">
  <div class="container">
    <div class="row">
      <div class="col-md-12 mx-auto">
        <div class="md-form">
          <input
            type="text"
            [(ngModel)]="searchText"
            class="form-control"
            id="search"
            mdbInput
          />
          <label for="search">Search</label>
        </div>
      </div>
    </div>
  </div>
  <div>
  <table mdbTable #tableEl="mdbTable" hover="true" class="table table-striped table-bordered table-sm">
    <thead>
      <tr>
        <th *ngFor="let head of headerElements; let i = index" aria-controls="tableSortExample" scope="col" [mdbTableSort]="tasks" [sortBy]="headerElements[i]">
          {{head}}
          <mdb-icon fas icon="sort"></mdb-icon>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr mdbTableCol *ngFor="let task of tasks; let i = index" (click)="openModal(task)">
        <th *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
          scope="row">{{task.viewId}}</th>
      

        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{task.title}}</td>
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{task.creationDate}}</td>
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{task.status}}</td>
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{task.sharkRank}}</td>
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{task.sharkClassification}}</td>
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{task.description}}</td>
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{getAssigneeFirstName(task.assignee)}}</td>
      </tr>
    </tbody>
    <tfoot class="grey lighten-5 w-100">
      <tr>
        <td colspan="8">
          <mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="tasks"></mdb-table-pagination>
        </td>
      </tr>
      </tfoot>
  </table>
  </div>
</div>

