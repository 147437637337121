<form [formGroup]="taskForm" (ngSubmit)="onSubmit(taskForm.value)">
  <div class="modal-body">
    <div class="form-group" [ngClass]="{'error': title.invalid && title.dirty}">
      <label for="title">Task Title: </label>
      <div *ngIf="title.invalid && (title.dirty || title.touched)">
        <em *ngIf="title.errors.required">
          Title is required.
        </em>
        <em *ngIf="title.errors.maxlength">
          Length exceeded by {{title.errors.maxlength.actualLength - title.errors.maxlength.requiredLength}} character(s).
        </em>
      </div>
      <input type="text" formControlName="title" class="form-control">
    </div>
    <div class="form-group">
      <label>Assign A User: </label>
      <select formControlName="assignee" class="form-control">
        <option *ngFor="let user of tribeMembers" [value]="user.uuid">
          {{user.firstname}}
        </option>
      </select>
    </div>
    <div class="form-group" [ngClass]="{'error': description.invalid && description.dirty}">
      <label>Task Description: </label>
      <div *ngIf="description.invalid && (description.dirty || description.touched)">
        <em *ngIf="description.errors.maxlength">
          Length exceeded by {{description.errors.maxlength.actualLength - description.errors.maxlength.requiredLength}} character(s).
        </em>
      </div>
      <textarea formControlName="description" class="form-control rounded-0"
                rows="3">
      </textarea>
    </div>
    <div class="form-group">
      <label>Rank your shark: </label>
      <select formControlName="sharkRank" class="form-control">
        <option value="megalodon">megalodon</option>
        <option value="greatwhite">great white</option>
        <option value="meat-eater">meat-eater</option>
        <option value="tadpole">tadpole</option>
        <option value="plankton">plankton</option>
      </select> 
    </div>
    <div class="form-group">
      <label>Classify your shark: </label>
      <select formControlName="sharkClassification" class="form-control">
        <option value="bug">bug</option>
        <option value="test">test</option>
        <option value="spike">spike</option>
        <option value="feature">feature</option>
      </select> 
    </div>
    <div class="form-group">
      <app-dynamic-form formGroupName="questionGroup" 
          [questionGroup]="taskForm.controls.questionGroup"
          [questions]="task.questions">
        
      </app-dynamic-form>
    </div>
    <div class="form-group">
      <label style="display:inline-block;">Choose your tank: </label><br>
      <div class="col-sm-12">
        <label for="backlog">
          <input formControlName="status" type="radio" id="backlog" 
          name="status" value="backlog" style="display:inline-block;">
            Backlog
        </label><br>
      </div>
      <div class="col-sm-12">
        <label for="todo">
          <input [checked]="true" formControlName="status" type="radio" id="todo" name="status" value="todo">
            To Do
        </label>
        <br>
      </div>
    </div>
    <div class="form-group">
      <tag-updater formControlName="tagUpdater" [project]="project" [task]="task"
        (onTagControlInit)="initTagCtrl($event)" (onQuestionEvent)="handleQuestion($event)">
        
      </tag-updater>
    </div>
  </div>
  <div class="modal-footer" style="align-items: flex-start;">
    <button type="button" class="btn btn-primary" 
      (click)="onSubmit(taskForm.value)"
      [ngClass]="isValid() ? 'enabled' : 'disabled'">
        Create Task
    </button>
    <button type="button" class="btn btn-danger" 
      (click)="closureCheck(taskForm.value)">
        Cancel
    </button>
  </div>
</form>