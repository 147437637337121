import { Component, ElementRef, EventEmitter, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IPayPalConfig, ICreateSubscriptionCallbackData, ICreateSubscriptionCallbackActions, ICreateSubscriptionRequest } from 'ngx-paypal';
import { HttpService } from '../shared/http/http.service';
import { SubscriptionModel, UserModel } from '../models';
import { NotificationService, RouteService, UserInfoService } from '../shared';

declare var paypal;
@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.css']
})
export class PaymentPageComponent implements OnInit {

  public payPalConfig?: IPayPalConfig;
  public showSuccess: boolean = false;
  public user: UserModel;
  @ViewChild('paypal') paypalElement: ElementRef;
  @ViewChild('paypalMonthly') paypalMonthly: ElementRef;
  @ViewChild('paypalYearly') paypalYearly: ElementRef;


  clientId: string = "Aesb14oOfusW-xNvUY6XpxEIVLnLa7OlgUKaMsfok-G53Y41-XG5Q1aNeDFgdH2hxBsa8VEmSoA1CsMD";
  monthlyPlanId: string = 'P-6SB07107Y30820121MASYULA';
  yearlyPlanId: string = 'P-84612088U8722141RMATLF3Q' ;
  selectedPlanId: string;
  basicAuth = '';


  public onSubscriptionCreated = new EventEmitter();

  constructor(private notifications: NotificationService, 
    private userInfoService: UserInfoService,
    private routeService: RouteService) { }  

  ngOnInit(): void {
    this.initConfig();
  }

  private initConfig(): void {
    
  }

  async ngAfterViewInit() {
    await this.loadScript("https://www.paypal.com/sdk/js?client-id=Aesb14oOfusW-xNvUY6XpxEIVLnLa7OlgUKaMsfok-G53Y41-XG5Q1aNeDFgdH2hxBsa8VEmSoA1CsMD&vault=true&intent=subscription")
    const self = this;  
    // this.selected = 'P-6SB07107Y30820121MASYULA';
    paypal.Buttons({
      style: {
        label: 'paypal',
        layout: 'vertical',
        color: 'blue'
      },
      advanced: {
        commit: 'true',
        extraQueryParams: [{ name: 'intent', value: 'subscription' }]
      },  
      createSubscription: function (data, actions) {  
        return actions.subscription.create({  
          'plan_id': self.selectedPlanId,  
        });  
      },  
      onApprove: function (data, actions) {  
        console.log(data);
        self.notifications.showInProgress('Waiting for crosscheck...', 'Subscription pending approval...')
        // self.userInfoService.user.subscription = new SubscriptionModel({
        //   id: data.subscriptionID
        // })
        self.activateSubscription(data.subscriptionID);
        // self.getSubcriptionDetails(data.subscriptionID);  
      },  
      onCancel: function (data) {  
        // Show a cancel page, or return to cart  
        console.log(data);
      },  
      onError: function (err) {  
        // Show an error page here, when an error occurs  
        console.log(err);  
      }  
  
    }).render(this.paypalElement.nativeElement);
  }
  
  private loadScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script')
      scriptElement.src = scriptUrl
      scriptElement.onload = resolve
      document.body.appendChild(scriptElement)
    })
  }

  private activateSubscription(subscriptionId: string) {
    // first make a call to paypal endpoint for subscription details
    // this.userInfoService.updateUser(subscription)
    // do what you want with subscription details in dynamo tables
    this.userInfoService.activateUser(subscriptionId)
    .then((user) => {
      this.user = user
      console.log('Updated subscription returned: ', user.subscription)
      if (this.userInfoService.active()) {
        // this.ngZone.run(() => this.router.navigate(['/projects']));
        this.routeService.openInsideAngularZone(['/projects'])
      }
    })
    .catch((err) => {
      console.error(err);
    })
  }

  toggleMonthly() {
    if (this.selectedPlanId === this.monthlyPlanId)
      this.selectedPlanId = undefined;
    else {
      this.selectedPlanId = this.monthlyPlanId
      console.info('Monthly plan selected!')
    }
  }

  toggleYearly() {
    if (this.selectedPlanId === this.yearlyPlanId)
      this.selectedPlanId = undefined;
    else {
      this.selectedPlanId = this.yearlyPlanId;
      console.info('Yearly plan selected!')
    }
  }

  planSelected(): boolean {
    return this.selectedPlanId !== undefined
  }

  subscriptionDetailsHandler() {

  }
  
  //  DEPRECATED ngx-paypal
  private ngxInit() {
    this.payPalConfig = {
      currency: 'USD',
      clientId: 'ARwbXeAdOYl0wIkU__2ttnd_yJ84D7Xi1QsR7EAP2DqBC6Tb6AhjbYhOfW6tRJVHpjk21a5fPN0QMGOM',
      vault: 'true',
      advanced: {
        commit: 'true',
        extraQueryParams: [{ name: 'intent', value: 'subscription' }]
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
        color: 'blue'
      },
      createSubscription: (data, actions) => {
        console.log(data)
        actions.subscription.create( <ICreateSubscriptionRequest> {
          'plan_id': 'P-9MK968360W2888000MAOG7CA',
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(JSON.stringify(err))
        })
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      }
    };
  }

  public onScriptLoad(paypal: any) {
    console.log(paypal);
  }
}
