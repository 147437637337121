import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ModalService, ProjectService } from '../../services';
import { CreateTaskModalContent } from '../../modals' 
import { TaskModel, ProjectModel, Column } from '../../../models';
 
@Component({
  selector: 'create-task-button',
  templateUrl: './create-task-button.component.html',
  styleUrls: ['./create-task-button.component.css']
})
export class CreateTaskButtonComponent {
  @Output() messageEvent = new EventEmitter<string>();
  @Input() column: Column;
  @Input() projectId: string;
  @Input() defaultStatus: string;
  private task: TaskModel;
  private project: ProjectModel;
  constructor(private modalService: ModalService, private projectService: ProjectService) { }

  ngOnInit(): void {
    // this.messageEvent = new EventEmitter();
    this.project = this.projectService.getProject(this.projectId)
  }

  open(): void {
    let status = "";
    if (this.defaultStatus === "backlog")
      status = 'backlog';
    else
      status = 'todo';
      
    this.task = new TaskModel({})
    let data = {
      task: this.task,
      project: this.project
    }
    this.modalService.openTaskModal(CreateTaskModalContent, data)
    .then((result) => {
      console.log('Result: ' + JSON.stringify(result));
      this.messageEvent.emit(result);
    })
    .catch((result) => {
      console.log('Result: ' + result);
    })
    .finally(() => {
      this.task = new TaskModel({});
    });
  }
}
