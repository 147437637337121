<!-- Credit: https://github.com/Devstackr/kanban-angular-layout/blob/master/src/app/pages/main-view/ -->

<div class="board">
  <div class="col-sm-12">
    <div *ngIf="project.voyageEnabled" class="board-bar" (click)="openVoyageUpdateModal()">
      <voyage-progress-bar [project]="project"></voyage-progress-bar>
    </div>
  </div>
  <div class="board-wrapper">
    <div class="board-columns" cdkDropListGroup>
      <div class="board-column" *ngFor="let column of board.columns; let i = index">

        <div class="column-title">
          {{ column.name }}
          <create-task-button [projectId]="project.uuid" *ngIf="i === 0" 
            class="float-right" [column]="column"
            (messageEvent)="passCreate($event)">
            <span class="glyphicon glyphicon-plus-sign"></span>      
          </create-task-button>
        </div>

        <div class="tasks-container"
          cdkDropList
          [cdkDropListAutoScrollDisabled] = "false"
          [cdkDropListData]="column.tasks"
          (cdkDropListDropped)="drop($event)"
          [attr.index]="i">

          <div *ngFor="let item of column.tasks">
            <task-thumbnail [projectId]="project.uuid" [projectName]="project.name" 
              *ngIf="item.status !== 'archived' && item.status !== 'backlog'" [task]="item"
              class="card" (messageEvent)="passUpdate($event)" [cdkDragData]="item" cdkDrag >
            </task-thumbnail>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
