import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VoyageModel } from 'src/app/models';

@Component({
  selector: 'warning-modal',
  template:`
    <div class="modal-header">
      <h4 class="modal-title">Archive Voyage</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label for="confirm">Confirm by typing {{archiveConfirmation}}</label> <br>
      <input [(ngModel)]='archiveInput' type="text" id="confirm" name="confirm">
    </div>
    <div class="modal-footer">
      <button [ngClass]="confirmed() ? 'active' : 'disabled'" type="button" class="btn btn-secondary" (click)="activeModal.close('Archive')">Archive</button>
    </div> 
  `
})
export class WarningModalComponent {

  @Input() voyage: VoyageModel
  public archiveInput: string = '';
  public archiveConfirmation: string = 'archive'
  constructor(public activeModal: NgbActiveModal) { }

  passBack(): void
  {
    console.log("Warning user...");
  }

  confirmed(): boolean {
    return this.archiveConfirmation === this.archiveInput;
  }

  ngChanges() {
    console.log(this.archiveInput)
  }
}