import { Directive, Input, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectService } from '../../shared';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, ValidatorFn, ValidationErrors, AbstractControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { ProjectModel, TaskModel } from 'src/app/models';

@Directive({
  selector: '[TagControl]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: TagControlDirective
    }
  ]
})
export class TagControlDirective implements ControlValueAccessor {

  @Input() allTags: string[] = [];
  @Input() tags: string[] = [];
  filteredTags: Observable<string[]>;
  visible = true;
  selectable = true;
  removable = true;
  touched = false;
  disabled = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor() {}

  onChange = (quantity) => { console.log('changed!') };

  onTouched = () => { console.log('touched!') };

  writeValue(obj: any): void {
    console.log('writeValue', `${obj}`)
    console.log('this.tags', this.tags)
    if (Array.isArray(obj)) {
      console.log('isArray true!')
      let initArray = Array.from(obj)
      initArray.forEach((element) => {
        this.tags.push(element)
      })
    }
    else if (this.tags && obj !== null && obj !== "") {
      console.log('pushing')
      this.tags.push(`${obj}`)
      this.tags = this.tags.filter((value) => {
        return value !== ""
      })
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    console.log('before touched: ', this.touched)
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      console.log('after touched: ', this.touched)
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  // validate({ value }: FormControl) {
  //   const isNotValid = this.tags !== ;
  //   return isNotValid && {
  //     invalid: true
  //   }
  // }
  
  forbiddenTagValidator(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = nameRe.test(control.value);
      return forbidden ? {forbiddenName: {value: control.value}} : null;
    };
  }
}
