import { Component, OnInit } from '@angular/core';
import { ProjectModel, TaskModel, VoyageModel } from '../models';
import { ProjectService, TaskService, RouteService } from '../shared';

@Component({
  selector: 'app-display-user-projects',
  templateUrl: './display-user-projects.component.html',
  styleUrls: ['./display-user-projects.component.css'],
})

export class DisplayUserProjectsComponent implements OnInit {

  projects: ProjectModel[];
  defaultStatus = "backlog";
  
  constructor(private routeService: RouteService, 
    private projectService: ProjectService,
    private taskService: TaskService) {}
  

  ngOnInit() 
  {
    this.projectService.getProjects()
    .then((res) => {
      this.projects = res
      this.projects.sort((lhs, rhs) => {
        return rhs.taskCount - lhs.taskCount
      })
    })
    .catch((err) => {
      console.log('Error: ' + JSON.stringify(err));
    })
  }
  
  addProject(project: ProjectModel): void
  { 
    this.projects.push(project);
  }

  removeProject(project: ProjectModel): void
  {
    // FIXME
    return;
  }
  
  receiveProjectInfo($event) {
    console.log("Incoming message from modal...");
    console.log($event);
    console.log(typeof $event);
    if ($event === "Close click")
    {
      console.log("Modal closed! Changes lost...");
    }
    else if (typeof $event === 'object')
    {
      let project = new ProjectModel($event);

      console.log("Adding project to list...");
      this.projectService.pushProject(project)
      .catch((err) => {
        console.log(err)
      })
    }
  }
  
  receiveTaskInfo($event, project: ProjectModel)
  {
    if ($event === "Close click")
    {
      console.log("Modal closed! Changes lost...");
    }
    else if (typeof $event === 'object')
    {
      console.log('$event', $event)
      let task = new TaskModel($event);

      console.log("Adding new task to project...");
      this.taskService.pushTask(task, project);
    }
  }

  receiveVoyageUpdate($event, project: ProjectModel) {
    if ($event === "Close click" || $event === "Cross click")
    {
      console.log("Modal closed! Changes lost...");
    }
    else if (typeof $event === 'object')
    {
      let voyage = new VoyageModel($event);

      console.log("Updating voyage in project...");
      console.log(voyage)
      this.projectService.updateVoyage(project, voyage, 'none');
    }
  }

  openBoard(projectId) {
    console.log(projectId)
    console.log("Opening Kanban board!");
    this.routeService.openInsideAngularZone(['/board'], { queryParams: { project: projectId } })
  }

  openSettings(projectId) {
    console.log("Opening Settings!");
    this.routeService.openInsideAngularZone(['/settings'], { queryParams: { project: projectId } })
  }

  openVoyageView($event) {
    if (typeof($event) == 'string') {
      let projectId = $event.toString()
      this.routeService.openInsideAngularZone(['/voyages'], { queryParams: { project: projectId } })
    }
  }

  projectsLoaded(): boolean {
    return !!(this.projects)
  }
}
