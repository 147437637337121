import { environment } from './environments/environment';

const cloud_config = {
  s3: {
    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: environment.apiUrl
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: environment.cognitoUserPoolId,
    APP_CLIENT_ID: environment.cognitoUserPoolClientId,
    IDENTITY_POOL_ID: "us-east-2:e789355c-ad0b-4459-8c12-2cdb1e87cc62"
  },
  oauth: {
    DOMAIN: 'https://thesharktracker.auth.us-east-2.amazoncognito.com',
    SCOPES: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    REDIRECT_SIGN_IN: 'http://localhost:4200/projects',
    REDIRECT_SIGN_OUT: 'http://localhost:4200/login',
    COOKIE: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: environment.domain,
      // OPTIONAL - Cookie path
        path: '/',
      // OPTIONAL - Cookie expiration in days
        expires: 1,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: environment.sameSite,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: environment.secure
      }
  }
};

export default cloud_config