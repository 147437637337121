<hr>
<div *ngIf="!user" class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>
<mat-nav-list *ngIf="user">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      App Settings
    </mat-expansion-panel-header>
    <div class="col-sm-12">
      <ul>
        <li>Max number of projects: 5</li>
        <li>Additional app constants and metrics</li>
      </ul>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      Manage Subscription
    </mat-expansion-panel-header>
    <div class="col-sm-12">
      <div *ngIf="active() || suspended()">
        <ul>
          <li>ID: {{user?.subscription.id}}</li>
          <li>Status: {{user?.subscription.status}}</li>
          <li>Billing: {{user?.subscription.billing_info.last_payment.amount.value}} 
            {{user?.subscription.billing_info.last_payment.amount.currency_code}} {{user?.subscription.rate}}
          </li>
        </ul>
        <button *ngIf="!suspended()" mdbBtn 
          color="secondary" mdbWavesEffect
          (click)="softCancel()"
          [ngClass]="{'disabled': cancelationPending}">
          Suspend Subscription
        </button>
        <button *ngIf="suspended()" #sub mdbBtn 
          color="secondary" mdbWavesEffect
          (click)="reactivateSubscription()"
          [ngClass]="{'disabled': activationPending}">
          Reactivate Subscription
        </button>
        <button mdbBtn color="danger" mdbWavesEffect
        (click)="hardCancel()"
        [ngClass]="{'disabled': cancelationPending}">
          Cancel Subscription
        </button>
      </div>
      <button *ngIf="!active()" mdbBtn 
        color="primary" mdbWavesEffect
        id="sub" (click)="subscribe()"
        [ngClass]="{'disabled': activationPending}">
        Subscribe
      </button>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      User Settings
    </mat-expansion-panel-header>
    <div class="col-md-12">
    <div>Upload or change your avatar:</div>
      <div
        ngfDrop
        multiple
        selectable       = "1"
        [(validDrag)]    = "validComboDrag"
        [(file)]        = "file"
        accept           = "image/*"
        [maxSize]        = "maxSize"
        class            = "well my-drop-zone col-sm-6"
        [(validDrag)]="validComboDrag"
        [(invalidDrag)]="invalidComboDrag">

        <div class="previewIcon" [ngfBackground]="file">
          <div class="col-sm-12"></div>
          </div>
        <label *ngIf="!file">Combo drop/select zone</label>
      </div>
    </div>
    <div class="col-sm-12">
      <button *ngIf="file" mdbBtn color="primary" (click)="uploadFiles(file)">upload avatar</button>
    </div>

  </mat-expansion-panel>
</mat-nav-list>
