import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, Validators } from '@angular/forms';

@Directive({
  selector: '[tagControlValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi:true,
      useExisting: TagControlValidator
    }
  ]
})
export class TagControlValidator implements Validator {

  onChange: any

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    console.log('validating custom control', control)
    const val = control.value
    console.log('tags', val)
    return { 'custom': 'This error triggered!' };
  }

  registerOnValidatorChange(fn: any) {
    this.onChange = fn
  }
}
