import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AuthService, UserInfoService } from './shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent {

  title = 'sharktracker';

  constructor(private auth: AuthService,
     private userInfoService: UserInfoService) {
      this.auth.userLoggedIn.subscribe(
        (value) => {
          this.userInfoService.onLogin.next(value)
        })
      this.auth.userLoggedOut.subscribe(
        (value) => {
          this.userInfoService.onLogout.next(value)
        })
    }

  ngOnInit() {
    this.auth.initialize()
    .then((res) => {
      console.log('User has logged in');
      // if (res !== undefined && res !== null) {
      //   this.userInfoService.observable.next(res);
      // }
    })
    .catch((err) => {
      console.log('User signed out / not signed up');
    });
  }
}
