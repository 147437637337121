import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EventEmitter } from 'events';
import { TaskModel } from 'src/app/models';
import { DropdownQuestion } from 'src/app/models/question/question-dropdown.model';
import { TaskService } from '../services';

import { QuestionBase } from './../../models/question/question-base.model';
import { QuestionControlService } from './../../models/question/question-control.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [ QuestionControlService ]
})
export class DynamicFormComponent implements OnInit {

  @Input() task: TaskModel | null;
  @Input() questions: QuestionBase<string>[];
  @Input() questionGroup!: FormGroup;
  payLoad = '';

  constructor() {
    console.log('DynamicFormComponent constructor', this)
  }

  ngOnInit() {
    
  }

  ngOnChanges() {
    console.log('ngOnChanges')
    console.log('this.questions', this.questions)
    console.log('this.questionGroup', this.questionGroup)
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.questionGroup.getRawValue());
    console.log(this.payLoad)
  }

  getPreExistingFormControl(prefix): any | null {
    return this.questionGroup.controls[prefix]
  }

  notNull(val: any): boolean {
    return val !== undefined && val !== null
  }
}