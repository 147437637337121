import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Event, NavigationExtras, Params, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  public currentProjectUuid: string
  get events(): Observable<Event> {
    return this.router.events
  }
  get queryParams(): Observable<Params> {
    return this.activatedRoute.queryParams
  }
  get routeSnapshot(): ActivatedRouteSnapshot {
    return this.activatedRoute.snapshot
  }

  constructor(private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private ngZone: NgZone) {
      this.activatedRoute.queryParams.subscribe((params) => {
        if ('project' in params) {
          this.currentProjectUuid = params['project']
        }
      })
    }

  open(route: string[], extras?: NavigationExtras): void {
    try {
      console.log('navigating to', route.toString())
      if (extras) console.log('extras', extras)
      this.router.navigate(route, extras)
    }
    catch (e) {
      throw new Error(e)
    }
  }

  openInsideAngularZone(route: string[], extras?: NavigationExtras): void {
    try {
      this.ngZone.run(() => {
        console.log('navigating to', route.toString())
        this.router.navigate(route, extras)
      })
    }
    catch (e) {
      throw new Error(e)
    }
  }

  openOutsideAngularZone(route: string[], extras?: NavigationExtras): void {
    try {
      this.ngZone.runOutsideAngular(() => {
        console.log('navigating to', route.toString())
        this.router.navigate(route, extras)
      })
    }
    catch (e) {
      throw new Error(e)
    }
  }
}
