import { Pipe, PipeTransform } from '@angular/core';
import { TaskModel } from 'src/app/models/TaskModel';

@Pipe({
  name: 'taskFilter'
})
export class TaskFilterPipe implements PipeTransform {

  transform(tasks: TaskModel[], searchText: string): TaskModel[] {
    if (!tasks)
    {
      return [];
    }
    if (!searchText) {
      return tasks;
    }
    searchText = searchText.toLocaleLowerCase();

    return tasks.filter(task => {
      return task.toLocaleLowerCase().includes(searchText);
    })
  }

}
