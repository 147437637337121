import { Input, Component, OnInit } from '@angular/core';
import { ProjectModel } from '../../../models/ProjectModel';
import { NotificationService, ModalService } from '../../services';
import { ConfirmModalComponent } from '../confirm-modal'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VoyageModel } from 'src/app/models';
import { constants } from '../config/modal-config-constants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'update-voyage-modal-content',
  templateUrl: './update-voyage-modal-content.component.html',
  styleUrls: ['./../config/modal-validation.css']
})
export class UpdateVoyageModalContent implements OnInit {
  @Input() public project: ProjectModel;
  @Input() public voyage: VoyageModel;
  //#region 
  voyageForm: FormGroup
  title: FormControl
  description: FormControl
  length: FormControl

  constructor(public notifications: NotificationService, 
    public modalService: ModalService,
    public activeModal: NgbActiveModal) { }
  
  ngOnInit() {
    this.title = new FormControl(this.voyage.name, [
      Validators.required, 
      Validators.maxLength(constants.titleLength)
    ])
    this.description = new FormControl(this.voyage.description, [
      Validators.maxLength(constants.descriptionLength)
    ]);
    this.length = new FormControl(this.voyage.lengthInWeeks);
    this.voyageForm = new FormGroup({
      title: this.title,
      description: this.description,
      length: this.length
    })
  }

  passBack(): void
  {
    console.log("Passing modal-content to modal component!");
    this.activeModal.close(this.voyage);
  }

  onSubmit(formValues) {
    let newVoyage = new VoyageModel(this.voyage);
    newVoyage.name = formValues.title;
    newVoyage.description = formValues.description;
    this.activeModal.close(newVoyage);
  }

  onArchive(formValues) {
    let newVoyage = new VoyageModel(this.voyage);
    newVoyage.name = formValues.title;
    newVoyage.description = formValues.description;
    newVoyage.status = 'archived'
    this.modalService.open(ConfirmModalComponent, newVoyage)
    .then((res) => {
      console.log(res)
      this.activeModal.close(newVoyage);
    })
    .catch((err) => {
      console.error(err)
    })
  }

  isValid(): boolean {
    return this.voyageForm.valid;
  }

  closureCheck(formValues) {
    if (this.formIsDirty() && this.isValid()) {
      // run warning
      if (this.notifications.alertUser() === 'Save') {
        this.onSubmit(formValues)
        return
      }
    }
    // close modal
    this.activeModal.close('Close click')
  }

  formIsDirty(): boolean {
    return this.voyageForm.dirty;
  }
}
