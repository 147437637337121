import { Component, NgZone } from '@angular/core';
import { RouteService } from '../shared';

@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: ['./frequently-asked-questions.component.css']
})
export class FrequentlyAskedQuestionsComponent {

  constructor(private ngZone: NgZone, private routeService: RouteService) { }

  navigateToAboutUs(): void {
    this.ngZone.run(() => {
      this.routeService.openInsideAngularZone(['/about-us'])
    })
  }
}
