import { Component, OnInit } from '@angular/core';
import { UserModel } from '../models';
import { 
  HttpService, 
  UserInfoService,
  RouteService,
  NotificationService 
} from '../shared';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-display-app-settings',
  templateUrl: './display-app-settings.component.html',
  styleUrls: ['./display-app-settings.component.css']
})
export class DisplayAppSettingsComponent implements OnInit {
  postUrl = '...';
  myFormData:FormData;//populated by ngfFormData directive
  file:File;
  uploadPercent:number;
  validComboDrag:any = false
  invalidDrag:any = false
  invalidComboDrag:any = false
  imgURL:any;//= "assets/images/code.jpg" 
  maxSize:any = 2000000;

  public user: UserModel;
  public cancelationPending: boolean = false;
  public activationPending: boolean = false;
  public userLoaded: boolean = false;

  constructor(private userInfoService: UserInfoService,
    private httpService: HttpService,
    private routeService: RouteService,
    private notification: NotificationService) {}
    
  ngOnInit(): void {
    // get the user data of the currently authenticated user
    this.userInfoService.getUser()
    .then((user) => {
      this.user = user
      // console.log('this.user', this.user)
    })
    .catch((err) => {
      this.notification.showError(err.toString(), 'Info retrieval failed!')
      console.error(err)
    })
  }

  public subscribe(): void {
    if (!this.active() && !this.suspended()) {
      // First time subscriber or returning hard cancelled subscriber
      // this.ngZone.run(() => this.router.navigate(['/payment']))
      console.log('calling sendToPaymentPage')
      this.sendToPaymentPage()
    }
    else if (this.suspended()) {
      // Ask the user if they want to reactivate their paused subscription
      // or replace it with a new subscription.
      console.log('calling replacementWarning')
      this.replacementWarning()
    }
  }

  public sendToPaymentPage(): void {
    this.activationPending = true;
    this.routeService.openInsideAngularZone(['/payment'])
  }

  public replacementWarning(): void {
    let res = this.notification.alertSubChange()
    if (res === 'Save') {
      console.log('reactivating')
      this.activationPending = true
      this.reactivateSubscription()
      .then((user) => {
        this.user = user
      })
      .catch((err) => {
        console.error(err)
      })
    }
    else {
      console.log('replacing')
      this.cancelationPending = true
      this.deactivateSubscription()
      .then((user) => {
        this.user = user
        this.sendToPaymentPage()
      })
      .catch((err) => {
        console.error(err)
      })
    }
  }

  public reactivateSubscription(): Promise<UserModel> {
    return new Promise<UserModel>((resolve, reject) => {
      if (!this.suspended()) {
        reject('Reactivation denied: subscription already active!')
      }
      this.activationPending = true;
      this.userInfoService.activateUser(this.user.subscription.id)
      .then((user) => {
        this.activationPending = false;
        console.log('subscription for ' +  this.user.email + ' reactivated.')
        resolve(user)
      })
      .catch((err) => {
        this.cancelationPending = false;
        console.log('reactivation failed for ' +  this.user.email)
        reject(err)
      })
    })
  }

  public softCancel(): void {
    this.suspendSubscription()
    .then((user) => {
      this.user = user
    })
    .catch((err) => {
      console.error(err)
    })
  }

  public suspendSubscription(): Promise<UserModel> {
    return new Promise<UserModel>((resolve, reject) => {
      if (!this.active()) {
        reject('Suspension denied: subscription already inactive!')
      }
      this.cancelationPending = true;
      this.userInfoService.suspendUser()
      .then((user) => {
        this.cancelationPending = false;
        console.log('subscription for ' +  this.user.email + ' suspended.')
        resolve(user)
      })
      .catch((err) => {
        reject(err)
      })
    })
  }

  public hardCancel(): void {
    this.deactivateSubscription()
    .then((user) => {
      this.user = user
    })
    .catch((err) => {
      console.error(err)
    })
  }

  public deactivateSubscription(): Promise<UserModel> {
    return new Promise<UserModel>((resolve, reject) => {
      this.cancelationPending = true;
      this.userInfoService.deactivateUser(this.user.subscription)
      .then((user) => {
        this.cancelationPending = false;
        console.log('subscription for ' +  this.user.email + ' cancelled.')
        resolve(user)
      })
      .catch((err) => {
        this.cancelationPending = false;
        console.log('cancellation failed for ' +  this.user.email)
        reject(err)
      })
    })
  }

  public active(): boolean {
    return this.userInfoService.active()
  }

  public suspended(): boolean {
    return this.userInfoService.suspended()
  }

  uploadFiles(file: File) {
    var reader = new FileReader();
    reader.readAsDataURL(file); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
      let fileUuid = uuidv4();
      this.createAvatar(fileUuid)
      .then((presignedUrl) => {
        return this.uploadAvatar(presignedUrl, fileUuid)
      })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.error(err)
      })
    }
  }

  createAvatar(fileUuid: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      console.log("Attempting to get presigned url...");
      this.notification.showInProgress("Attempting to push avatar to server...", "waiting on response...");
      this.httpService.createAvatar(fileUuid).toPromise()
      .then((res) => {
        console.log(res)
        this.notification.showSuccess("Presigned url created!", "Success!");
        resolve(res)
      })
      .catch((err) => {
        console.log("Error: " + JSON.stringify(err));
        this.notification.showError("Presigned url failed!", "Check console for debug");
        reject(err);
      })
      .finally(() => console.log("done"));
    })
  }

  uploadAvatar(preSignedUrl: string, fileUuid: string): Promise<string>
  {
    return new Promise<string>((resolve, reject) => {
      console.log("Attempting to push avatar to server...");
      console.log(this.file.size);

      this.httpService.uploadAvatar(preSignedUrl, this.file, fileUuid).toPromise()
      .then((res) => {
        console.log(res)
        this.notification.showSuccess("Avatar Uploaded!", "");
        resolve(res);
      })
      .catch((err) => {
        console.log("Error: " + JSON.stringify(err));
        this.notification.showError("Avatar upload failed!", "");
        reject(err);
      })
      .finally(() => console.log("done"));
    })
  }

  printDebug(): void {
    console.log('httpService', this.httpService)
    console.log('notificationService', this.notification)
    console.log('userInfoService', this.userInfoService)
    console.log('routeService', this.routeService)
  }
}
