import { 
  Component, 
  OnInit, 
  Input,
  Output,
  EventEmitter,
  ViewChild, 
  ViewChildren, 
  ElementRef, 
  QueryList, 
  ChangeDetectorRef
} from '@angular/core';
import { Observable } from 'rxjs';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import { MatRipple } from '@angular/material/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { map, startWith, tap } from 'rxjs/operators';
import { ProjectModel, TaskModel } from 'src/app/models';

@Component({
  selector: 'tag-form-control',
  templateUrl: './tag-form-control.component.html',
  styleUrls: ['./tag-form-control.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: TagFormControlComponent
    }
  ]
})
export class TagFormControlComponent implements OnInit, ControlValueAccessor {

  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  // @ViewChild('auto') matAutocomplete: MatAutocomplete;
  public tagInputFormControl: FormControl = new FormControl('');
  @Output() onNewTag = new EventEmitter()
  @Output() onRipple = new EventEmitter()
  @Input() matAutocomplete: MatAutocomplete;
  @Input() tags: string[]
  @Input() projectTags: string[]
  @Input() chipList: MatChipList;
  public filteredTags: Observable<string[]>
  separatorKeysCodes: number[] = [ENTER, COMMA];

  public tag: string

  constructor() {}

  ngOnInit(): void {
    
  }

  add(event: MatChipInputEvent): void {
    if (this.disabled) {
      return
    }

    this.markAsTouched();
    const input = event.input;
    const value = event.value;
    if (value === "") return

    const trimmedValue = value.trim()

    // does not already exist check
    let index = this.tags.indexOf(trimmedValue)
    console.log('index', index)
    if (index === -1) {
      // Add the tag
      this.tags.push(trimmedValue);
      
      // inform the parent form
      // this.onNewTag.emit(trimmedValue)
      this.onChange(this.tags)
    }
    else {
      this.onRipple.emit(index)
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.tagInputFormControl.setValue('');
    
  }

  displayAutoComplete() {
    this.trigger.openPanel()
  }

  //#region ControlValueAccessor Interface
  onChange = (quantity) => { console.log('changed!') };

  onTouched = () => { 
    console.log('touched!')
    this.trigger.openPanel()
  };

  touched = false;
  disabled = false;

  writeValue(obj: any): void {
    console.log('writeValue', `${obj}`)
    console.log('this.tag', this.tag)
    if (this.tag && obj !== null && obj !== "") {
      console.log('pushing')
      this.onNewTag.emit(`${obj}`)
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    console.log('before touched: ', this.touched)
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      console.log('after touched: ', this.touched)
    }
  }

  markAsUntouched() {
    console.log('before untouched: ', this.touched)
    if (this.touched) {
      this.touched = false;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
  //#endregion
}
