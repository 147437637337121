import { QuestionBase } from "./question/question-base.model";
import { UserModel } from "./UserModel";

export class TaskModel 
{
  uuid: string;
  viewId?: string;
  title: string;
  description: string;
  status: string;
  creationDate?: Date;
  lastUpdateTime?: Date;
  assignee: string;
  sharkRank: string;
  sharkClassification: string;
  latestUpdate: string;
  changedBy: string;
  history?: any;
  tags: string[] = [];
  questions: QuestionBase<string>[] = [];
  // tags = {'regular': ['v1', 'test', ...], 'questions': {key1: QuestionBase1, key2: QuestionBase2 }}

  constructor(obj: any = null)
	{
		if(obj != null)
		{
      Object.assign(this, obj);
		}
  }
  
  toLocaleLowerCase(): TaskModel {
    return new TaskModel({
      viewId: this.viewId?.toLocaleLowerCase(),
      title: this.title.toLocaleLowerCase(),
      uuid: this.uuid,
      creationDate: this?.creationDate,
      lastUpdateTime: this?.lastUpdateTime,
      status: this.status.toLocaleLowerCase(),
      sharkRank: this.sharkRank.toLocaleLowerCase(),
      description: this.description.toLocaleLowerCase(),
      assignee: this.assignee,
      sharkClassification: this.sharkClassification?.toLocaleLowerCase(),
      latestUpdate: this.latestUpdate?.toLocaleLowerCase(),
      changedBy: this.changedBy?.toLocaleLowerCase(),
      tags: this.toLocaleLowerCases(this.tags)
    })
  }

  includes(searchTerm): boolean {
    if (this.title.includes(searchTerm))
      return true
    if (this.status.includes(searchTerm))
      return true
    if (this.description.includes(searchTerm))
      return true
    if (this.sharkRank.includes(searchTerm))
      return true
    if (this.sharkClassification?.includes(searchTerm))
      return true
    if (this.assignee.includes(searchTerm))
      return true
    if (this?.viewId?.includes(searchTerm))
      return true
    if (this?.creationDate?.toString().includes(searchTerm))
      return true
    if (this?.lastUpdateTime?.toString().includes(searchTerm))
      return true
    if (this?.tags.includes(searchTerm))
      return true
    return false
  }

  toLocaleLowerCases(strings: string[]) {
    let lowerCases: string[] = []
    strings.forEach((element) => {
      lowerCases.push(element.toLocaleLowerCase())
    })
    return lowerCases
  }
  
  mergeFormValues(formValues: any): void {
    this.title = formValues?.title;
    this.assignee = formValues?.assignee;
    this.description = formValues?.description;
    this.sharkRank = formValues?.sharkRank;
    this.sharkClassification = formValues?.sharkClassification;
    this.tags = formValues?.tagUpdater;
    this.questions = this.createQuestions(formValues)
  }

  createQuestions(formValues): QuestionBase<string>[] {
    if (!formValues || !formValues.questionGroup) return []
    let questions: QuestionBase<string>[] = []
    let keyQuestionMap: {
      [key: string]: QuestionBase<string>
    }
    let questionGroup: {
      [key: string]: string
    }
    // returns a search-efficient data structure
    keyQuestionMap = this.getQuestionMap()
    console.log('keyQuestionMap', keyQuestionMap)
    questionGroup = formValues.questionGroup
    console.log('questionGroup', questionGroup)
    Object.keys(questionGroup).forEach((key: string) => {
      const lowerCaseKey = key.toLocaleLowerCase()
      keyQuestionMap[lowerCaseKey].value = questionGroup[key]
    });
    // flatten the map
    for (let key in keyQuestionMap) {
      questions.push(keyQuestionMap[key])
    }
  
    return questions
  }

  getQuestionMap(): {[key: string]: QuestionBase<string>} {
    let map: {[key: string]: QuestionBase<string>} = {}
    const length = this.questions.length
    for (let index = 0; index < length; index++) {
      map[this.questions[index].key] = this.questions[index]
    }
    return map
  }

  mergeIncompleteTask(partialTask: TaskModel): void {
    this.uuid = partialTask.uuid;
    this.viewId = partialTask.viewId;
    this.creationDate = partialTask.creationDate;
    this.lastUpdateTime = partialTask.creationDate;
    this.latestUpdate = partialTask.latestUpdate;
    this.changedBy = partialTask.changedBy;
  }
}