import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ProjectModel, SubscriptionModel, TaskModel, UserModel, VoyageModel } from '../../models';

@Injectable()
export abstract class HttpService {


  constructor(public httpClient: HttpClient) {}

  //#region PROJECTS
  abstract pullProjects(userParam?: string): Observable<ProjectModel[]>
  abstract pushProject(project: ProjectModel): Observable<ProjectModel>
  abstract updateProject(project: ProjectModel): Observable<any>
  //#endregion

  //#region TASKS
  abstract pullTasks(projectParam: string): Observable<TaskModel[]>
  abstract pullTasksLazy(project: ProjectModel, numberOfTasks:number, lastKey:string): Observable<TaskModel[]>
  abstract pushTask(task: TaskModel, project: ProjectModel): Observable<any>
  abstract updateTask(task: TaskModel, project: ProjectModel): Observable<TaskModel>
  //#endregion

  //#region USERS
  abstract pullUser(): Observable<UserModel>
  abstract pushEmailInvite(email: string, projectUuid: string): Observable<Object>
  abstract pushPromotion(email: string, projectUuid: string): Observable<Object>
  abstract createSubscription(user: UserModel): Observable<SubscriptionModel>
  abstract pullSubscription(user: UserModel): Observable<SubscriptionModel>
  abstract updateUserSubscription(subscription: SubscriptionModel): Observable<UserModel>
  abstract softCancelSubscription(user: UserModel): Observable<SubscriptionModel>
  abstract hardCancelSubscription(user: UserModel): Observable<SubscriptionModel>
  //#endregion

  //#region TRIBE
  abstract pullTribe(projectUuid: string): Observable<UserModel[]>
  //#endregion

  //#region VOYAGE
  abstract pushVoyage(projectParam: string, voyage: VoyageModel)
  abstract updateVoyage(projectParam: string, voyage: VoyageModel)
  //#endregion

  //#region Avatar
  abstract getAvatar(avatarUsername: string)
  abstract createAvatar(fileUuid: string): Observable<any>
  abstract uploadAvatar(preSignedUrl: string, file:File, fileUuid: string): Observable<any>
  ////#endregion

  //#region COOKIE
  // abstract getCookie(): Observable<any>
  //#endregion

  //#region ERROR
  handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    }
  }
  //#endregion
}