import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProjectModel, VoyageModel } from 'src/app/models';

@Component({
  selector: 'voyage-thumbnail',
  templateUrl: './voyage-thumbnail.component.html',
  styleUrls: ['./voyage-thumbnail.component.css']
})
export class VoyageThumbnailComponent {
  
  @Input() voyage: VoyageModel
  @Input() project: ProjectModel
  @Output() messageEvent: EventEmitter<VoyageModel> = new EventEmitter()
  @Output() archiveEvent: EventEmitter<VoyageModel> = new EventEmitter()

  pass($event): void {
    this.messageEvent.emit($event)
  }

  passArchive($event): void {
    this.archiveEvent.emit($event)
  }
}
