<mat-expansion-panel *ngIf="project.voyageEnabled">
  <mat-expansion-panel-header>
      <voyage-progress-bar [project]="project"></voyage-progress-bar>
  </mat-expansion-panel-header>

  <div class="voyage-container" *ngIf="project.voyageEnabled">

    <div cdkDropList [cdkDropListData]="activeVoyages" 
        #active="cdkDropList"
        class="voyage-list" 
        [cdkDropListConnectedTo]="[upcoming]"
        (cdkDropListDropped)="dropIntoActive($event)"
        [attr.index]="0">
      <h1>Active Voyage</h1>
      <div class="voyage-list-item" *ngFor="let voyage of activeVoyages" [cdkDragData]="voyage" cdkDrag>
        {{voyage.name | truncate: 20}}
        <voyage-details-button [voyage]="voyage" [project]="project"
          (messageEvent)="onUpdate($event)"
          (archiveEvent)="onArchive($event, voyage)">
          
        </voyage-details-button>
      </div>
    </div>
  
    <div cdkDropList [cdkDropListData]="upcomingVoyages" 
        #upcoming="cdkDropList"
        class="voyage-list"
        [cdkDropListConnectedTo]="[active]"
        (cdkDropListDropped)="dropIntoInactive($event)"
        [attr.index]="1">
      <h1>Upcoming Voyages</h1>
      <div class="voyage-list-item" *ngFor="let voyage of upcomingVoyages" [cdkDragData]="voyage" cdkDrag>
        {{voyage.name | truncate: 20}}
        <voyage-details-button [voyage]="voyage" [project]="project"
          (messageEvent)="onUpdate($event)"
          (archiveEvent)="onArchive($event, voyage)">
          
        </voyage-details-button>
      </div>
    </div>
    
    <button class="btn btn-primary" id="voyage-view" (click)="openVoyageView(project)">
      <span aria-label="Open Voyages" class="glyphicon glyphicon-tasks"></span>
    </button>
  </div>

</mat-expansion-panel>
