import { Pipe, PipeTransform } from '@angular/core';
import { UserModel } from 'src/app/models';

@Pipe({
  name: 'tribeFilter'
})
export class TribeFilterPipe implements PipeTransform {

  transform(tribe: UserModel[], filterBy: string[]): UserModel[] {
    if (!tribe)
    {
      return [];
    }
    if (!filterBy) {
      return tribe;
    }

    return tribe.filter(user => {
      return filterBy.includes(user.email);
    })
  }

}
