<div class="tag-container" [ngClass]="{'error': hasError()}">
  <mat-form-field appearance="fill">
    <mat-label>Tags</mat-label>
    <mat-chip-list #chipList>
      <div *ngFor="let tag of visibleTags">
        <mat-chip matRipple
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(tag)">
          {{tag}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
      </div>
      <input
        type="text"
        matInput
        placeholder="New tag..."
        #tagInput
        [formControl]="tagCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
        (blur)="onTouched()"
        (click)="displayAutoComplete()">
        <mat-error *ngIf="tagCtrl.invalid">
          {{getErrorMessage()}}
        </mat-error>
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
        {{tag}}
      </mat-option>
    </mat-autocomplete>
    
  </mat-form-field>  
</div>