export class VoyageModel
{
  uuid: string;
	name: string;
  description: string;
  status?: string; // "archived, upcoming, future"
  viewId?: string;

  startTime?: string;
  endTime?: string;
  lastUpdateTime?: string;
  lengthInWeeks: number;

	constructor(obj: any = null)
	{
		if(obj != null)
		{
			Object.assign(this, obj);
		}
	}
}