export class Parser {
  //#region SHARED HELPERS
  static getNumber(data: object, k: string): number {
    return k in data ? this.filterInt(data[k]) : 0
  }

  static filterInt(num: any): number {
    if (this.notNull(num)) {
      const parsed = parseInt(num)
      return !isNaN(parsed) ? parsed : 0
    }
    return 0
  }

  static getString(data: object, k: string): string {
    return k in data ? this.filterString(data[k]) : ""
  }

  static getList(data: object, k: string): string[] {
    return k in data ? this.filterList(data[k]): []
  }

  static getObj(data: object, k: string): object {
    return k in data ? this.filterObj(data[k]): {}
  }

  static filterObj(obj: any): object {
    try {
      switch(typeof(obj)) {
        case 'string':
          return JSON.parse(obj)
        case 'object':
          return obj
        default:
          let errorMsg = 'Payload data type \'' + typeof(obj) + '\' unrecognized!'
          throw new Error(errorMsg)
      }
    }
    catch(e) {
      throw e
    }
  }

  static filterList(list: any): string[] {
    try {
      switch (typeof(list)) {
        case 'string':
          const tags = this.getEvalReplacement(list)
          return this.getEvalReplacement(list).filter((tag) => {
            return tag !== ""
          })
        case 'object':
          return this.getArray(list)
        default:
          let errorMsg = 'Payload data type ' + typeof(list) + ' unrecognized!'
          throw new Error(errorMsg)
      }
    }
    catch(e) {
      throw e
    }
  }

  static filterString(str: any): string {
    return this.notNull(str) ? str : ""
  }

  static getEvalReplacement(input: string): string[] {
    // Heavy Replacement Strategy.
    // const input = `['la maison', "l'animal"]` // Input.
    try {
      const array = input
        .replace(/^\[|\]$/g, '') // Remove leading and ending square brackets ([]).
        .split(',') // Split by comma.
        .map((phrase) => // Iterate over each phrase.
          phrase.trim() // Remove leading and ending whitespace.
          .replace(/"/g, '') // Remove all double quotes (").
          .replace(/^\'|\'$/g, '') // Remove leading and ending single quotes (').
        )
      // console.log(array) // Proof.
      return array
    }
    catch(e) {
      throw e
    }
  }

  static getArray(input: any): string[] {
    if (Array.isArray(input)) {
      return input as string[]
    }
    console.error('input: not an array!')
    return []
  }

  static getListFromString(input: string): string[] {
    // String.prototype.replace() + JSON.parse() Strategy.
    console.log(input)
    let replacement = input.replace(/'/g, '"')
    console.log(replacement)
    const array = JSON.parse(replacement) // Array.
    console.log(array) // Proof.
    return array
  }
  //#endregion
  
  static clone(obj): Object {
    if(obj == null || typeof(obj) != 'object')
        return obj;

    var temp = new obj.constructor(); 
    for(var key in obj)
        temp[key] = this.clone(obj[key]);

    return temp;
  }

  static notNull(value: any): boolean {
    return value !== null && value !== undefined
  }
}