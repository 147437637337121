import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tagFilter'
})
export class TagPipe implements PipeTransform {

  transform(tags: string[], omissions: string[]): string[] {
    if (!tags)
    {
      return [];
    }
    return tags.filter(tag => {
      return 
    })
  }

}
