import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from './../http/http.service';
import { SubscriptionModel, UserModel } from './../../models';
import { AuthService } from './../auth/auth.service';
import http from './../http/utilities/http-parse-utility'
import util from './../services/utilities/user-parser'


@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  public user: UserModel;
  public presignedUrl: string;
  public onLogin = new Subject();
  public onLogout = new Subject();
  public onRetrieval = new Subject<UserModel>();
  public onActivateUser = new Subject();
  public retrievingUser: boolean = false;

  constructor(private auth: AuthService, private httpService: HttpService)
  {
    this.onLogin.subscribe((value) => {
      this.retrievingUser = true;
      this.getUser()
      .then((userInfo) => {
        console.log('userInfo', userInfo)
        this.retrievingUser = false;
        this.onRetrieval.next(userInfo);
      })
    });

    this.onLogout.subscribe({
      next: ((value) => {
        this.user = undefined;
        this.presignedUrl = undefined;
      })
    })

  }

  getUser(): Promise<UserModel> {
    return new Promise<UserModel>((resolve, reject) => {
      if (!this.auth.isAuthenticated()) {
        reject('Data retrieval denied: authentication no longer valid!')
        return
      }
      if (this.user !== undefined) {
        resolve(this.user)
        return
      }
      this.retrievingUser = true;
      this.httpService.pullUser().toPromise()
      .then((res) => {
        this.user = this.getUserHandler(res);
        console.log(this.user)
        this.retrievingUser = false;
        this.onRetrieval.next(this.user)
        resolve(this.user)
      })
      .catch((err) => {
        reject(err)
      })
    })
  }

  getSubscription(subscriptionId: string): Promise<SubscriptionModel> {
    return new Promise<SubscriptionModel>((resolve, reject) => {
      this.httpService.pullSubscription(this.user).toPromise()
      .then((subscription) => {
        resolve(subscription)
      })
      .catch((err) => {
        reject(err)
      })
    })
  }

  public async updateUser(subscription: SubscriptionModel): Promise<UserModel> {
    return new Promise<UserModel>((resolve, reject) => {
      this.httpService.updateUserSubscription(subscription).toPromise()
      .then((res) => {
        console.log(res)
        this.user.subscription = new SubscriptionModel(subscription);
        // if (subscription.status === "ACTIVE") {
        //   this.notifications.showSuccess("SharkTracker is yours!", "Subscription Activated.")
        // }
        // else {
        //   this.notifications.showSuccess("We'll miss you!", "Subscription deactivated.")
        // }
        resolve(this.user)
      })
      .catch((err) => {
        console.error(err)
        // this.notifications.showError("Subscription failed!", err)
        reject(err)
      })
    })
  }

  public async activateUser(subscriptionId: string): Promise<UserModel> {
    if (this.user === undefined) {
      await this.getUser()
    }
    return new Promise<UserModel>((resolve, reject) => {
      this.user.subscription = new SubscriptionModel({id: subscriptionId});
      this.httpService.createSubscription(this.user).toPromise()
      .then((res) => {
        console.log(res)
        this.user.subscription = this.getSubscriptionHandler(res);
        // this.notifications.showSuccess("SharkTracker is yours!", "Subscription Activated.")
        resolve(this.user)
      })
      .catch((err) => {
        console.error(err)
        // this.notifications.showError("Subscription failed!", err)
        reject(err)
      })
    })
  }

  public async suspendUser(): Promise<UserModel> {
    if (this.user === undefined) {
      await this.getUser()
    }
    return new Promise<UserModel>((resolve, reject) => {
      this.httpService.softCancelSubscription(this.user).toPromise()
      .then((res) => {
        console.log(res)
        this.user.subscription = this.getSubscriptionHandler(res);
        // this.notifications.showSuccess("Subscription request received!", "Subscription paused.")
        resolve(this.user)
      })
      .catch((err) => {
        console.error(err)
        // this.notifications.showError("Subscription change failed!", err)
        reject(err)
      })
    })
  }

  public async deactivateUser(subscription: SubscriptionModel): Promise<UserModel> {
    if (this.user === undefined) {
      await this.getUser()
    }
    return new Promise<UserModel>((resolve, reject) => {
      this.httpService.hardCancelSubscription(this.user).toPromise()
        .then((res) => {
          console.log(res)
          this.user.subscription = this.getSubscriptionHandler(res)
          // this.notifications.showSuccess("We'll miss you!", "Subscription deactivated!")
          resolve(this.user)
        })
        .catch((err) => {
          console.error(err)
          // this.notifications.showError("Subscription deactivation failed!", err)
          reject(err)
        })
    })
  }

  private getUserHandler(res: any): UserModel {
    let payload = http.getPayload(res)
    console.log('payload', payload)
    return util.parseUser(payload)    
  }

  private getSubscriptionHandler(res: any): SubscriptionModel {
    console.log(res);
    let jsonBody = res;
    if ('data' in jsonBody) {
      jsonBody = jsonBody.data
    }
    if ('Item' in jsonBody) {
      jsonBody = jsonBody.Item
    }
    return new SubscriptionModel(jsonBody)
  }

  public active(): boolean {
    // console.log('userInfoService checking subscription...')
    if (!this.user) return false;
    if (!this.user.subscription) return false;
    // console.log(this.user.subscription.status)
    return this.user.subscription.status === "ACTIVE"
  }

  public suspended(): boolean {
    if (!this.user) return false;
    if (!this.user.subscription) return false;
    return this.user.subscription.status === "SUSPENDED"
  }

  public authenticated(): boolean {
    return this.auth.isAuthenticated()
  }

  ngOnDestroy() {
    this.onLogin.unsubscribe();
    this.onLogout.unsubscribe();
    this.onRetrieval.unsubscribe();
    this.onActivateUser.unsubscribe();
  }
}