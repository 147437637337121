import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'ng-sidebar';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { Error404Component } from './errors/404.component'
import { AppComponent } from './app.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule, MAT_CHIPS_DEFAULT_OPTIONS} from '@angular/material/chips';
import {MatRippleModule} from '@angular/material/core';
//#region SHARED
import {
  AuthService,
} from './shared/auth'
import {
  ProjectService,
  TaskService,
  TribeService,
  UserInfoService,
  ModalService,
  NotificationService
} from './shared/services/'
import {
  RouteService,
  AuthenticationGuardService,
  AuthorizationGuardService
} from './shared/routing/'
import {
  HttpService,
  HttpCloudService,
  RestAPIService,
} from './shared/http/'
import {
  TaskDetailsButtonComponent,
  CreateProjectButtonComponent,
  CreateTaskButtonComponent,
  CreateVoyageButtonComponent,
  VoyageDetailsButtonComponent,
  OpenTaskTableButtonComponent,
} from './shared/buttons'
import {
  CreateProjectModalContent,
  CreateTaskModalContent,
  UpdateTaskModalContent,
  ConfirmReplacementModalComponent,
} from './shared/modals'
import 
{ 
  VoyageProgressBarComponent,
  WarningModalComponent,
  MatSliderComponent,
  httpInterceptorProviders,
  TribeFilterPipe,
  TruncateFilterPipe,
  TaskFilterPipe,
  TagBuilderComponent,
  TagUpdaterComponent,
  CreateVoyageModalContent,
  UpdateVoyageModalContent,
  TagFormControlComponent,
} 
from './shared';
//#endregion
import {
  DisplayUserTasksComponent,
  KanbanBoardComponent,
  TaskThumbnailComponent 
} 
from './display-user-tasks/index';
import { InputUserDataFormComponent } from './input-user-data-form/index';
import { DisplayUserProjectsComponent } from './display-user-projects/index';
import { DisplayUserTaskTableComponent } from './display-user-task-table/display-user-task-table.component';
import { DisplayProjectSettingsComponent } from './display-project-settings/display-project-settings.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { NavbarComponent } from './navbar/navbar/navbar.component';
import { DisplayAppSettingsComponent } from './display-app-settings/display-app-settings.component';
import { DisplayUserVoyagesComponent } from './display-user-voyages/display-user-voyages.component';
import { VoyageWellComponent } from './display-user-projects/voyage-well/voyage-well.component';
import { VoyageThumbnailComponent } from './display-user-voyages/voyage-thumbnail/voyage-thumbnail.component';
import { FrequentlyAskedQuestionsComponent } from './frequently-asked-questions/frequently-asked-questions.component';
import { NavSidebarComponent } from './navbar/nav-sidebar/nav-sidebar.component';
import { PaymentPageComponent } from './payment-page/payment-page.component';
import { ngfModule } from 'angular-file';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
/* Configure Amplify resources */
Amplify.configure(awsconfig);

/* Keycode mapping */
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { DynamicFormQuestionComponent } from './models/question/dynamic-form-question/dynamic-form-question.component';
import { DynamicFormComponent } from './shared/dynamic-form/dynamic-form.component';
import { QuestionControlService } from './models/question/question-control.service';
import { TagPipe } from './shared/tags/tag.pipe';

/* Auto-imports */


@NgModule({
  declarations: [
    AppComponent,
    Error404Component,
    InputUserDataFormComponent,
    DisplayUserProjectsComponent,
    CreateProjectModalContent,
    CreateTaskModalContent,
    CreateVoyageModalContent,
    UpdateTaskModalContent,
    TaskThumbnailComponent,
    TaskDetailsButtonComponent,
    DisplayUserTasksComponent,
    KanbanBoardComponent,
    CreateTaskButtonComponent,
    CreateProjectButtonComponent,
    OpenTaskTableButtonComponent,
    DisplayUserTaskTableComponent,
    TaskFilterPipe,
    DisplayProjectSettingsComponent,
    LandingPageComponent,
    LoginPageComponent,
    NavbarComponent,
    DisplayAppSettingsComponent,
    VoyageProgressBarComponent,
    VoyageWellComponent,
    UpdateVoyageModalContent,
    CreateVoyageModalContent,
    CreateVoyageButtonComponent,
    WarningModalComponent,
    ConfirmReplacementModalComponent,
    VoyageThumbnailComponent,
    NavSidebarComponent,
    MatSliderComponent,
    PaymentPageComponent,
    FrequentlyAskedQuestionsComponent,
    AboutUsComponent,
    ContactUsComponent,
    DisplayUserVoyagesComponent,
    TruncateFilterPipe,
    VoyageDetailsButtonComponent,
    TribeFilterPipe,
    TagBuilderComponent,
    TagUpdaterComponent,
    TagFormControlComponent,
    DynamicFormQuestionComponent,
    DynamicFormComponent,
    TagPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DragDropModule,
	  ToastrModule.forRoot(
      {
        maxOpened:4,
        timeOut:4000
      }
    ),
    SidebarModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    MatSelectModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatChipsModule,
    MatRippleModule,
    MatAutocompleteModule,
    AmplifyUIAngularModule,
    ngfModule
  ],
  exports: [
    MatSelectModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule
  ],
  providers: [
    KanbanBoardComponent, 
    ModalService,
    NotificationService, 
    NgbActiveModal, 
    ProjectService,
    TaskService,
    TribeService,
    UserInfoService,
    AuthService,
    RestAPIService,
    RouteService,
    AuthenticationGuardService,
    AuthorizationGuardService,
    QuestionControlService,
    { 
      provide: HttpService, 
      useClass: HttpCloudService 
    },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA]
      }
    },
    httpInterceptorProviders,
    Document,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
