import { Injectable } from '@angular/core';
import { RestAPI } from '@aws-amplify/api-rest';

@Injectable({
  providedIn: 'root'
})
export class RestAPIService {

  constructor() { }

  get(apiName: string, path: string, init: any): Promise<any> {
    return new Promise((resolve, reject) => {
      RestAPI.get(apiName, path, init)
      .then((res) => {
        console.log(res)
        resolve(res);
      })
      .catch((err) => {
        console.log(err)
        reject(err);
      })
    })
  }

  put(apiName: string, path: string, init: any): Promise<any> {
    return new Promise((resolve, reject) => {
      RestAPI.put(apiName, path, init)
      .then((res) => {
        console.log(res)
        resolve(res);
      })
      .catch((err) => {
        console.log(err)
        reject(err);
      })
    })
  }

  patch(apiName: string, path: string, init: any): Promise<any> {
    return new Promise((resolve, reject) => {
      RestAPI.patch(apiName, path, init)
      .then((res) => {
        console.log(res)
        resolve(res);
      })
      .catch((err) => {
        console.log(err)
        reject(err);
      })
    })
  }

  post(apiName: string, path: string, init: any): Promise<any> {
    return new Promise((resolve, reject) => {
      RestAPI.post(apiName, path, init)
      .then((res) => {
        console.log(res)
        resolve(res);
      })
      .catch((err) => {
        console.log(err)
        reject(err);
      })
    })
  }
}
