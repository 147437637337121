import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import axios from "axios";


@Injectable()
export class NoopInterceptor implements HttpInterceptor {

  constructor() { 
    axios.interceptors.request.use(undefined, (request) => {
      console.log('Intercepted!')
      console.log(request)
      return request
    })
    axios.interceptors.response.use(undefined, (response) => {
      console.log('Intercepted!')
      console.log(response)
      return response
    })
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(req)
    return next.handle(req);
  }
}
