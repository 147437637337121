import { SubscriptionModel } from "src/app/models";
import { Parser } from './parser-utility'

export default class SubscriptionParser extends Parser {
  
  static parseSubscription(payload: Object) {
    try {
      return new SubscriptionModel({
        id: this.getId(payload),
        status: this.getStatus(payload), 
        start_time: this.getStartTime(payload),
        status_update_time: this.getUpdateTime(payload),
        plan_id: this.getPlanId(payload),
        billing_info: this.getBillingInfo(payload),
        facilitatorAccessToken: this.getFacilitatorAccessToken(payload),
        name: this.getName(payload),
        description: this.getDescription(payload),
        rate: this.getRate(payload),
        startDate: this.getStartDate(payload),
        endDate: this.getEndDate(payload)
      })
    }
    catch(e) {
      throw e
    }
  }

  static getEndDate(payload: Object) {
    const key: string = "endDate"
    return this.getString(payload, key)
  }
  
  static getStartDate(payload: Object) {
    const key: string = "startDate"
    return this.getString(payload, key)
  }

  static getDescription(payload: Object) {
    const key: string = "description"
    return this.getString(payload, key)
  }

  static getName(payload: Object) {
    const key: string = "name"
    return this.getString(payload, key)
  }

  static getRate(payload: Object) {
    const key: string = "rate"
    return this.getString(payload, key)
  }

  static getFacilitatorAccessToken(payload: Object) {
    const key: string = "facilitatorAccessToken"
    return this.getString(payload, key)
  }

  static getBillingInfo(payload: Object): object {
    const key: string = "billing_info"
    let billingInfo: object = this.getObj(payload, key)
    if (Object.keys(billingInfo).length === 0) {
      billingInfo['last_payment'] = {
        amount: 'N/A'
      }
    }
    return billingInfo
  }

  static getPlanId(payload: Object) {
    const key: string = "plan_id"
    return this.getString(payload, key)
  }

  static getUpdateTime(payload: Object) {
    const key: string = "status_update_time"
    return this.getString(payload, key)
  }

  static getStartTime(payload: Object) {
    const key: string = "start_time"
    return this.getString(payload, key)
  }

  static getId(payload: Object) {
    const key: string = "id"
    return this.getString(payload, key)
  }

  static getStatus(payload: Object) {
    const key: string = "status"
    return this.getString(payload, key)
  }

}