<div class="landing-page">
  <mat-grid-list cols="12" rowHeight="50px">
    <mat-grid-tile class="dark" [colspan]=12 [rowspan]=3>
      <div class="tile-body">
        <h1 class="tile-header">Enhance your work</h1>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]=6 [rowspan]=8>
      <div class="tile-body">
        <h1 class="tile-header">
          Brave the deeps
        </h1>
        <p class="tile-text">
          Sharktracker helps you better manage your projects. <br>
          Whether you're sailing on a small team, or running your own race, <br>
          Sharktracker will help keep your project afloat.
        </p>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]=6 [rowspan]=8>
      <img class="tile-img" src="assets/images/cropped-task-board.png">
    </mat-grid-tile>
    <mat-grid-tile class="dark" [colspan]=12 [rowspan]=3>
      <div class="tile-body">
        <h1 class="tile-header">Enjoy your work</h1>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]=7 [rowspan]=4>
      <img class="tile-img top-offset" src="assets/images/cropped-st-projects-capture.png">
    </mat-grid-tile>
    <mat-grid-tile [colspan]=5 [rowspan]=4>
      <div class="tile-body">
        <h1 class="tile-header">
          Manage your journey
        </h1>
        <p class="tile-text">
          Quick and easy access to all of your 
          projects and teams
        </p>
      </div>
    </mat-grid-tile>
    <mat-grid-tile class="dark" [colspan]=12 [rowspan]=3>
      <div class="tile-body">
        <h1 class="tile-header">
          Equip yourself
        </h1>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]=6 [rowspan]=6>
      <div class="tile-body">
        <h1 class="tile-header">
          Utilize best-fit
        </h1>
        <p class="tile-text">
          Customize your features to meet all of your project and development needs
        </p>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]=6 [rowspan]=6>
      <img class="tile-img top-offset" src="assets/images/cropped-project-settings-page.png" alt="">
    </mat-grid-tile>
    <mat-grid-tile class="dark" [colspan]=12 [rowspan]=3>
      <div class="tile-body">
        <h1 class="tile-header">
          Hold fast!
        </h1>
        <p class="title-text">
          More capabilities coming soon...
        </p>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="background-container">
  </div>
  <footer class="page-footer headerColor pt-4">

    <div class="footer-container container-fluid text-center text-md-center">
      
      <div class="row">

        <div class="col-md-3 mt-md-0 mt-3">
          <div>
            <a (click)="openAboutUs()">
              About
            </a>
          </div>
        </div>

        <div class="col-md-2 mt-md-0 mt-3">
          <div>
            <a (click)="openFaqs()">
              FAQ
            </a>
          </div>
        </div>
        <div class="col-md-2 mb-md-0 mb-3">
          <div>
            <a (click)="openContactUs()">
              Contact Us
            </a>
          </div>
        </div>

        <div class="col-md-2 mb-md-0 mb-3">
          <div>
            <a href="legal">
              Legal
            </a>          
          </div>
        </div>

        <div class="col-md-3 mb-md-0 mb-3">
          <div>
            <a href="privacy">
              Privacy
            </a>
          </div>
        </div>

      </div>

    </div>
    <div class="footer-copyright text-center py3">
      &#8482; 2021: SharkRaft Technologies LLC
    </div>

  </footer>

</div>

