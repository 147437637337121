import { Component, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { CreateProjectModalContent } from '../../modals/create-project-modal-content'
import { ProjectModel } from '../../../models';

// ModalComponent class
@Component({
  selector: 'create-project-button',
  templateUrl: './create-project-button.component.html'
})
export class CreateProjectButtonComponent {
  @Output() messageEvent = new EventEmitter<string>();
  
  constructor(private modalService: ModalService) {}

  private project: ProjectModel = new ProjectModel();

  ngOnInit() {

  }

  openModal():void {
    console.log('User creating project...')
    this.modalService.openProjectModal(CreateProjectModalContent, this.project)
    .then((result) => {
      console.log('Result: ' + result);
      // pass data to display-user-projects component
      console.log("Changes saved!");
      this.messageEvent.emit(result);
    })
    .catch((result) => {
      // pass data to display-user-projects component
      console.log("Changes saved!");
      this.messageEvent.emit(result);
    })
    .finally(() => {
      // clear project data to be ready for next assignment
      this.project = new ProjectModel({})
    });
  }

}
