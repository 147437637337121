<h1 class="d-flex justify-content-center">Contact Us</h1>

<div class="contact-container">
  <p class="item">
    To get in touch with our development team, email us at:
  </p>
  <p style="font-weight: 600" class="d-flex item">
    info@thesharktracker.com
  </p>
  <p class="item">
    Feel free to send feedback, bug reports, and anything else you notice.
    We're a mix of US Eastern and Central time zones, so if you live close by, 
    you'll probably hear from us during normal business hours. 
  </p>
  <hr>
  <p class="item">
    For specific legal questions, please also email us at 
  </p>
  <p style="font-weight: 600" class="d-flex item">
    info@thesharktracker.com
  </p>
  <p class="item">
    We can then put you in touch with our legal council.
  </p>
</div>