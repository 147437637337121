import { Injectable } from '@angular/core';
import { UserModel, ProjectModel, VoyageModel } from '../../models';
import { NotificationService } from './notification.service';
import { HttpService } from './../http/http.service';
import { EventEmitter } from '@angular/core';
import { AuthService } from './../auth/auth.service';
import { UserInfoService } from './user-info.service';
import { TribeService } from './tribe.service';
import util from './../http/utilities/http-parse-utility'
import ProjectParser from './utilities/project-parser';
import VoyageParser from './utilities/voyage-parser'

export type UpdateType = 'activate' | 'deactivate' | 'none'

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  projects: ProjectModel[];
  projectCreated: EventEmitter<ProjectModel> = new EventEmitter();

  constructor(private httpService: HttpService,
    private notification: NotificationService,
    private auth: AuthService,
    private userinfo: UserInfoService) {
      this.auth.userLoggedOut.subscribe(() => {
        this.projects = undefined;
      })
  }

  //#region ############ PROJECT ############
  public async getProjects(fromServer: boolean = false): Promise<ProjectModel[]> {
    return new Promise<ProjectModel[]>((resolve, reject) => {
      if (this.projects && !fromServer) {
        console.log("get projects from cache!");
        resolve(this.projects);
      }
      else {
        // call the api
        console.log("get projects from api");
        this.httpService.pullProjects(this.userinfo.user.uuid).toPromise()
        .then((res) => {
          this.projects = this.getProjectsResponseHandler(res);
          console.log(this.projects)
          resolve(this.projects);
        })
        .catch((err) => {
          console.log('Error: ' + JSON.stringify(err));
          reject(err);
        })
      }
    })
  }

  private getProjectsResponseHandler(res: any): ProjectModel[]
  {
    let incomingProjects: ProjectModel[] = [];
    // itemArr = [{...}, {...}, ...]
    let itemArr = util.getPayload(res)

    itemArr.forEach(element => {
      incomingProjects.push(ProjectParser.parseProject(element))
    });
    return incomingProjects;
  }

  public getProject(projectUuid: string): ProjectModel {
    if (!this.projects) {
      return undefined
    } 
    let index = this.projects.findIndex((project) => {
      return project.uuid === projectUuid;
    })
    let project = new ProjectModel(this.projects[index])
    return project
  }

  public pushProject(project: ProjectModel): Promise<ProjectModel>
  {
    return new Promise<ProjectModel>((resolve, reject) => {
      console.log("Attempting to push project to server...");
      this.notification.showInProgress("Setting up project " + project.name , "waiting on response...")
      this.httpService.pushProject(project).toPromise()
      .then((res) => {
        project = this.pushProjectResponseHandler(project, res);
        if (project.uuid) {
          this.notification.showSuccess("Project created!", project.name);
          // add new project to the service
          this.projects.push(project);
          resolve(project);
        }
        else {
          this.notification.showError("Project limit reached!", "Server Exception")
          reject('Project Limit Reached!')
        }
      })
      .catch((err) => {
        console.log("Error: " + JSON.stringify(err));
        this.notification.showError("Project creation failed!", project.name);
        reject(err);
      })
      .finally(() => console.log("done"));
    })
  }

  private pushProjectResponseHandler(project: ProjectModel, res: any): ProjectModel {
    let jsonBody = res;
    if (!!(res.data)) {
      jsonBody = res.data;
    }
    else if (!!(res.body) && typeof(res.body) === 'string') {
      jsonBody = JSON.parse(res.body);
    }

    // Check if response contains projectUuuid
    // otherwise project limit was exceeded
    if ('projectUuid' in jsonBody) {
      project.uuid = jsonBody['projectUuid'];
      project.taskCount = parseInt(jsonBody['taskCount']);
    }
    
    return project;
  }

  public updateProject(project: ProjectModel): Promise<ProjectModel> {
    return new Promise<ProjectModel>((resolve, reject) => {
      console.log("Attempting to push settings...")
      this.notification.showInProgress("Waiting on response...", "Settings update in progress")
      this.httpService.updateProject(project).toPromise()
      .then((res) => {
        let projectIdx = this.projects.findIndex((element) => {
          return element.uuid === project.uuid
        })
        console.log("res", res)
        this.projects[projectIdx] = this.updateProjectResponseHandler(res)
        this.notification.showSuccess("Settings updated successfully!", project.name);
        resolve(res);
      })
      .catch((err) => {
        console.error(err)
        this.notification.showError("Error updating project", project.name);
        reject(err);
      })
    })
  }

  private updateProjectResponseHandler(res: any): ProjectModel {
    
    let payload = util.getPayload(res);
    console.log('payload', payload)
    let project = ProjectParser.parseProject(payload)
    console.log('project', project)
    
    return project;
  }

  public pushEmailInvite(user: UserModel, projectUuid: string): Promise<Object> {
    return new Promise<Object>((resolve, reject) => {
      this.notification.showInProgress("Waiting on response...", "User invite in progress")
      this.httpService.pushEmailInvite(user.email, projectUuid).toPromise()
      .then((res) => {
        this.notification.showSuccess("Invitation Sent", user.email);
        resolve(res);
      })
      .catch((err) => {
        console.error(err);
        this.notification.showError("Invitation Failed", user.email);
        reject(err);
      });
    })
  }

  public pushPromotion(user: UserModel, projectUuid: string): Promise<Object> {
    return new Promise<Object>((resolve, reject) => {
      console.log("Attempting to push promotion of user " + user.email + " to new rank!")
      this.notification.showInProgress("Response pending...", "Promotion in progress")
      this.httpService.pushPromotion(user.email, projectUuid).toPromise()
      .then((res) => {
        this.notification.showSuccess("Promotion request sent!", user.email);
        resolve(res);
      })
      .catch((err) => {
        console.log('Error: ' + err);
        this.notification.showError("Error sending promotion request!", user.email);
        reject(err);
      })
    })
  }
  //#endregion

  //#region ############ VOYAGE ############
  getVoyages(projectUuid: string): VoyageModel[] {
    let projectIdx = this.projects.findIndex((element) => {
      return element.uuid === projectUuid
    })
    if (projectIdx < 0) {
      return []
    }
    if (!('voyages' in this.projects[projectIdx])) {
      return []
    }
    if (this.projects[projectIdx].voyages === undefined) {
      return []
    }
    return Array.from(this.projects[projectIdx].voyages)
  }

  getVoyage(projectUuid: string, voyageUuid: string): VoyageModel {
    let projectIdx = this.projects.findIndex((element) => {
      return element.uuid === projectUuid
    })
    if (projectIdx < 0) return new VoyageModel()
    let voyageIdx = this.projects[projectIdx].voyages.findIndex((element) => {
      return element.uuid === voyageUuid
    })
    if (voyageIdx < 0) return new VoyageModel()
    return new VoyageModel(this.projects[projectIdx].voyages[voyageIdx])
  }

  createVoyage(project: ProjectModel, voyage: VoyageModel): Promise<VoyageModel> {
    console.log('project.service.ts', voyage)
    return new Promise<VoyageModel>((resolve, reject) => {
      console.log("Attempting to push voyage to server...");
      this.notification.showInProgress("Creating voyage " + voyage.name, "waiting on response...")
      this.httpService.pushVoyage(project.uuid, voyage).toPromise()
      .then((res) => {
        let incomingVoyage = this.pushVoyageResponseHandler(res, voyage)
        this.notification.showSuccess("Voyage created!", incomingVoyage.name);
        // Add voyage to project model
        let projectIdx = this.projects.findIndex((element) => {
          return element.uuid === project.uuid
        })
        if (projectIdx < 0) reject('Project not found!');
        console.log(incomingVoyage)
        this.projects[projectIdx].voyages.push(incomingVoyage);
        resolve(incomingVoyage);
      })
      .catch((err) => {
        console.log("Error: " + JSON.stringify(err));
        this.notification.showError("Voyage creation failed!", project.name);
        reject(err);
      })
      .finally(() => console.log("done"));
    })
  }

  updateVoyage(project: ProjectModel, updatedVoyage: VoyageModel, flag: UpdateType) { 
    let msg = "Updating"
    if (flag === 'activate') {
      msg = 'Activating'
      updatedVoyage.status = 'active'
    }
    else if (flag === 'deactivate') {
      msg = 'Deactivating'
      updatedVoyage.status = 'future'
    }
    return new Promise<VoyageModel>((resolve, reject) => {
      console.log("Attempting to update voyage...");
      console.log(updatedVoyage)
      this.notification.showInProgress(msg + " voyage " + updatedVoyage.name, "waiting on response...")
      this.httpService.updateVoyage(project.uuid, updatedVoyage).toPromise()
      .then((res) => {
        let data = util.getPayload(res)

        let projectIdx = this.projects.findIndex((element) => {
          return element.uuid === project.uuid
        })
        if (projectIdx < 0) reject('Project not found!');
        
        console.log(this.projects[projectIdx].voyages)
        let voyageIdx = this.projects[projectIdx].voyages.findIndex((element) => {
          return element.uuid === updatedVoyage.uuid
        })
        if (voyageIdx < 0) reject('Voyage not found!')
        
        // get voyage from http data
        let incomingVoyage = VoyageParser.parseVoyage((data['item']['voyages'][updatedVoyage.uuid]))
        incomingVoyage.uuid = updatedVoyage.uuid
        
        // update voyage model
        this.projects[projectIdx].voyages[voyageIdx] = incomingVoyage

        // update project model
        if (flag === 'activate') {
          this.projects[projectIdx].latestVoyageUuid = incomingVoyage.uuid 
        }
        else if (flag === 'deactivate') {
          this.projects[projectIdx].latestVoyageUuid = ""
        }
        // resolve
        this.notification.showSuccess("Voyage updated!", updatedVoyage.name);
        resolve(incomingVoyage)
      })
      .catch((err) => {
        console.log("Error: " + JSON.stringify(err));
        this.notification.showError("Voyage update failed!", updatedVoyage.name);
        reject(err);
      })
    })
  }

  private pushVoyageResponseHandler(res: any, voyage: VoyageModel): VoyageModel {
    let jsonBody = res;
    if (!!(res.data)) {
      jsonBody = res.data;
    }
    else if (!!(res.body) && typeof(res.body) === 'string') {
      jsonBody = JSON.parse(res.body);
    }
    if ('item' in jsonBody) {
      jsonBody = JSON.parse(jsonBody)
    }
    console.log('jsonBody', jsonBody)
    let newVoyage = new VoyageModel({
      uuid: jsonBody['voyageUuid'],
      name: jsonBody['voyageName'],
      description: jsonBody['voyageGoal'],
      viewId: jsonBody['viewId'],
      status: jsonBody['status'],
      lengthInWeeks: voyage?.lengthInWeeks,
      lastUpdateTime: jsonBody['lastUpdateTime'],
      startTime: jsonBody['startTime'],
      endTime: jsonBody['endTime']
    })
    return newVoyage
  }
  //#endregion
}
