import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProjectModel, VoyageModel } from 'src/app/models';
import { UpdateVoyageModalContent } from './../../modals' 
import { ModalService } from './../../services';

@Component({
  selector: 'voyage-details-button',
  templateUrl: './voyage-details-button.component.html',
  styleUrls: ['./voyage-details-button.component.css']
})
export class VoyageDetailsButtonComponent {

  @Input() voyage: VoyageModel;
  @Input() project: ProjectModel;
  @Output() messageEvent = new EventEmitter()
  @Output() archiveEvent = new EventEmitter()

  constructor(private modalService: ModalService) { }

  openUpdateModal(voyage: VoyageModel): void {
    console.log('User opening voyage')
    let data = {
      voyage: voyage,
      project: this.project
    }
    this.modalService.openVoyageModal(UpdateVoyageModalContent, data)
    .then((result) => {
      console.log(result)
      voyage = new VoyageModel(result)
      if (voyage.status === 'archived') {
        this.archiveEvent.emit(voyage)
      }
      else {
        this.messageEvent.emit(voyage)
      }
    })
  }

}
