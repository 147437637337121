<nav class="navbar-custom fixed-top">
  <div class="left-btn-group">
    <a title="Home" (click)="openHome()" class="logo">
      <img id="logo" src="assets/images/SharkTracker_Logo.jpg">
    </a>
    <div *ngIf="authenticatedAndSubscribed()" id="nav-btn" class="nav-item">
      <a class="nav-text" (click)="openProjects()">
        Projects
      </a>
    </div>
    <mat-form-field *ngIf="authenticatedAndSubscribed() && projectSelected()" appearance="standard">
      <mat-label>{{currentProject.name}}</mat-label>
      <mat-select [(ngModel)]="selectedView" name="view">
        <mat-option *ngFor="let view of views" [value]="view.value" (onSelectionChange)="onSelection(view.value, $event)">
          {{view.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <ul class="nav-links">
    <li class="nav-item"><a class="nav-text" (click)="openAboutUs()">About</a></li>
    <li class="nav-item"><a class="nav-text" (click)="openContactUs()">Contact Us</a></li>
    <li class="nav-item"><a class="nav-text" (click)="openFaqs()"><i class="glyphicon glyphicon-question-sign"></i></a></li>
    <li *ngIf="!authenticated()" class="nav-item"><a class="nav-text" (click)="openLogin()">Register / Login</a></li>
    
    <li *ngIf="authenticated()" class="nav-header dropdown">
      <a class="nav-link dropdown-toggle dropdown-header"
        id="navbarDropdownMenuLink" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <i class="fas fa-user"></i> 
        {{getUsername()}}
      </a>
      <div class="dropdown-menu dropdown-menu-right dropdown-info" aria-labelledby="navbarDropdownMenuLink">
        <a class="dropdown-item" (click)="openAppSettings()">My account</a>
        <a class="dropdown-item" (click)="logOut()">Logout</a>
      </div>
    </li>
  </ul> 
</nav>
