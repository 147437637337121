<form [formGroup]="voyageForm" (ngSubmit)="onSubmit(voyageForm.value)">
  <div class="modal-body">
    <div class="form-group" [ngClass]="{'error': title.invalid && title.dirty}">
      <label for="title">{{getVoyagePrefix()}} Name (optional):</label>
      <div *ngIf="title.invalid && (title.dirty || title.touched)">
        <em *ngIf="title.errors.maxlength">
          Length exceeded by {{title.errors.maxlength.actualLength - title.errors.maxlength.requiredLength}} character(s).
        </em>
      </div>
      <input type="text" formControlName="title" name="title" class="form-control">
    </div>
    <div class="form-group" [ngClass]="{'error': description.invalid && description.dirty}">
      <label>Voyage Description: </label>
      <div *ngIf="description.invalid && (description.dirty || description.touched)">
        <em *ngIf="description.errors.maxlength">
          Length exceeded by {{description.errors.maxlength.actualLength - description.errors.maxlength.requiredLength}} character(s).
        </em>
      </div>
      <textarea formControlName="description" class="form-control rounded-0"
                rows="3">
      </textarea>
    </div>
    <div class="form-group">
      <label class="form-label" for="voyageRange">Length in Weeks</label>
      <mat-slider color="primary" formControlName="length" 
        thumbLabel tickInterval="1" min="1" max="6" step="1">
      </mat-slider>
    </div>
  </div>
  <div class="modal-footer" style="align-items: flex-start;">
    <button type="button" class="btn btn-primary" 
      (click)="onSubmit(voyageForm.value)"
      [ngClass]="isValid() ? 'enabled' : 'disabled'">
        Create Voyage
    </button>
    <button type="button" class="btn btn-danger" 
      (click)="closureCheck(voyageForm.value)">
        Cancel
    </button>
  </div>
</form>