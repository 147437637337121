import { Component, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import { ProjectService, ModalService } from '../../services';
import { UpdateTaskModalContent } from './../../modals'
import { TaskModel, ProjectModel } from '../../../models';

@Component({
  selector: 'task-details-button',
  templateUrl: './task-details-button.component.html',
  styleUrls: ['./task-details-button.component.css']
})
export class TaskDetailsButtonComponent {
  @Input() projectId: string;
  public project: ProjectModel
  @Input() task;
  @Output() messageEvent: EventEmitter<TaskModel> = new EventEmitter();
  
  constructor(private modalService: ModalService, private projectService: ProjectService) { }

  ngOnInit(): void {
    this.project = this.projectService.getProject(this.projectId)
  }

  openModal(): void {
    console.log('User viewing task...')
    let data = {
      task: this.task,
      project: this.project
    }
    this.modalService.openTaskModal(UpdateTaskModalContent, data)
    .then((result) => {
      console.log('Result: ' + JSON.stringify(result));
      this.task = result;
      this.messageEvent.emit(result);
    })
    .catch((err) => {
      console.log("Error: " + err);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getImage()
  }

  getImage() {
    if (this.task.status !== 'done') {
      return 'assets/images/view_icon.png'
    }
    else {
      return 'assets/images/archive-image.png'
    }
  }
}
