import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UserModel } from '../models/UserModel';
import { ProjectService, TribeService, RouteService } from '../shared';
import { ProjectModel } from '../models';

@Component({
  selector: 'app-display-project-settings',
  templateUrl: './display-project-settings.component.html',
  styleUrls: ['./display-project-settings.component.css', './../shared/modals/config/modal-validation.css']
})
export class DisplayProjectSettingsComponent implements OnInit {
  
  project: ProjectModel;
  projectParam: string;
  panelOpenState = false;
  userForm: FormGroup;
  promotionForm: FormGroup;
  email: FormControl;
  //#region PROJECT CONFIG FORM
  title: FormControl;
  description: FormControl;
  daysUntilTaskDeletion: FormControl;
  voyageBtn: FormControl;
  voyageSlider: FormControl;
  tagBuilder: FormControl;
  configurationForm: FormGroup = new FormGroup({ 
    title: new FormControl(),
    description: new FormControl(),
    daysUntilTaskDeletion: new FormControl(),
    voyageBtn: new FormControl(),
    voyageSlider: new FormControl(),
    tagBuilder: new FormControl()
  });
  //#endregion
  tribe: UserModel[] = [];
  deckHands: UserModel[] = []; 
  isCaptain: boolean = true;

  submittedEmails: string[] = [];

  constructor(private formBuilder: FormBuilder, private routeService: RouteService,
    private projectService: ProjectService, private tribeService: TribeService) { 

    this.routeService.queryParams.subscribe(params => {
      this.projectParam = params['project'];
    });
  }

  async ngOnInit(): Promise<void> {
    if(this.projectService.getProject(this.projectParam) === undefined)
    {
      await this.projectService.getProjects();    
    }
    this.project = this.projectService.getProject(this.projectParam);

    // invitee form
    this.userForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.createProjectForm()

    this.tribeService.getTribe(this.projectParam)
    .then((res) => {
      this.tribe = res;
      this.deckHands = this.getDeckHands();
      // this.firstMates = this.firstMates();
      // this.captains = this.getCaptains()
      console.log("Tribe retrieved!");
    })
    .catch((err) => {
      console.error("Error getting tribe for project!");
    })
  }

  createProjectForm() {
    this.configurationForm = this.formBuilder.group({
      title: [
        this.project.name,
        [Validators.required, Validators.maxLength(50)]
      ],
      description: [
        this.project.description,
        [Validators.required, Validators.maxLength(1000)]
      ],
      daysUntilTaskDeletion: [
        this.project.daysUntilTaskDeletion
      ],
      voyageBtn: [
        (this.project.voyageEnabled),
      ],
      voyageSlider: [
        (this.project.autoVoyageLength || 2)
      ],
      tagBuilder: [this.project.tags, []]
    });
    this.title = this.configurationForm.get("title") as FormControl;
    this.description = this.configurationForm.get("description") as FormControl;
    this.daysUntilTaskDeletion = this.configurationForm.get("daysUntilTaskDeletion") as FormControl;
    this.voyageBtn = this.configurationForm.get("voyageBtn") as FormControl;
    this.voyageSlider = this.configurationForm.get("voyageSlider") as FormControl;

    // add custom form control
    this.configurationForm.addControl("tagBuilder", this.tagBuilder)
    this.tagBuilder = this.configurationForm.get("tagBuilder") as FormControl;
  }

  invalidEmail()
  {
  	return (this.userForm.controls.email.errors != null) && this.userForm.touched;
  }

  onSubmitInvite()
  {
    let email: string = this.userForm.controls.email.value
    console.log("Email of Invitee: " + email);

    if(this.userForm.invalid)
  	{
      console.log("Invalid Submission")
  		return;
  	}
    let index = this.submittedEmails.findIndex((x) => {
      return x == email
    })
  	if (index === -1)
  	{
      var user = new UserModel();
      user.email = email;
      this.projectService.pushEmailInvite(user, this.projectParam)
      .then(() => {
        this.submittedEmails.push(email)
      })
      .catch(() => {
        
      })
  	}
  }

  onPromoteUser(value: string)
  {
    if (value) {
      let user = new UserModel();
      user.email = value
      this.projectService.pushPromotion(user, this.projectParam);
    }
    else {
      console.error("Selection value undefined!")
    }
  }

  onSelectPromotionChange(value: any) {
    console.log(JSON.stringify(value))
  }

  onSubmitConfigure() {
    if (this.hasChanged()) {
      this.configurationForm.markAsUntouched()
      console.log(this.configurationForm.controls.tagBuilder.value)
      let project: ProjectModel = new ProjectModel({
        uuid: this.projectParam,
        name: this.configurationForm.controls.title.value,
        description: this.configurationForm.controls.description.value,
        daysUntilTaskDeletion: this.configurationForm.controls.daysUntilTaskDeletion.value,
        voyageEnabled: this.configurationForm.controls.voyageBtn.value,
        autoVoyageLength: this.configurationForm.controls.voyageSlider.value,
        tags: this.configurationForm.controls.tagBuilder.value
      }) 
      this.projectService.updateProject(project)
      .then((project) => {
        // reset form to untouched state upon success
        this.configurationForm.markAsPristine();
        this.configurationForm.markAsUntouched();
        console.log(this.configurationForm)
      })
      .catch((err) => {

      })
    }
  }

  hasChanged() {
    console.log('touched: ', this.configurationForm.touched)
    console.log('dirty: ', this.configurationForm.dirty)
    console.log('valid: ', this.configurationForm.valid)
    console.log(this.configurationForm);
    return (this.configurationForm.touched 
    && this.configurationForm.dirty
    && this.configurationForm.valid)
  }

  titleIsInvalid() {
    return this.configurationForm.controls.title.invalid && this.configurationForm.controls.title.dirty
  }

  titleHasError() {
    return this.configurationForm.controls.title.invalid 
    && (this.configurationForm.controls.title.dirty 
    || this.configurationForm.controls.title.touched);
  }

  getLastNameInitial(lastName: string): string {
    if (lastName.length === 0) {
      return ''
    }
    if (Array.isArray(lastName)) {
      return lastName[0][0] + '.'
    }
    return lastName[0] + '.'
  }

  getDeckHands(): UserModel[] {
    let emails = this.project.deckHands
    return this.tribeService.getFilteredMembers(emails)
  }

  getFirstMates(): UserModel[] {
    let emails = this.project.firstMates
    return this.tribeService.getFilteredMembers(emails)
  }

  getCaptains(): UserModel[] {
    let emails = this.project.captains
    return this.tribeService.getFilteredMembers(emails)
  }

  getVoyageTags(): string[] {
    let tags: string[] = Array.from<string>([])
    this.project.voyages.forEach((voyage) => {
      if (voyage.status !== 'archived') {
        let prefix = 'V' + voyage.viewId.toString()
        tags.push(prefix)
      }
    })
    console.log('voyage tags: ', tags)
    return tags
  }

  removeNull(array) {
    return array.filter(x => x !== null)
  }

  initTagCtrl(tagCtrl: FormControl): void {
    this.configurationForm.addControl('tagInputControl', tagCtrl)
    this.configurationForm.updateValueAndValidity()
    console.log('added tagCtrl to configurationForm', this.configurationForm)
  }
}
