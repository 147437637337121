import { TaskModel, UserModel } from "src/app/models"
import { QuestionBase } from "src/app/models/question/question-base.model"
import { DropdownQuestion } from "src/app/models/question/question-dropdown.model"
import { TextboxQuestion } from "src/app/models/question/question-textbox.model"
import { Parser } from './parser-utility'
import UserParser  from './user-parser'

export default class TaskParser extends Parser {
  static parseTask(payload: any): TaskModel {
    if (Array.isArray(payload)) {
      throw new Error('Faled to parse: payload must not be an array')
    }
    try {
      const task = new TaskModel({
        uuid: this.getUuid(payload),
        viewId: this.getViewId(payload),
        title: this.getTitle(payload),
        description: this.getDescription(payload),
        status: this.getStatus(payload),
        creationDate: this.getCreationDate(payload),
        assignee: this.getAssignee(payload),
        lastUpdateTime: this.getLastUpdateTIme(payload),
        sharkRank: this.getSharkRank(payload),
        sharkClassification: this.getSharkClassification(payload),
        latestUpdate: this.getLatestUpdate(payload),
        changedBy: this.getChangedBy(payload),
        history: this.getHistory(payload),
        tags: this.getTags(payload),
        questions: this.getQuestions(payload)
      })
      return task
    }
    catch (e) {
      throw e
    }
  }

  static getName(payload: any) {
    const key: string = "username"
    return this.getString(payload, key)
  }

  static getQuestions(payload: any): QuestionBase<string>[] {
    let questions: QuestionBase<string>[] = []
    const key: string = 'questions'
    const list: string[] = this.getList(payload, key)
    let obj: any;
    let type: string;
    list.forEach((item) => {
      obj = this.filterObj(item)
      type = this.getString(obj, 'controlType')
      switch(type) {
        case 'dropdown':
          questions.push(new DropdownQuestion(obj))
          break;
        case 'textbox':
          questions.push(new TextboxQuestion(obj))
          break;
        default:
          questions.push(new QuestionBase<string>())
      }
    })
    return questions
  }

  static getTags(payload: any): string[] {
    const key: string = 'tags'
    return this.getList(payload, key)
  }

  static getChangedBy(payload: any): string {
    const key: string = 'latestChangeBy'
    return this.getString(payload, key)
  }

  static getHistory(payload: any): object {
    const key: string = 'taskHistory'
    return this.getObj(payload, key)
  }

  static getViewId(payload: any): string {
    const key: string = 'viewId'
    return this.getString(payload, key)
  }

  static getUuid(payload: any): string {
    const key: string = 'taskUuid'
    return this.getString(payload, key)
  }

  static getTitle(payload: any): string {
    const key: string = "taskTitle"
    return this.getString(payload, key)
  }

  static getDescription(payload: any): string {
    const key: string = "description"
    return this.getString(payload, key)
  }

  static getSharkRank(payload: any): string {
    const key: string = "sharkRank"
    return this.getString(payload, key)
  }

  static getLastUpdateTIme(payload: any): string {
    const key: string = "lastUpdateTime"
    return this.getString(payload, key)
  }

  static getAssignee(payload: any): string {
    let keys: string[] = ["username", "assignee"]
    let res = "";
    while (keys.length > 0 && res === "") {
      res = this.getString(payload, keys.pop())
    }
    // backward compatibility
    if (res === "") res = 'unassigned'
    return res
  }

  static getCreationDate(payload: any): string {
    const key: string = "creationTime"
    return this.getString(payload, key)
  }

  static getStatus(payload: any): string {
    const key: string = "taskStatus"
    return this.getString(payload, key)
  }

  static getSharkClassification(payload: any): string {
    const key: string = "sharkClassification"
    return this.getString(payload, key)
  }

  static getLatestUpdate(payload: any): string {
    const key: string = 'latestUpdate'
    return this.getString(payload, key)
  }
}