import { 
  Component, 
  OnInit, 
  Input, 
  ViewChild, 
  ViewChildren, 
  ElementRef, 
  QueryList, 
  ChangeDetectorRef,
  EventEmitter,
  Output
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatRipple } from '@angular/material/core';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { map, startWith, tap } from 'rxjs/operators';
import { ProjectModel, TaskModel } from 'src/app/models';

@Component({
  selector: 'tag-updater',
  templateUrl: './tag-updater.component.html',
  styleUrls: ['./tag-updater.component.css', './../../modals/config/modal-validation.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: TagUpdaterComponent
    }
  ]
})
export class TagUpdaterComponent implements OnInit, ControlValueAccessor {

  @Input() project: ProjectModel
  @Input() task: TaskModel
  public visibleTags: string[]
  // VERY IMPORTANT CONCEPT
  // this.onChange connects this.tags to the emitted value coming from this.tagCtrl
  public tags: string[];
  public allTags: string[];
  public tagStream: Observable<string>;
  public filteredTags: Observable<string[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  // change new FormControl([], ...);
  // to new FormControl('', ...);
  // at your own peril!
  tagCtrl = new FormControl([], [Validators.maxLength(24)]);
  @Output() onTagControlInit = new EventEmitter()
  @Output() onQuestionEvent = new EventEmitter()
  visible = true;
  selectable = true;
  removable = true;
  
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('chipList') chipList: MatChipList;
  @ViewChildren(MatRipple) ripples: QueryList<MatRipple>
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

  constructor(private changeDetector: ChangeDetectorRef) {
    // console.log(this.tagCtrl.status)s
    this.tagCtrl.statusChanges.pipe(
      map((event) => {
        // console.log(event)
        return event
      })
    )
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(''),
      map((tag: string) => {
        // console.log('tag', tag)
        return tag ? this._filter(tag) : this.allTags.slice()
      })
    );
  }

  ngOnInit(): void {
    this.initTags()
    this.onTagControlInit.emit(this.tagCtrl)
  }

  ngOnChanges(change: any): void {
    console.log('change', change)
  }

  initTags(): void {
    this.tags = this.task.tags ?? []
    this.visibleTags = this.filterByVisibility(this.task.tags)
    console.log('this.visibleTags', this.visibleTags)
    this.allTags = this.project.tags
    this.onChange(this.tags)
  }

  filterByVisibility(tags: string[]): string[] {
    let subset: string[] = []
    tags.forEach(tag => {
      if (!tag.includes('/')) {
        subset.push(tag)
      }
    })
    return subset
  }

  ngAfterViewInit(): void {
    // all ViewChildren are set by the time we reach this lifecycle hook
    this.ripples.changes.subscribe((value) => {
      this.changeDetector.detectChanges();
    })
  }

  add(event: MatChipInputEvent): void {
    if (this.disabled || this.hasError()) {
      return
    }

    this.markAsTouched();
    const input = event.input;
    const value = event.value;
    if (value === "") return

    // sanitize input
    const trimmedValue = value.trim()

    // insert tag or create form control
    this.insertTag(trimmedValue)

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    
    this.markAsTouched();
    const value = event.option.viewValue
    if (value === "") return

    this.insertTag(value)

  }
  
  insertTag(value: string): void {
    if (value.includes('/')) {
      this.handleAdvancedTag(value)
    }
    else {
      this.handleRegularTag(value)
    }
  }

  handleRegularTag(value: string) {
    // does not already exist check
    let index = this.tags.indexOf(value)
    if (index === -1) {
      this.tags.push(value);
      index = this.visibleTags.indexOf(value)
      if (index === -1) {
        this.visibleTags.push(value)
      }
      // inform the parent form
      // writeValue should be called with typeof(value) === string
      this.onChange(this.tags)
    }
    else {
      console.log('tag already exists!')
      // console.log(this.tagInput)
      this.launchRippleAtIndex(index)
    }
  }

  handleAdvancedTag(tag: string) {
    let index = this.tags.indexOf(tag)
    if (index === -1) {
      // only push to tags, not to visible tags
      this.tags.push(tag)
      
      // inform the parent form
      this.onChange(this.tags)

      // handle question event
      const split = tag.split('/')
      // console.log('split', split)
      const key = split[0].toLocaleLowerCase()
      const value = split[1]
      const event = { key: key, value: value };
      this.handleQuestionEvent(event)
      this.onQuestionEvent.emit(event)
    }
    else {
      console.log('tag already exists!')
    }
  }

  handleQuestionEvent(event: {[key: string]: string}) {
    const key = event.key
    const value = event.value

    // assuming the advanced tag was not already found in this.tags
    if (this.project.questions[key]) {
      // if form question already exists
      // add the tag value to the dictionary
      this.project.questions[key].push(value)

    }
    // this.onQuestionEvent.emit({prefix: key, value: value})
  }

  launchRippleAtIndex(idx: number) {
    let element: MatRipple = this.ripples.find((item, index) => {
      return index === idx
    })
    if (element) {
      // console.log('element', element)
      const rippleRef = element.launch({
        persistent: true,
        centered: true,
        color: 'blue'
      });
  
      // Fade out the ripple later.
      rippleRef.fadeOut();
    }
  }

  remove(tag: string): void {
    console.log('remove', tag)
    if (this.disabled) {
      console.log('this.disabled', this.disabled)
      return
    }
    this.markAsTouched();
    let index = this.tags.indexOf(tag);
    console.log('index', index)
    if (index >= 0) {
      this.tags.splice(index, 1);

      console.log('this.tags', this.tags)

      // inform tagUpdater in TaskForm
      this.onChange(this.tags)
    }
    index = this.visibleTags.indexOf(tag);
    if (index >= 0) {
      this.visibleTags.splice(index, 1);

      console.log('this.visibleTags', this.visibleTags)

    }
  }

  displayAutoComplete() {
    this.trigger.openPanel()
  }

  private _filter(value: string): string[] {
    if (value === null || value === undefined) {
      return []
    }
    // console.log('value', value, typeof(value))
    const filterValue = value.toString().toLocaleLowerCase();

    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

  hasError() {
    return this.tagCtrl.invalid && this.tagCtrl.dirty
  }

  getErrorMessage() {
    console.log(this.tagCtrl.errors)
    if (this.tagCtrl.errors.maxlength) {
      let diff = this.tagCtrl.errors.maxlength.actualLength - this.tagCtrl.errors.maxlength.requiredLength
      return 'Max length exceeded by ' + diff.toString() + ' characters!';
    }
  }

   //#region ControlValueAccessor Interface
   onChange = (quantity) => { console.log('changed with', quantity) };

   onTouched = () => { 
     console.log('touched!')
     this.trigger.openPanel()
   };
 
   touched = false;
   disabled = false;
 
   writeValue(obj: any): void {
    // This is a tricky-ass function.
    // If you're here and the value of obj === ""
    // then somewhere a call to this.tagCtrl.setValue('')
    // or this.tagCtrl = new FormControl('', ...)
    // is happening. Please remove any of these calls. 
    // Use [] not '' to INITIALIZE TagUpdaterComponent.
    // If the value of obj != "", then APPEND the string value to the end of
    // the corresponding array, either tags or visibleTags (or both).
    // if (!Array.isArray(obj)) throw new Error('TagUpdater expected an array, but received ' + typeof(obj))
    switch(typeof(obj)) {
      case 'object':
        if (Array.isArray(obj)) {
          console.log('writeValue', obj)
          console.log('isArray true!')
          
          // reset tags
          this.tags = []
          this.visibleTags = []
          obj.forEach((element) => {
            // insert tag or create form control
            this.tags.push(element)
            if (!element.includes('/')) {
              this.visibleTags.push(element)
            }
          })
        }
        else {
          throw new Error('TagUpdater expected an array!')
        }
        break;
      case 'string':
        if (obj !== "" && obj !== null) {
          this.tags.push(obj)
          if (!obj.includes('/')) {
            this.visibleTags.push(obj)
          }
        }
        break;
      default:
        let msg = 'TagUpdater tried to write unrecognized data type \'' + typeof(obj) + '\''
        throw new Error(msg)
    }
   }
 
   registerOnChange(onChange: any) {
     this.onChange = onChange;
   }
 
   registerOnTouched(onTouched: any) {
     this.onTouched = onTouched;
   }
 
   markAsTouched() {
     if (!this.touched) {
       this.onTouched();
       this.touched = true;
     }
   }
 
   markAsUntouched() {
     if (this.touched) {
       this.touched = false;
     }
   }
 
   setDisabledState(disabled: boolean) {
     this.disabled = disabled;
   }
   //#endregion
 
  
}
