<form [formGroup]="taskForm" (ngSubmit)="onSubmit(taskForm.value)">
  <div class="modal-body">
    <div class="form-group" [ngClass]="{'error': title.invalid && title.dirty}">
      <label>Task Title: </label>
      <div *ngIf="title.invalid && (title.dirty || title.touched)">
        <em *ngIf="title.errors.required">
          Title is required.
        </em>
        <em *ngIf="title.errors.maxlength">
          Length exceeded by {{title.errors.maxlength.actualLength - title.errors.maxlength.requiredLength}} character(s).
        </em>
      </div>
      <input type="text" formControlName="title" autofocus="false" class="form-control">
    </div>
    <div class="form-group">
      <label>Assign A User: </label>
      <select formControlName="assignee" class="form-control">
        <option *ngFor="let user of tribeMembers" [value]="user.uuid">
          {{user.firstname}}
        </option>
      </select>
    </div>
    <div class="form-group" [ngClass]="{'error': description.invalid && description.dirty}">
      <label>Task Description: </label>
      <div *ngIf="description.invalid && (description.dirty || description.touched)">
        <em *ngIf="description.errors.maxlength">
          Length exceeded by {{description.errors.maxlength.actualLength - description.errors.maxlength.requiredLength}} character(s).
        </em>
      </div>
      <textarea formControlName="description" class="form-control rounded-0"
                rows="3">
      </textarea>
    </div>
    <div class="form-group">
      <label>Rank your shark: </label>
      <select formControlName="sharkRank" class="form-control">
        <option value="megalodon">megalodon</option>
        <option value="greatwhite">great white</option>
        <option value="meat-eater">meat-eater</option>
        <option value="fledgling">fledgling</option>
        <option value="tadpole">tadpole</option>
      </select> 
    </div>
    <div class="form-group">
      <label>Classify your shark: </label>
      <select formControlName="sharkClassification" class="form-control">
        <option value="bug">bug</option>
        <option value="test">test</option>
        <option value="spike">spike</option>
        <option value="feature">feature</option>
      </select> 
    </div>
    <div class="form-group">
      <app-dynamic-form formGroupName="questionGroup" 
          [questionGroup]="taskForm.controls.questionGroup"
          [questions]="task.questions">
        
      </app-dynamic-form>
    </div>
    <div class="form-group">
      <tag-updater formControlName="tagUpdater" #tagUpdater 
        [project]="project" [task]="task"
        (onTagControlInit)="initTagCtrl($event)"
        (onQuestionEvent)="handleQuestion($event)">
      
      </tag-updater>
    </div>
  </div>
  <div class="modal-footer" style="align-items: flex-start;">
    <button type="submit" class="btn btn-primary" 
      [ngClass]="isValid() ? 'enabled' : 'disabled'">
        Update Task
    </button>
    <button *ngIf="task.status === 'done'" 
      type="button" class="btn btn btn-secondary" 
      (click)="task.status = 'archived'; onSubmit(taskForm.value)"
      [ngClass]="isValid() ? 'enabled' : 'disabled'">
        Archive Task
    </button>
    <button *ngIf="task.status === 'archived'" 
      type="button" class="btn btn btn-secondary" 
      (click)="task.status = 'done'; onSubmit(taskForm.value)"
      [ngClass]="isValid() ? 'enabled' : 'disabled'">
        Unarchive Task
    </button>
    <button *ngIf="task.status === 'backlog'" 
      type="button" class="btn btn btn-secondary" 
      (click)="task.status = 'todo'; onSubmit(taskForm.value)"
      [ngClass]="isValid() ? 'enabled' : 'disabled'">
        Onboard Task
    </button>
    <button *ngIf="task.status === 'todo'"
      type="button" class="btn btn btn-secondary" 
      (click)="task.status = 'backlog'; onSubmit(taskForm.value)"
      [ngClass]="isValid() ? 'enabled' : 'disabled'">
        Backlog
    </button>
    <button type="button" class="btn btn-danger" 
      (click)="closureCheck(taskForm.value)">
        Close
    </button>
  </div>
</form>